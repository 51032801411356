type CarouselConfigItem = {
  breakpoint: { max: number; min: number };
  items: number;
  slidesToSlide: number;
};
export type CarouselConfig = {
  desktop: CarouselConfigItem;
  tablet: CarouselConfigItem;
  mobile: CarouselConfigItem;
  minimobile: CarouselConfigItem;
};
export type CarouselBannerConfig = Pick<CarouselConfig, 'mobile'>;

export const carouselMainPage: CarouselConfig = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1199, min: 850 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 849, min: 576 },
    items: 2,
    slidesToSlide: 2,
  },
  minimobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const carouselRestaurants: CarouselConfig = {
  desktop: {
    breakpoint: { max: 3000, min: 1301 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1199, min: 850 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 849, min: 576 },
    items: 2,
    slidesToSlide: 2,
  },
  minimobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const carouselCategories: CarouselConfig = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 6,
    slidesToSlide: 6,
  },
  tablet: {
    breakpoint: { max: 1199, min: 850 },
    items: 4,
    slidesToSlide: 4,
  },
  mobile: {
    breakpoint: { max: 849, min: 576 },
    items: 3,
    slidesToSlide: 3,
  },
  minimobile: {
    breakpoint: { max: 575, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
};

export const carouselRelatedProductsPopup: CarouselConfig = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1199, min: 850 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 849, min: 576 },
    items: 2,
    slidesToSlide: 2,
  },
  minimobile: {
    breakpoint: { max: 575, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
};

export const carouselBanner: CarouselBannerConfig = {
  mobile: {
    breakpoint: { max: 991, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
