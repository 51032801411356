import React from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { getLinkByCode, getPictureByCode } from '../../utils/bannerPrictures';
import { buildLocale } from '../../utils/buildLocale';

interface IProps {
  code: string;
  title1?: string;
  title2?: string;
  linkTitle?: string;
  pictures: Array<any>;
  containerClass?: string;
  hideDescription?: boolean;
}

const BannerItem = (props: IProps) => {
  const { code, title1, title2, linkTitle, containerClass, pictures, hideDescription } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  return (
    <div className={`banner-mobile ${containerClass || ''}`}>
      <figure className="banner-pic">
        <img src={getPictureByCode(code, pictures)} alt="" />
      </figure>
      {!hideDescription && (
        <div className="banner-text">
          {title1 && <h1>{buildLocale(currentTranslate, title1)}</h1>}
          {title2 && <h2>{buildLocale(currentTranslate, title2)}</h2>}
          {linkTitle && (
            <a href={getLinkByCode(code, pictures)} className="btn">
              {buildLocale(currentTranslate, linkTitle)}
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default BannerItem;
