import React from 'react';
import { generateClassName } from '../../../utils/generateClassName';
import useComponentVisible from '../../../useHooks/useComponentVisible';

interface IDateTimeInputProps<T = any> {
  value: T;
  inputValue?: string;
  options: IOption<T>[];
  onSelect: (s: IOption<any>) => any;
  label?: string;
  placeholder?: string;
  isInput: boolean;
  isSelect?: boolean;
  name?: string;
  onChange?: (s: string) => any;
  onKeyDown?: any;
  readonly?: boolean;
  defaultValue?: string;
}

export interface IOption<T> {
  label: string;
  value: T;
  extra?: any;
  buildNumbers?: any;
}
const DropdownInput = React.forwardRef(
  (
    {
      options,
      value,
      onSelect,
      label,
      placeholder,
      isInput,
      name,
      inputValue,
      onChange,
      onKeyDown,
      isSelect = true,
      readonly,
      defaultValue,
    }: IDateTimeInputProps,
    ref: any,
  ) => {
    const option = options.find((o) => o.value === value);
    const input = inputValue ? inputValue : option ? option.label : value;

    const { ref: inputRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const handleToggle = () => {
      setIsComponentVisible((f) => !f);
    };

    const handelSelect = (select: any) => {
      onSelect(select);
      handleToggle();
    };

    const handleChange = (e: any) => {
      if (onChange) {
        onChange(e.target.value);
      }
    };

    const keyDown = (e: any) => {
      if (onKeyDown) {
        onKeyDown(e.key);
      }
    };

    return (
      <div
        className={generateClassName('input-group date form-part', {
          filled: !!input,
        })}
        ref={inputRef}>
        {label && <label>{label}</label>}
        <input
          type="text"
          name={name}
          ref={ref}
          className={generateClassName('form-control', {
            'cursor-pointer': value !== null,
          })}
          placeholder={placeholder}
          contentEditable={isInput}
          value={input}
          onFocus={handleToggle}
          onChange={handleChange}
          autoComplete={isSelect ? 'off' : 'on'}
          onKeyDown={keyDown}
          readOnly={!!readonly}
          defaultValue={defaultValue}
        />
        {isSelect && (
          <span
            className={generateClassName('arrow', {
              'arrow-focus': isComponentVisible,
            })}
            onFocus={handleToggle}
            tabIndex={1}
          />
        )}
        {isSelect && options.length > 0 && (
          <div
            className={generateClassName('search-hint dropdown-date', {
              'dropdown-date-focus  search-hint-show': isComponentVisible,
            })}>
            {options.map((s, i) => {
              return (
                <div
                  key={i}
                  onClick={() => handelSelect(s)}
                  className={generateClassName('date-hint_item', {
                    'date-hint_item-active': s.value === value,
                  })}>
                  {s.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  },
);

export default DropdownInput;
