import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { setLoaderColor } from '../../../utils/setLoaderColor';

interface IProps {
  isLoading: boolean;
  loadingMessage: string;
}

const LoadingProcess = (props: IProps) => {
  const { isLoading, loadingMessage } = props;
  return (
    <div className="pay-loader_content">
      <span className="text">{loadingMessage}</span>
      <ClipLoader size={50} color={setLoaderColor()} loading={isLoading} />
    </div>
  );
};

export default LoadingProcess;
