import React, { useEffect, useState } from 'react';
import Cart from './Cart';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsCart } from '../../redux/cart/cartReducer';
import { useFormattingContext } from '../../context/FormattingContext';
import { usePaths } from '../Routes/RouterList';
import useDidUpdateEffect from '../../useHooks/useDidUpdateEffect';
import { useSpring, animated } from 'react-spring';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { actionsOther, selectorsOther } from '../../redux/other/otherReducer';
import { BlockInfoOptions } from '../../redux/other/otherTypes';
import { selectorsUser } from '../../redux/user/userReducer';
import { useLessThen991 } from '../../utils/mediaQuery';
import { getLocaleStorageItem } from '../../utils/localStorageHelper';
import { useNavigate } from 'react-router';

interface IHeaderCartProps {
  onClose: any;
  closeNonAuth: any;
}

const HeaderCart = React.memo(({ onClose, closeNonAuth }: IHeaderCartProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const blockInfoOptions: BlockInfoOptions = useSelector(selectorsOther.getBlockInfoOptions);
  const paths = usePaths();
  const isAuth = useSelector(selectorsUser.isAuth);
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const minPrice = useSelector(selectorsOther.getMinDeliveryPrice);
  const { formatPrice } = useFormattingContext();
  const cart = useSelector(selectorsCart.getCart);
  const count = useSelector(selectorsCart.getGeneralCount);
  const navigate = useNavigate();
  const [isLowerThenMin, setIsLowerThenMin] = useState(false);
  const notAvailableProducts = useSelector(selectorsCart.getNotAvailableProducts);
  const isOpenCart = useSelector(selectorsCart.getIsOpenCart);
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const dispatch = useDispatch<any>();
  const isSelectedAuth = isAuth || getLocaleStorageItem('asGuest');
  const productsPrice = useSelector(selectorsCart.getProductsPrice);

  const OFFSET_TO_BASKET_BUTTON_WITH_MAX_PUB_MESS = 7.2;
  const OFFSET_TO_BASKET_BUTTON_WITH_ONE_PUB_MESS = 0.7;
  const OFFSET_TO_BASKET_BUTTON_WITHOUT_PUB_MESS = 4.8;

  const [top, setTop] = useState(OFFSET_TO_BASKET_BUTTON_WITH_MAX_PUB_MESS);

  const handleToBasket = () => {
    isSelectedAuth ? toBasketNav() : openNonAuth();
    (!isLess991 || (isLess991 && isSelectedAuth)) && onClose();
  };

  const toBasketNav = () => {
    navigate(paths[isLess991 ? 'pay-page' : 'cart-list']);
  };

  const openNonAuth = () => {
    dispatch(actionsOther.setIsOpenNonAuth(true));
  };

  useDidUpdateEffect(() => {
    if (count === 0) {
      onClose();
    }
  }, [count]);

  useEffect(() => {
    setIsLowerThenMin((productsPrice || 0) < minPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsPrice]);

  useEffect(() => {
    const marginTop = blockInfoOptions.headerHightPx / 10;

    if (blockInfoOptions.displayedmMessagesTotal === 0) {
      setTop(marginTop + OFFSET_TO_BASKET_BUTTON_WITHOUT_PUB_MESS);
      return;
    }
    if (blockInfoOptions.displayedmMessagesTotal > 1) {
      setTop(marginTop + OFFSET_TO_BASKET_BUTTON_WITH_MAX_PUB_MESS);
      return;
    }

    setTop(marginTop - OFFSET_TO_BASKET_BUTTON_WITH_ONE_PUB_MESS);
  }, [blockInfoOptions]);

  return (
    <animated.div
      className="basket-block basket-popup"
      onClick={(e) => e.stopPropagation()}
      style={{ ...props, ...{ top: top + 'rem', zIndex: 1111, position: 'fixed' } }}>
      <div className="basket-block_title">
        {count !== 0 ? (
          <div className="desktop-title">
            <p>{formatPrice(+cart.total)}</p>
            {count} {buildLocale(currentTranslate, 'tovara')}
          </div>
        ) : (
          <p>{buildLocale(currentTranslate, 'cartEmptyItems')}</p>
        )}
        <p className="mobile-title">
          <span className="title">{buildLocale(currentTranslate, 'cartTitle')}</span>
          <span className="number">
            {count} {buildLocale(currentTranslate, 'tovara')}
          </span>
        </p>
        <button onClick={onClose} className="basket-block_close" />
      </div>
      <Cart isHeader={true} isLowerThenMin={isLowerThenMin} minPrice={minPrice} isOpenCart={isOpenCart} />
      <div className="basket-block_buttons">
        {isLowerThenMin && (
          <span className="low-price-error">
            {buildLocale(currentTranslate, 'toLowOrderSum')} {minPrice}€
          </span>
        )}
        <button
          onClick={handleToBasket}
          className="btn"
          type="button"
          disabled={isLowerThenMin || notAvailableProducts.length > 0}>
          {buildLocale(currentTranslate, 'btnOrderLong')} <span className="price">{formatPrice(+cart.total)}</span>
        </button>
        <button onClick={onClose} className="btn btn-border btn-close_mobile" type="button">
          <span>{buildLocale(currentTranslate, 'btnContinueOrder')}</span>
        </button>
      </div>
    </animated.div>
  );
});

export default HeaderCart;
