import { getOwnCategory } from '../getOwnCategory';

export const draftToDL = (draftInfo: any) => {
  return {
    items: draftInfo.purchases.map((p) => {
      const cartCustomCategory = getOwnCategory(p?.product?.customCategories);
      return {
        item_name: p.product?.titleDe || p.product?.title,
        item_id: p.product?.id,
        price: p.product?.productOptions
          ? p.product?.productOptions[0]?.salePrice || p.product?.productOptions[0]?.price
          : p.price,
        category: cartCustomCategory?.name,
        quantity: p.count,
        brand: p.product?.brand?.nameDe,
      };
    }),
    value: draftInfo.totalPrice || 0,
  };
};
