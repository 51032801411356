import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import getDiscountPercentage from '../../utils/getDiscountPercentage';
import { IProductOption } from '../../typings/IProduct';
import { getDateTimeDuration } from '../../utils/getDateTimeDuration';
import { getDiffInSeconds } from '../../utils/getDiffInSeconds';
import { getDateTimeDurationWithCountdown } from '../../utils/getDateTimeDurationWithCountdown';
import { buildLocale } from '../../utils/buildLocale';

interface SaleDiscountWidgetProps {
  productOption: IProductOption | null;
  needCountdown: boolean;
  price?: number;
  salePrice?: number | string | null;
}

const SaleDiscountWidget = (props: SaleDiscountWidgetProps) => {
  const { productOption, needCountdown, price, salePrice } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const actualPrice = price !== undefined ? price : productOption ? +productOption?.price : 0;
  const actualSalePrice = salePrice !== undefined ? Number(salePrice) : productOption ? productOption?.salePrice : null;
  const saleEndDate = productOption ? productOption?.saleEndDate : null;
  const [diffInSecondsForCountdown, setDiffInSecondsForCountdown] = useState<number | undefined>(undefined);
  const [intervalId, setIntervalId] = useState<any>(undefined);
  const [isCountdown, setIsCountdown] = useState<boolean | undefined>(undefined);

  const SECONDS_LIMIT_FOR_COUNTDOWN = 86400;
  const DEFAULT_EMPTY_COUNTDOWN_VALUE = '--:--:--';

  const isCountdownAvailable = (diffInSeconds: number) => SECONDS_LIMIT_FOR_COUNTDOWN >= diffInSeconds;

  let diffInSeconds = saleEndDate ? getDiffInSeconds(saleEndDate) : 0;
  const hours = Math.floor((diffInSeconds / 3600) % 24);
  let diffForCountdown =
    diffInSecondsForCountdown !== undefined
      ? diffInSecondsForCountdown
      : hours
        ? `${buildLocale(currentTranslate, 'saleEndDateStillHours', { '{{HOURS}}': `${DEFAULT_EMPTY_COUNTDOWN_VALUE}` })}`
        : `${buildLocale(currentTranslate, 'saleEndDateStillMinutes', { '{{MINUTES}}': `${DEFAULT_EMPTY_COUNTDOWN_VALUE}` })}`;
  let hasCountdown = isCountdown !== undefined ? isCountdown : needCountdown && isCountdownAvailable(diffInSeconds);

  const resetCountdownInterval = () => {
    clearInterval(intervalId);
    setIsCountdown(false);
  };

  const getEndDateInUtc = (saleEndDate) => {
    const saleEndDateObj = new Date(saleEndDate);
    return new Date(
      Date.UTC(
        saleEndDateObj.getUTCFullYear(),
        saleEndDateObj.getUTCMonth(),
        saleEndDateObj.getUTCDate(),
        saleEndDateObj.getUTCHours(),
        saleEndDateObj.getUTCMinutes(),
        saleEndDateObj.getUTCSeconds(),
      ),
    );
  };

  const runCountdownProcess = () => {
    if (!saleEndDate) {
      clearInterval(intervalId);
      return;
    }
    const endDateInUtc = getEndDateInUtc(saleEndDate);
    endDateInUtc.setSeconds(endDateInUtc.getSeconds());
    const interval = setInterval(() => {
      endDateInUtc.setSeconds(endDateInUtc.getSeconds());
      const newDiff = getDiffInSeconds(endDateInUtc.toString());
      setDiffInSecondsForCountdown(newDiff);
      if (newDiff < 1) {
        resetCountdownInterval();
      }
    }, 1000);
    setIntervalId(interval);

    return interval;
  };

  const isDefaultEmptyCountdown = (): boolean => {
    return (
      typeof diffForCountdown === 'string' &&
      diffForCountdown.includes(DEFAULT_EMPTY_COUNTDOWN_VALUE) &&
      isCountdownAvailable(diffInSeconds)
    );
  };

  useEffect(() => {
    if (!needCountdown) {
      return;
    }
    if (isDefaultEmptyCountdown()) {
      setIsCountdown(true);
      return;
    }
    setIsCountdown(!!diffInSecondsForCountdown && diffInSecondsForCountdown > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diffInSecondsForCountdown]);

  useEffect(() => {
    if (!saleEndDate) {
      return;
    }
    if (diffInSeconds < 1) {
      return;
    }
    if (!needCountdown || !isCountdownAvailable(diffInSeconds)) {
      return;
    }
    const intervalId = runCountdownProcess();
    if (!intervalId) {
      return;
    }
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleEndDate, needCountdown]);

  return (
    <span className="item-status item-status_sale">
      {!!saleEndDate && diffInSeconds > 0 && (
        <span className={`sale-date${needCountdown ? '_bg' : ''}`}>
          {!hasCountdown && getDateTimeDuration(diffInSeconds, currentTranslate)}
          {hasCountdown && isDefaultEmptyCountdown() && <>{diffForCountdown}</>}
          {hasCountdown &&
            Number(diffForCountdown) > 0 &&
            getDateTimeDurationWithCountdown(Number(diffForCountdown), currentTranslate)}
        </span>
      )}
      {getDiscountPercentage(actualSalePrice, actualPrice)}
    </span>
  );
};

export default SaleDiscountWidget;
