import config from '../config';
import defaultImg from '../assets/images/pic-default.png';
import { IImage } from '../typings/CustomTypes/IImage';

const getUrlImg = (name: IImage[] | IImage | string | null) => {
  if (!name) return defaultImg; //todo default image

  if (typeof name === 'string') {
    return config.baseURL + 'images/' + name;
  }
  if (Array.isArray(name)) {
    if (name.length === 0) {
      return defaultImg;
    }
    return config.baseURL + 'images/' + name[0].uuid;
  }
  return config.baseURL + 'images/' + name.uuid;
};

export default getUrlImg;
