import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Base64 } from 'js-base64';
import configureStore from './redux/store';
import { ApiProvider, loadApiCache } from 'react-use-api';
import instance from './services/instance';
import { CustomContext } from 'react-use-api/build/typings';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { actionsOther, fetchGetAllSettings } from './redux/other/otherReducer';
import service from './services/service';
import { RootState } from './redux/reducer';
import { actionsCart } from './redux/cart/cartReducer';
import broadcast from './context/BroadcastContext';
import { refreshUser } from './redux/user/userReducer';
import { serviceGetCustomCategories } from './redux/category/categoryReducer';
import { HelmetProvider } from 'react-helmet-async';
import initi18n from './initi18n';
import ProviderEventRedux from './context/EventReduxContext';
import { thunkDeliveryTypes, thunkGetSellPoints } from './redux/sellPoints/sellPointsReducer';
import getIsExistSellPoint from './utils/isExistSellPoint';
import getIsNotExistInPO from './utils/getIsNotExistInPO';
import { actionsOrder } from './redux/order/orderReducer';
import getLocale from './utils/getLocale';
import { I18nextProvider, withSSR } from 'react-i18next';
import { TypeDelivery } from './constants/constantsId';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { thunkGetAllCities } from './redux/city/cityReducer';
import { ref_cartProducts } from './utils/refReact';
import cloneArray from './utils/cloneArray';
import { thunkGetHTMLTranslations, thunkGetTranslations } from './redux/locale/localeReducer';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import { selectorsDelivery } from './redux/delivery/deliveryReducer';
import config from './config';
import './assets/scss/stylesMetro.scss';
import 'react-multi-carousel/lib/styles.css';
import 'react-phone-input-2/lib/style.css';

serviceWorkerRegistration.unregister();

if (config.isErrorReportingEnabled === 'true') {
  Sentry.init({
    dsn: config.sentryDsn,
    maxBreadcrumbs: Number(config.sentryMaxBreadcrumbs || 3),
    release: config.versionTag,
  });
}

const { store, persistor } = configureStore(
  new CookieStorage(Cookies),
  (window as any).DATA ? JSON.parse(Base64.decode((window as any).DATA)) || {} : {},
);

const apiContext: CustomContext = {
  settings: {
    axios: instance,
    shouldUseApiCache: () => true,
    maxRequests: Number(config.maxApiRequests) || 100,
  },
};

loadApiCache();

const name = Base64.decode((window as any).DATA) === '{}' ? 'render' : 'hydrateRoot';
delete (window as any).DATA;
const state: RootState = store.getState();

const locale: any = getLocale(state);

Cookies.set('locale', locale);

loadPolyfills()
  .then(preparingToRender)
  .then((i18n) => {
    if (name === 'hydrateRoot' && window.location.pathname.indexOf('pay-page') === -1) {
      const ExtendedApp = withSSR()(App);
      hydrateRoot(
        document.getElementById('root')!,
        <Sentry.ErrorBoundary>
          <HelmetProvider>
            <I18nextProvider i18n={i18n}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  {() => (
                    // @ts-ignore
                    <ApiProvider context={apiContext}>
                      <ProviderEventRedux>
                        <BrowserRouter>
                          <ExtendedApp />
                        </BrowserRouter>
                      </ProviderEventRedux>
                    </ApiProvider>
                  )}
                </PersistGate>
              </Provider>
            </I18nextProvider>
          </HelmetProvider>
        </Sentry.ErrorBoundary>,
      );
    } else {
      const root = createRoot(document.getElementById('root')!);
      root.render(
        <Sentry.ErrorBoundary>
          <HelmetProvider>
            <I18nextProvider i18n={i18n}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  {() => (
                    // @ts-ignore
                    <ApiProvider context={apiContext}>
                      <ProviderEventRedux>
                        <BrowserRouter>
                          <App />
                        </BrowserRouter>
                      </ProviderEventRedux>
                    </ApiProvider>
                  )}
                </PersistGate>
              </Provider>
            </I18nextProvider>
          </HelmetProvider>
        </Sentry.ErrorBoundary>,
      );
    }
  });

async function preparingToRender() {
  await fetchData();
  store.dispatch(thunkGetAllCities(locale));

  const state: RootState = store.getState();
  const zipCode = selectorsDelivery.getZipCode(state);
  const isBot = state.other.isBot;

  if (isBot) {
    return initi18n(locale);
  }

  broadcast.init(store.dispatch);
  updateUser();

  loadCart(zipCode).then(() => {
    const state: RootState = store.getState();
    if (state.order.deliveryType === null) {
      store.dispatch(
        actionsOther.setData({
          isOpenDeliveryType: true,
        }),
      );
    }
  });

  window.addEventListener('blur', () => {
    const state: RootState = store.getState();
    broadcast.broadcastCart(state.cart.data);
  });

  return initi18n(locale);
}

async function fetchData() {
  await store.dispatch(serviceGetCustomCategories());
  await store.dispatch(fetchGetAllSettings());
  store.dispatch(actionsOther.setLocale(locale as any));
  await store.dispatch(thunkGetTranslations());
  await store.dispatch(thunkGetHTMLTranslations());
}

const updateUser = () => {
  const state: RootState = store.getState();
  if (!state.user.data) {
    store.dispatch(refreshUser);
  }
};
const loadCart = (zipCode: string | null) => {
  ref_cartProducts.current = [];

  return loadPayloadData().then(() => {
    service
      .getCart(zipCode)
      .then((res) => {
        const state: RootState = store.getState();
        const {
          items,
          sellPoint,
          deliveryType,
          total,
          bottleTotalDeposit,
          deliveryCost,
          rackTotalDeposit,
          totalWeightDeposit,
          totalWeight,
          notAvailableProducts,
          productsPrice,
        } = res;
        if (!sellPoint || items.length === 0 || deliveryType === null) {
          return false;
        }
        store.dispatch(actionsCart.updateCartProducts(items));
        store.dispatch(actionsCart.setData(items));
        store.dispatch(
          actionsCart.setPrices({
            total,
            bottleTotalDeposit,
            deliveryCost,
            rackTotalDeposit,
            totalWeightDeposit,
            totalWeight,
            productsPrice,
          }),
        );
        const allSellPoints = state.sellPoints.data;
        const isExistSellPoint = getIsExistSellPoint(allSellPoints, sellPoint.id);

        if (!isExistSellPoint) {
          return false;
        }

        if (notAvailableProducts) {
          store.dispatch(actionsCart.setNotAvailableProducts(notAvailableProducts));
        }

        const isExistInPO = getIsNotExistInPO(items, sellPoint.id);
        if (isExistInPO) {
          return false;
        }
        ref_cartProducts.current = cloneArray(items);

        store.dispatch(actionsCart.updateCart(sellPoint.id));
        store.dispatch(
          actionsOrder.setData({
            deliveryType,
            sellPoint: deliveryType.code === TypeDelivery.courier ? null : +sellPoint.id,
          }),
        );

        return true;
      })
      .then((res) => {
        if (!res) {
          store.dispatch(
            actionsOther.setData({
              isOpenDeliveryType: true,
            }),
          );
        }
      })
      .catch((e) => {
        store.dispatch(
          actionsOther.setData({
            isOpenDeliveryType: true,
          }),
        );
      });
  }) as any;
};

const loadPayloadData = () => {
  return Promise.all([store.dispatch(thunkGetSellPoints(locale)), store.dispatch(thunkDeliveryTypes)]);
};

function loadPolyfills() {
  const polyfills: any[] = [];

  if (!supportsResizeObserver()) {
    polyfills.push(import('resize-observer-polyfill'));
  }
  if (!supportsBroadcastChannel()) {
    polyfills.push(import('broadcastchannel-polyfill'));
  }

  return Promise.all(polyfills);
}

function supportsResizeObserver() {
  return 'ResizeObserver' in global && 'ResizeObserverEntry' in global;
}

function supportsBroadcastChannel() {
  return 'BroadcastChannel' in global;
}
