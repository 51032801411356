import React, { useMemo, useRef, useState } from 'react';
import useParseQuery from '../useHooks/useParseQuery';
import BarLoader from 'react-spinners/BarLoader';
import { useSelector } from 'react-redux';
import { selectorsCategory } from '../redux/category/categoryReducer';
import useDidUpdateEffect from '../useHooks/useDidUpdateEffect';
import CommonHelmet from '../components/common/CommonHelmet';
import Products from '../components/Products/Products';
import CategoryFirstLevel from '../components/Category/CategoryFirstLevel';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { useT } from '../useHooks/useT';
import { usePaths } from '../components/Routes/RouterList';
import config from '../config';
import {
  getBannerBullets,
  getBannerHeight,
  getBannerImage,
  getBannerTitle,
  isHasBanner,
} from '../utils/bannerPrictures';
import { SeoTexts } from '../constants/seoTexts';
import { useParams, useSearchParams } from 'react-router-dom';
import { selectorsFilters } from '../redux/filters/filtersReducer';
import NotFoundComponent from './common/NotFoundPage';
import { useHelmetMetaLinkQueryParamsForBrandsProduct } from '../useHooks/useHelmetMetaLinkQueryParamsForBrandsProduct';

const ProductsPage = (props: any) => {
  const urlParams = useParams();
  const [searchParams] = useSearchParams();
  const t = useT();
  const paths = usePaths();
  const getCategoryParam = (): string => {
    return urlParams['category'] || '';
  };
  const paramCategory = getCategoryParam();
  const helmetMetaLinkQueryParams = useHelmetMetaLinkQueryParamsForBrandsProduct();
  const currentCategory = useSelector(selectorsCategory.getCategoriesById(paramCategory));
  const chainsCategory = useSelector(selectorsCategory.getChainsCategories(paramCategory));
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const totalSelectedFiltersCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const [isLoading, setIsLoading] = useState(false);
  const query = useParseQuery('search');
  const refCategory = useRef(null as any | null);
  const startPage = useMemo(() => Number(searchParams.get('page')) || 1, [searchParams]);

  const metaData = useMemo(() => {
    let title = currentCategory?.seoTexts?.title || SeoTexts.title;
    if (selectedBrands.length === 1 && totalSelectedFiltersCount === 1) {
      title = `${currentCategory?.name} ${selectedBrands[0].nameDe} | MyBio.de`;
    }
    let description = currentCategory?.seoTexts?.description || SeoTexts.description;
    let h1 = currentCategory?.seoTexts?.header || '';
    const ogImg = currentCategory?.cdnImage?.variants?.public;

    if (startPage !== 1) {
      title = `${t('page')} ${startPage} - ${title}`;
      description = `${t('page')} ${startPage} - ${description}`;
      h1 = `${t('page')} ${startPage} - ${h1}`;
    }

    return {
      title,
      description,
      h1,
      ogImg,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory, selectedBrands, totalSelectedFiltersCount]);

  useDidUpdateEffect(() => {
    if (refCategory.current) {
      refCategory.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'center',
      });
    }
  }, [refCategory.current]);

  const bullets = getBannerBullets(currentCategory);

  if (!currentCategory) {
    return <NotFoundComponent />;
  }

  return (
    <section className="section-catalog">
      <CommonHelmet title={metaData.title} description={metaData.description} ogImg={metaData.ogImg}>
        <link rel="canonical" href={config.canLink + paths.products(paramCategory) + helmetMetaLinkQueryParams} />
      </CommonHelmet>
      {isLoading && <div className={'cover-products'} />}
      <BarLoader
        height={5}
        width={'100%'}
        color={'#ff9e1b'}
        loading={isLoading}
        // css={'position: fixed; z-index: 100'}
      />

      <section className={`section-main ${isHasBanner(currentCategory) ? '' : 'no-banner-crumbs'}`}>
        {!isHasBanner(currentCategory) && <Breadcrumbs idCategory={paramCategory} />}
        {isHasBanner(currentCategory) && (
          <div className="search-main" style={{ height: getBannerHeight(currentCategory) }}>
            <Breadcrumbs idCategory={paramCategory} />
            <figure className="search-main_pic">
              <img src={getBannerImage(currentCategory)} alt="" />
            </figure>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <h1 style={{ color: '#fff' }}>{getBannerTitle(currentCategory, metaData.h1, selectedBrands)}</h1>
                  <div className="row">
                    {bullets &&
                      bullets.map((item, index) => (
                        <>
                          <div key={index} className="col-md-4">
                            <article className="category-page_art" style={{ color: '#fff' }}>
                              {item}
                            </article>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {!isHasBanner(currentCategory) && (
        <h1 className="category-title">
          {currentCategory?.name || ''}
          {selectedBrands.length === 1 && totalSelectedFiltersCount === 1 ? ` ${selectedBrands[0].nameDe}` : ''}
        </h1>
      )}
      {currentCategory?.children ? (
        <CategoryFirstLevel
          currentCategory={currentCategory}
          setIsLoading={setIsLoading}
          query={query}
          chainsCategory={chainsCategory}
          startPage={startPage}
        />
      ) : (
        <Products
          className="col-12 catalog-right"
          query={query}
          seoText={startPage === 1 ? currentCategory?.seoText! : undefined}
        />
      )}
    </section>
  );
};

export default ProductsPage;
