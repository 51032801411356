import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import config18n from './assets/i18n/config';

const initI18n = async (locale: string) => {
  const instance = i18n.createInstance();

  await instance
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng: locale,
      fallbackLng: config18n.defaultLocale,

      interpolation: {
        escapeValue: false,
      },
      react: {
        transSupportBasicHtmlNodes: true,
      },
    });

  return instance;
};

export default initI18n;
