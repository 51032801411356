export enum productUnitsAlias {
  kg = 'KGM',
  portion = 'PTN',
  piece = 'PCE',
  pack = 'PK',
  g = 'GRM',
  l = 'LTR',
  m = 'MTR',
  ml = 'MLT',
  pair = 'PR',
  m2 = 'MTK',
  cm2 = 'CMK',
  cm = 'CMT',
}
