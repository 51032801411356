import { useCallback, useEffect, useState } from 'react';
import { isBrowser } from '../utils/isBrowser';

export const useDropDown = (className: string) => {
  const [isShow, setIsShow] = useState(false);

  const handle = useCallback((event: any) => {
    if (!event.target.closest('.' + className)) {
      setIsShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('click', handle);
      return () => {
        window.removeEventListener('click', handle);
      };
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { isShow, setIsShow };
};
