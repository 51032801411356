import HomePage from '../../views/HomePage';
import LoginPage from '../../views/LoginPage';
import ImpersonatePage from '../../views/ImpersonatePage';
import LandingPage1 from '../../views/LandingPage1';
import ConfidentialityPage from '../../views/ConfidentialityPage';
import AboutUsPage from '../../views/AboutUsPage';
import ProduktrueckrufePage from '../../views/ProduktrueckrufePage';
import AgbPage from '../../views/AgbPage';
import WiderrufsrechtPage from '../../views/WiderrufsrechtPage';
import PressePage from '../../views/PressePage';
import DeliveryPage from '../../views/DeliveryPage';
import RegionalProductsPage from '../../views/RegionalProductsPage';
import RegionalPartnersPage from '../../views/RegionalPartnersPage';
import RegistrationPage from '../../views/RegistrationPage';
import BrandsPage from '../../views/BrandsPage';
import BioBadgesPage from '../../views/BioBadgesPage';
import FinishRegistrationPage from '../../views/FinishRegistrationPage';
import ForgotPasswordMobile from '../../views/ForgotPasswordMobile';
import SearchingPage from '../../views/MobileSearching/SearchingPage';
import CartListPage from '../../views/CartListPage';
import PayPage from '../../views/PayPage';
import RestorePasswordPage from '../../views/RestorePasswordPage';
import ProductsPage from '../../views/ProductsPage';
import AllProductsPage from '../../views/AllProductsPage';
import ProductPage from '../../views/ProductPage';
import CategoriesPage from '../../views/CategoriesPage';
import OwnCategoriesPage from '../../views/OwnCategoriesPage';
import ProfilePage from '../../views/ProfilePage';
import FAQPage from '../../views/FAQPage';
import CategoriesMobile from '../../views/CategoriesMobilePage';
import ImpressumPage from '../../views/ImpressumPage';
import WishlistPage from '../../views/WishlistPage';
import React, { ReactNode } from 'react';
import NotFoundComponent from '../../views/common/NotFoundPage';
import BrandPage from '../../views/BrandPage';

type RouteData = {
  path: string;
  element: ReactNode;
  route?: any;
  routes?: RouteData[];
  name?: string;
};

export interface IRoute {
  path: string;
  element: ReactNode;
  routes?: IRoute[];
}

const usePaths = () => {
  const data: RouteData[] = getData();

  return data
    .filter((d) => !!d.route)
    .reduce((a, b) => {
      a[b.name!] = b.route;
      return a;
    }, {}) as any;
};

const getData = (): RouteData[] => {
  return [
    {
      path: '/',
      element: <HomePage />,
      route: '/',
      name: 'main',
    },
    {
      path: '/home',
      element: <HomePage />,
      route: '/home',
      name: 'home',
    },
    {
      path: '/login',
      element: <LoginPage />,
      route: '/login',
      name: 'login',
    },
    {
      path: '/impersonate',
      element: <ImpersonatePage />,
      route: '/impersonate',
      name: 'impersonate',
    },
    {
      path: '/lp1',
      element: <LandingPage1 />,
      route: '/lp1',
      name: 'lp1',
    },
    {
      path: '/confidentiality',
      element: <ConfidentialityPage />,
      route: '/confidentiality',
      name: 'confidentiality',
    },
    {
      path: '/about_us',
      element: <AboutUsPage />,
      route: '/about_us',
      name: 'about_us',
    },
    {
      path: '/produktrueckrufe',
      element: <ProduktrueckrufePage />,
      route: '/produktrueckrufe',
      name: 'produktrueckrufe',
    },
    {
      path: '/presse',
      element: <PressePage />,
      route: '/presse',
      name: 'presse',
    },
    {
      path: '/delivery',
      element: <DeliveryPage />,
      route: '/delivery',
      name: 'delivery',
    },
    {
      path: '/regional_products',
      element: <RegionalProductsPage />,
      route: '/regional_products',
      name: 'regional_products',
    },
    {
      path: '/regional_partners',
      element: <RegionalPartnersPage />,
      route: '/regional_partners',
      name: 'regional_partners',
    },
    {
      path: '/registration',
      element: <RegistrationPage />,
      route: '/registration',
      name: 'registration',
    },
    {
      path: '/brands',
      element: <BrandsPage />,
      name: 'brands',
      route: '/brands',
    },
    {
      path: '/brands/:id',
      element: <BrandPage />,
      route: (id: number) => `/brands/${id}`,
      name: 'brand',
    },
    {
      path: '/bio_badges',
      element: <BioBadgesPage />,
      name: 'bio_badges',
      route: '/bio_badges',
    },
    {
      path: '/finish_registration',
      element: <FinishRegistrationPage />,
      route: '/finish_registration',
      name: 'finish_registration',
    },
    {
      path: '/forgot_pass',
      element: <ForgotPasswordMobile />,
      route: '/forgot_pass',
      name: 'forgot_pass',
    },
    {
      path: '/searching',
      element: <SearchingPage />,
      route: '/searching',
      name: 'searching',
    },
    {
      path: '/cart-list',
      element: <CartListPage />,
      route: '/cart-list',
      name: 'cart-list',
    },
    {
      path: '/pay-page',
      element: <PayPage />,
      route: '/pay-page',
      name: 'pay-page',
    },
    {
      path: '/reset-password',
      element: <RestorePasswordPage />,
      route: '/reset-password',
      name: 'restorePassword',
    },
    {
      path: '/products/:category',
      element: <ProductsPage />,
      route: (idCategory: number) => `/products/${idCategory}`,
      name: 'products',
    },
    {
      path: '/all_products',
      element: <AllProductsPage />,
      name: 'all_products',
      route: '/all_products',
    },
    {
      path: '/product/:product',
      element: <ProductPage />,
      name: 'product',
      routes: [
        {
          path: '/product/:product/:tab',
          element: <ProductPage />,
          name: 'product_tab',
        },
      ],
      route: (idProduct: number, tab?: number) => {
        if (tab) {
          return `/product/${idProduct}/${tab}`;
        }
        return `/product/${idProduct}`;
      },
    },
    {
      path: '/categories/:id',
      element: <CategoriesPage />,
      route: (id: number) => `/categories/${id}`,
      name: 'categories',
    },
    {
      path: '/own_categories/:id',
      element: <OwnCategoriesPage />,
      route: (id: number) => `/own_categories/${id}`,
      name: 'own_categories',
    },
    {
      path: '/profile/:option',
      element: <ProfilePage />,
      name: 'profile',
      routes: [
        {
          path: '/profile',
          element: <ProfilePage />,
          name: 'profile_base',
        },
      ],
      route: (option?: string) => (option ? `/profile/${option}` : '/profile'),
    },
    {
      path: '/faq',
      element: <FAQPage />,
      route: '/faq',
      name: 'faq',
    },
    {
      path: '/agb',
      element: <AgbPage />,
      route: '/agb',
      name: 'agb',
    },
    {
      path: '/widerrufsrecht',
      element: <WiderrufsrechtPage />,
      route: '/widerrufsrecht',
      name: 'widerrufsrecht',
    },
    {
      path: '/categories_page',
      name: 'categories_page',
      element: <CategoriesMobile />,
      routes: [
        {
          path: '/categories_page/:type',
          element: <CategoriesMobile />,
          name: 'categories_page_type',
        },
        {
          path: '/categories_page/:type/:id',
          element: <CategoriesMobile />,
          name: 'categories_page_type',
        },
      ],
      route: (tab: string, id: number) => {
        if (id && tab) {
          return `/categories_page/${tab}/${id}`;
        }
        if (tab && !id) {
          return `/categories_page/${tab}`;
        }
        return '/categories_page';
      },
    },
    {
      path: '/impressum',
      element: <ImpressumPage />,
      route: '/impressum',
      name: 'impressum',
    },
    {
      path: '/wishlist',
      element: <WishlistPage />,
      route: '/wishlist',
      name: 'wishlist',
    },
    {
      path: '*',
      element: <NotFoundComponent />,
      name: 'not_found',
    },
  ];
};
const RouterList = (): IRoute[] => {
  const data = getData();

  return data.map((routeItemData: RouteData) => ({
    path: routeItemData.path,
    element: routeItemData.element,
    routes: routeItemData?.routes || [],
  }));
};

export { usePaths };
export default RouterList;
