import { CreatorReducer } from '../base/base';
import { ISellPointsActions, ISellPointsState } from './sellPointsTypes';
import service from '../../services/service';
import { RootState } from '../reducer';
import { IDeliveryType } from '../../typings/IDeliveryType';
import { ISellPoint } from '../../typings/ISellPoint';
import { TypeDelivery } from '../../constants/constantsId';

const init: ISellPointsState = {
  data: [],
  error: null,
  isLoading: false,
  typesDelivery: [],
};

const creator = new CreatorReducer<ISellPointsActions, ISellPointsState>('sellPoints');
creator.addAction('setOptionData', (state, action) => {
  return { ...state, ...action.payload };
});

const actionsSellPoints = creator.createActions();

const thunkGetSellPoints = (locale?: any) => async (dispatch: any, getStore: () => RootState) => {
  if (getStore().sellPoints.data.length > 0) return;

  dispatch(actionsSellPoints.setLoading(false));
  try {
    const res = await service.getSellPoints(locale);
    if (!res.success) {
      throw res.data;
    }
    //todo delete
    const sellpoints: ISellPoint[] = res.data;
    sellpoints.forEach((s) => {
      s.img = s.sellPointImage ? s.sellPointImage.uuid : null;
    });

    dispatch(actionsSellPoints.setData(sellpoints));
  } catch (e) {
    dispatch(actionsSellPoints.setError(e));
  } finally {
    dispatch(actionsSellPoints.setLoading(false));
  }
};

const thunkDeliveryTypes = async (dispatch: any, getStore: () => RootState) => {
  if (getStore().sellPoints.typesDelivery.length > 0) return;

  dispatch(actionsSellPoints.setLoading(false));
  try {
    const res = await service.getDeliveryTypes();
    if (!res.success) {
      throw res.data;
    }
    //todo delete
    const deliveryTypes: IDeliveryType[] = res.data;

    /* sellpoints.forEach((s) => {
      s.img = s.sellPointImage ? s.sellPointImage.uuid : null;
    }); */

    dispatch(
      actionsSellPoints.setOptionData({
        typesDelivery: deliveryTypes.filter((d) => d.code !== TypeDelivery.drive),
      }),
    );
  } catch (e) {
    dispatch(actionsSellPoints.setError(e));
  } finally {
    dispatch(actionsSellPoints.setLoading(false));
  }
};

const getSellPoints = (ignoreIsActive: boolean) => (state: RootState) => {
  if (ignoreIsActive) {
    return state.sellPoints.data;
  }
  return state.sellPoints.data.filter((s) => s.isActive);
};

const getSellPoint = (id: number | null) => (state: RootState) => {
  return getSellPoints(false)(state).find((s) => s.id === id);
};

const selectorSellPoint = {
  getDeliveryTypes: (state: RootState) => state.sellPoints.typesDelivery,
  getAllSellPoints: (state: RootState) => state.sellPoints.data,
};
export { actionsSellPoints, thunkGetSellPoints, getSellPoints, getSellPoint, selectorSellPoint, thunkDeliveryTypes };
export default creator.createReducerFetch(init);
