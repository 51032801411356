import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ClipLoader from 'react-spinners/ClipLoader';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import service from '../../services/service';
import { IBioBadgeItem } from '../../typings/IBioBadges';
import ProductImage from '../ImagesComponent/ProductImage';
import { buildLocale } from '../../utils/buildLocale';
import { setLoaderColor } from '../../utils/setLoaderColor';

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  currentId: number | null;
}

const BioBadgeModal = (props: IProps) => {
  const { isOpen, closeModal, currentId } = props;
  const [bioBadgeInfo, setBioBadgeInfo] = useState<IBioBadgeItem | null>(null);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  useEffect(() => {
    if (currentId) {
      service
        .getBioBadgeById(currentId)
        .then((value) => setBioBadgeInfo(value.data))
        .catch((error) => {
          console.error(error.message);
          closeModal();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal className="brands-modal_modal" isOpen={isOpen}>
      {!bioBadgeInfo ? (
        <div className="loading-center">
          <ClipLoader size={30} color={setLoaderColor()} loading={true} />
        </div>
      ) : (
        <div className="brands-modal">
          <figure className="brands-modal_logo">
            <ProductImage
              product={{ ...(bioBadgeInfo as any), title: bioBadgeInfo.name, cdnImages: [bioBadgeInfo.logo] }}
              isDefaultImg={!bioBadgeInfo.logo}
            />
          </figure>
          <div className="brands-modal_content">
            <p className="brands-modal_name">{bioBadgeInfo.nameDe || ''}</p>
            {bioBadgeInfo.descriptionDe && (
              <div
                className="brands-modal_text"
                dangerouslySetInnerHTML={{
                  __html: bioBadgeInfo.descriptionDe,
                }}></div>
            )}
          </div>
          <div className="brands-modal_buttons">
            <button className="btn btn-border btn-orange-border" onClick={closeModal}>
              {buildLocale(currentTranslate, 'bioBadgesModalClose')}
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BioBadgeModal;
