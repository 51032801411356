import React from 'react';
import ShowError from './ShowError';
import { generateClassName } from '../../../utils/generateClassName';

interface IInputItemProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  errors: any;
  label?: string;
  name: string;
  value?: string | any;
}

const ItemInput = React.forwardRef(({ label, name, type, errors, placeholder, ...rest }: IInputItemProps, ref: any) => {
  const isError = !!errors[name];

  return (
    <div className="profile-info_row">
      {label && <label className="profile-info_label">{label}</label>}
      <div className={'profile-form-item'}>
        <input
          type={type || 'text'}
          className={generateClassName('form-control', {
            'error-form-border': isError,
          })}
          name={name}
          ref={ref}
          {...rest}
          placeholder={label || placeholder}
        />
      </div>
      <ShowError errors={errors} name={name} />
    </div>
  );
});

const MiniItemInput = React.forwardRef(
  (
    { label, name, type = 'text', errors, placeholder, onChange, defaultValue, value, ...rest }: IInputItemProps,
    ref: any,
  ) => {
    const isError = !!errors[name];

    return (
      <div
        className={generateClassName('form-part', {
          filled: !!value,
        })}>
        {label && <label>{label}</label>}
        <div className={'profile-form-item'}>
          <input
            type={type}
            name={name}
            ref={ref}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            className={generateClassName('form-control', {
              'error-form-border': isError,
            })}
            {...rest}
          />
          <ShowError errors={errors} name={name} />
        </div>
      </div>
    );
  },
);

export { MiniItemInput };
export default ItemInput;
