import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { usePaths } from '../Routes/RouterList';
import { buildLocale } from '../../utils/buildLocale';
import { setLocaleStorageItem } from '../../utils/localStorageHelper';
import { actionsOther } from '../../redux/other/otherReducer';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const NonAuthModal = (props: IProps) => {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const paths = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const setAsGuest = () => {
    setLocaleStorageItem('asGuest', 'true');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} className={`modal-notify`}>
      <div className="modal-notify_content">
        <button className="modal-close" onClick={() => dispatch(actionsOther.setIsOpenNonAuth(false))}>
          &nbsp;
        </button>
        <h3>{buildLocale(currentTranslate, 'nonAuthTitle')}</h3>
        <span className="btn" onClick={setAsGuest}>
          {buildLocale(currentTranslate, 'nonAuthGuest')}
        </span>
        <Link className="btn" to={paths.login} onClick={onClose}>
          {buildLocale(currentTranslate, 'nonAuthLogin')}
        </Link>
        <Link className="btn btn-border" to={paths.registration} onClick={onClose}>
          {buildLocale(currentTranslate, 'loginToRegistrationLink')}
        </Link>
      </div>
    </Modal>
  );
};

export default NonAuthModal;
