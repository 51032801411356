import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../../../../redux/locale/localeReducer';
import { AttributesType, IBrand, IBrands } from '../../../../../typings/IFilters';
import useComponentVisible from '../../../../../useHooks/useComponentVisible';
import { buildLocale } from '../../../../../utils/buildLocale';
import CustomSelectOption from './CustomSelectOption';

interface IProps {
  items: IBrands;
  shownItems: IBrands;
  setShownItems: (items: IBrands | AttributesType) => void;
  onSearch: (text: string) => void;
  isLoading: boolean;
  isOpenCurrent: boolean;
  addItem: (item: IBrand) => void;
  isChecked: (id: number) => boolean;
  removeItem: (item: IBrand) => void;
  setIsOpenCurrent: (isOpen: boolean) => void;
  title: string;
}

const CustomDropdownFilter = (props: IProps) => {
  const {
    items,
    shownItems,
    setShownItems,
    isLoading,
    onSearch,
    isOpenCurrent,
    addItem,
    isChecked,
    removeItem,
    setIsOpenCurrent,
    title,
  } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const { ref: inputRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpenCurrent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  const handleToggle = () => {
    setIsComponentVisible((f) => !f);
    setShownItems(items);
  };

  return (
    <>
      {items.length > 0 && (
        <div
          className="category-filters_item category-filters_item-more"
          ref={inputRef}
          onClick={() => {
            handleToggle();
            setIsOpenCurrent(!isOpenCurrent);
          }}>
          <span>{title}</span>
          {isOpenCurrent && (
            <div className="mobile-filter_checkbox-list">
              <div className="filter-search">
                <input
                  className="form-control"
                  placeholder={buildLocale(currentTranslate, 'filtersSearchPlaceholder')}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    e.stopPropagation();
                    onSearch(e.target.value);
                  }}
                />
              </div>
              {shownItems.map((item) => (
                <CustomSelectOption
                  addBrand={addItem}
                  brand={item}
                  isChecked={isChecked}
                  isLoading={isLoading}
                  removeBrand={removeItem}
                  key={item.id}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CustomDropdownFilter;
