import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import BrandModal from '../components/BrandsPage/BrandModal';
import BrandsHeader from '../components/BrandsPage/BrandsHeader';
import Pagination from '../components/common/Pagination';
import ProductImage from '../components/ImagesComponent/ProductImage';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { IBrands } from '../typings/IBrands';
import { blockBodyScroll } from '../utils/blockBodyScroll';
import { buildLocale } from '../utils/buildLocale';
import queries from '../services/queries';
import useApi from 'react-use-api';
import { useNavigate, useNavigationType } from 'react-router';
import CommonHelmet from '../components/common/CommonHelmet';
import config from '../config';
import { usePaths } from '../components/Routes/RouterList';
import ProductLoader from '../components/Loaders/ProductLoader';
import GoBackBtn from '../components/CategoriesMobile/GoBackBtn';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';
import { selectorsOther } from '../redux/other/otherReducer';
import { useLessThen991 } from '../utils/mediaQuery';
import { useRouterStaticContext } from '../context/RouterStaticContext';
import NotFoundComponent from './common/NotFoundPage';

const BrandsPage = () => {
  const paths = usePaths();
  const [searchParams] = useSearchParams();
  const paginationParamValue = Number(searchParams.get('page')) || 1;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const limit = 20;
  const offset = paginationParamValue > 1 ? (paginationParamValue - 1) * limit : 0;
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const navigationType = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate();
  const staticContext = useRouterStaticContext();

  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [currentBrandId, setCurrentBrandId] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const backBtn = useMemo(() => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return '';
    }
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={currentTranslate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleData = (data: any, state: any) => {
    const { error } = state;
    if (!error) {
      const { items, count } = data;
      setCount(count);
      return [...items];
    }
    console.error(error.response.data.msg);
    return [];
  };

  const getBrands = (search: any, top?: number, skip?: number) => queries.getBrands(search, top, skip);
  const [brands, { loading: isLoading, error }] = useApi<IBrands>(getBrands(search, limit, offset), { handleData });

  if (staticContext && error?.status === 404) {
    staticContext.statusCode = 404;
  }

  useEffect(() => {
    blockBodyScroll(isOpen);
  }, [isOpen]);

  const openBrandModal = (id: number) => {
    setCurrentBrandId(id);
    setIsOpen(true);
  };

  const closeBrandModal = () => {
    setIsOpen(false);
    setCurrentBrandId(null);
  };

  const onSearch = (text: string) => {
    setSearch(text);
  };

  if (error?.status === 404) {
    return <NotFoundComponent />;
  }

  return (
    <section className="brands-page">
      <CommonHelmet title={`${buildLocale(currentTranslate, 'brandsBreadcrumps')} | MyBio.de`}>
        <link rel="canonical" href={config.canLink + paths.brands} />
      </CommonHelmet>
      {backBtn}
      <BrandsHeader onSearch={onSearch} />
      {isLoading && <ProductLoader />}
      {!isLoading && (
        <div className="container">
          <ul className="brands-list">
            {!!brands?.length &&
              brands.map((brand: any) => (
                <li onClick={() => openBrandModal(brand.id)} key={brand.id}>
                  <figure className="brands-logo">
                    <ProductImage
                      product={{ ...brand, title: brand.name, cdnImages: [brand.logo] }}
                      isDefaultImg={!brand.logo}
                    />
                  </figure>
                </li>
              ))}
          </ul>
          <Pagination availableCount={1000} count={count} perPage={limit} loading={isLoading} />
        </div>
      )}
      {isOpen && (
        <BrandModal isOpen={isOpen} currentId={currentBrandId} closeModal={closeBrandModal} location={location} />
      )}
    </section>
  );
};

export default BrandsPage;
