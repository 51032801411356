import React from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { setLoaderColor } from '../../utils/setLoaderColor';

interface IContainerModalProps {
  title: string;
  idForm: string;
  isAdd?: boolean;
  isOpen: boolean;
  toggle: () => any;
  isLoading: boolean;
  children: any;
  className?: string;
}

const ContainerModal: React.FC<IContainerModalProps> = React.memo(
  ({ children, title, idForm, isOpen, toggle, isLoading, className, isAdd = true }) => {
    const currentTranslate = useSelector(selectorsLocale.getTranslate);

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={toggle}
        contentLabel="Example Modal"
        className={`edit_modal ${className}`}
        overlayClassName="edit_overlay payment_overlay">
        <div className="modal-dialog modal_add-card">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{title}</h4>
              {isLoading && <ClipLoader size={30} color={setLoaderColor()} loading={isLoading} />}
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
              <button disabled={isLoading} type="button" className="btn btn-border" onClick={toggle}>
                {buildLocale(currentTranslate, 'btnCancel')}
              </button>
              <button disabled={isLoading} form={idForm} className="btn">
                {isAdd ? buildLocale(currentTranslate, 'btnAdd') : buildLocale(currentTranslate, 'btnSave')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

export default ContainerModal;
