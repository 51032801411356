import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { selectorsUser } from '../../../redux/user/userReducer';
import { IContact } from '../../../typings/IContact';
import useComponentVisible from '../../../useHooks/useComponentVisible';
import { buildLocale } from '../../../utils/buildLocale';
import { formatUserProfile } from '../../../utils/formatUserProfile';
import { email, firstName, lastName, phone } from '../../../constants/payData';

interface IProps {
  setUserData: (profile: IContact) => void;
  updateStorageUser: (user: any) => void;
  setValue: (key: string, value?: string | null) => void;
}

const UserProfilesDropdown = (props: IProps) => {
  const { setUserData, updateStorageUser, setValue } = props;
  const profiles = useSelector(selectorsUser.getContacts);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [showInfo, setShowInfo] = useState<any>(null);

  const { ref: inputRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const selectProfile = (profile: IContact) => {
    setUserData(profile);
    setValue(firstName, profile.firstName);
    setValue(lastName, profile.lastName);
    setValue(phone, profile.phone);
    setValue(email, profile.email);
    updateStorageUser(profile);
    setIsComponentVisible(false);
    setShowInfo(null);
  };

  const handleToggle = () => {
    setIsComponentVisible((f) => !f);
  };

  if (!profiles || profiles.length === 0) {
    return null;
  }

  return (
    <div className="pay-dropdown" ref={inputRef}>
      <span className="link" onFocus={handleToggle} tabIndex={1}>
        {buildLocale(currentTranslate, 'payPageUserDropdown')}
      </span>
      {isComponentVisible && (
        <div className={`${isComponentVisible ? 'pay-dropdown_block' : ''}`}>
          {profiles.map((profile) => (
            <div
              className="info-row"
              key={profile.id}
              onClick={() => selectProfile(profile)}
              onMouseEnter={() => setShowInfo(formatUserProfile(profile))}
              onMouseLeave={() => setShowInfo(null)}>
              {formatUserProfile(profile)}
            </div>
          ))}
        </div>
      )}
      {showInfo && <div className="info-row-tooltip profile-tooltip">{showInfo}</div>}
    </div>
  );
};

export default UserProfilesDropdown;
