import React from 'react';
import { NavLink } from 'react-router-dom';
import { usePaths } from '../Routes/RouterList';
import getShopLogo from '../../utils/getShopLogo';

const HeaderLogo = React.memo(() => {
  const paths = usePaths();

  return (
    <div className="header-logo">
      <NavLink to={paths.home} aria-label="Home">
        <img src={getShopLogo()} alt="" width="102" height="40" />
      </NavLink>
    </div>
  );
});

export default HeaderLogo;
