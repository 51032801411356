import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsCategory } from '../../redux/category/categoryReducer';
import { useLessThen991 } from '../../utils/mediaQuery';
import { usePaths } from '../Routes/RouterList';
import { BreadcrumbList, WithContext } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';
import { isBrowser } from '../../utils/isBrowser';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import config from '../../config';
import { useNavigate } from 'react-router';
import { IBreadcrumb } from '../../typings/IBreadcrumb';
import { actionsFilters } from '../../redux/filters/filtersReducer';
import { selectorsOther } from '../../redux/other/otherReducer';

export enum ParentType {
  brands = 'brands',
  brand = 'brand',
}

interface IBreadcrumbsProps {
  idCategory?: any;
  lastItemName?: string;
  type?: ParentType;
  additionalItems?: IBreadcrumb[];
}

const Breadcrumbs = ({ idCategory, lastItemName, type, additionalItems }: IBreadcrumbsProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const paths = usePaths();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const chainsCategory = useSelector(selectorsCategory.getChainsCategories(idCategory));
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const originPath = useMemo(() => {
    return isBrowser ? window.location.origin : config.canLink;
  }, []);

  const routes = (index?: number) => {
    if (index === 0) {
      return (id: any) => (id ? paths.own_categories(id) : paths.home);
    }
    switch (type) {
      case ParentType.brands:
        return () => paths.brands;
      case ParentType.brand:
        return (id: any) => paths.brand(id);
      default:
        return (id: any) => paths.products(id);
    }
  };

  const items = useMemo(() => {
    const breadcrumbs: IBreadcrumb[] = [{ name: buildLocale(currentTranslate, 'commonMain'), link: paths.home }];
    switch (type) {
      case ParentType.brands:
        breadcrumbs.push({ name: buildLocale(currentTranslate, 'brandsFooter'), link: paths.brands });
        break;
      case ParentType.brand:
        breadcrumbs.push({ name: buildLocale(currentTranslate, 'brandsFooter') });
        break;
      default:
        breadcrumbs.push(
          ...chainsCategory.map((category, index) => ({ ...category, link: routes(index)(category.id) })),
        );
    }
    additionalItems && breadcrumbs.push(...additionalItems);
    lastItemName && breadcrumbs.push({ name: lastItemName });
    return breadcrumbs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCategory, currentTranslate]);

  const generateSchemaOrgJson = (): WithContext<BreadcrumbList> => {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: items.map((crumb, index) => {
        if (index < items.length - 1) {
          return {
            '@type': 'ListItem',
            position: index + 1,
            name: crumb.name,
            item: originPath + crumb.link,
          };
        }
        return {
          '@type': 'ListItem',
          position: index + 1,
          name: crumb.name,
        };
      }),
    };
  };

  const onBreadcrumbsItemClick = (crumb: IBreadcrumb) => {
    const link = crumb.link;
    const filtersData = crumb?.filtersData || [];
    for (const filterDataItem of filtersData) {
      if (ParentType.brands === filterDataItem.filterName) {
        dispatch(actionsFilters.setSelectedBrands(filterDataItem.filterItems));
      }
    }
    navigate(link);
  };

  if (isLess991) {
    return null;
  }
  return (
    <nav aria-label="breadcrumb">
      <JsonLd item={generateSchemaOrgJson()} />
      <ol className="breadcrumb">
        {items.map((crumb, index) => {
          if (crumb.link !== undefined)
            return (
              <li key={index} className="breadcrumb-item" onClick={() => onBreadcrumbsItemClick(crumb)}>
                <a href={crumb.link} onClick={(e) => e.preventDefault()}>
                  {crumb.name}
                </a>
              </li>
            );
          return (
            <li key={index} className="breadcrumb-item active" aria-current="page">
              {crumb.name}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
