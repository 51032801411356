import { ICustomCategory } from '../typings/ICategory';

export const getOwnCategory = (categories: ICustomCategory[]): ICustomCategory | null => {
  // TODO: If necessary, implement the logic for searching own category
  // we take the first category for now
  if (categories && categories.length > 0) {
    return categories[0];
  }
  return null;
};
