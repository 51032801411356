import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';

import { IProduct } from '../../typings/IProduct';
import { ICartItem } from '../../typings/ICartItem';

import CartCountItem from '../Cart/CartCountItem';
import ProductImage from '../ImagesComponent/ProductImage';

import { getIndexProductOption } from '../../utils/getIndexProductOption';
import { useFormattingContext } from '../../context/FormattingContext';
import { actionsOther, selectorsOther } from '../../redux/other/otherReducer';
import ProductCard from '../Modals/ProductCard';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import { DL_helper } from '../../utils/DataLayer/DL_helper';
import { getCapacity } from '../../utils/calculateUnits';
import { buildLocale } from '../../utils/buildLocale';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import getPriceWithoutVAT from '../../utils/getPriceWithoutVAT';
import { isMetroShop } from '../../constants/shops';
import config from '../../config';
import SaleDiscountWidget from './SaleDiscountWidget';
import { useLessThen991 } from '../../utils/mediaQuery';
import getDiscountPercentage from '../../utils/getDiscountPercentage';
import { ID_UNIT_WEIGHT } from '../../constants/constantsId';

interface IProductProps {
  product: IProduct;
  setSearchField: any;
  setProducts: any;
}

/**
 * Search list item for the product you are looking for
 */
const SearchedItem = React.memo(({ product, setSearchField, setProducts }: IProductProps) => {
  const dispatch = useDispatch<any>();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [isShowProduct, setIsShowProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [isFavorite, setIsFavorite] = useState(product.isFavorite || false);
  const { formatPrice } = useFormattingContext();
  const isProductInCart = useSelector(selectorsCart.checkProductInCart(product.id));
  const cartProduct = useSelector(selectorsCart.getCartProduct(product.id));
  const cart = useSelector(selectorsCart.getCartProducts);
  const isProductSearchPopupOpen = useSelector(selectorsOther.getProductSearchPopupOpenState);
  const productsSearchingTotal = useSelector(selectorsOther.getProductsSearchingTotal);
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);

  const isMyBioShop = !isMetroShop(config.shopId);
  const ID_SELL_POINT = useSelector(selectorsCart.getIdSellPoint);
  const index = getIndexProductOption(product, ID_SELL_POINT);
  const currentProductOption = product.productOptions.length ? product.productOptions[index] : null;
  const price = currentProductOption ? +currentProductOption?.price : 0;
  const salePrice = currentProductOption ? currentProductOption?.salePrice : null;
  const alternativeCountProduct = useSelector(selectorsCart.getAlternativeCountProduct(product.id));
  const taxPercent = product?.taxCategory?.percent || 0;
  const isWeightUnit = product.unit && product.unit.externalId === ID_UNIT_WEIGHT;
  const isAvgWeight = !!product.avgWeight && isWeightUnit;
  const actualPrice = isMyBioShop ? price : getPriceWithoutVAT(price, taxPercent);
  const actualSalePrice = isMyBioShop ? salePrice : getPriceWithoutVAT(+salePrice!, taxPercent);
  const isAvailable = product && product.productOptions[0].availableAmount !== 0;
  const unitType = product.unit?.type || product.unit?.typeDe || product.unit?.typeEn;
  const capacity = getCapacity(product.quantityPerPack, unitType);

  useEffect(() => {
    if (!isShowProduct) {
      blockBodyScroll(false);
    }
  }, [isShowProduct]);

  const handleOrder = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!isAvailable) {
      return;
    }
    dispatch(
      actionsCart.addProduct({
        comment: '',
        count: isAvgWeight ? +product.avgWeight! : 1,
        product: product,
        alternativeCount: isAvgWeight ? 1 : null,
        services: [],
      }),
    );
    DL_helper(cart, 1, 'addToCart', product);
  };

  const selectProduct = () => {
    openProductCardPopup(product);
  };

  /**
   * Handle the product popup open event
   *
   * @param {Object} product
   */
  const openProductCardPopup = (product) => {
    if (!isShowProduct) {
      setIsShowProduct(true);
      setSelectedProduct(product);
    }
  };

  /**
   * Handle the product popup close event
   */
  const closeProductCardPopup = (needToCloseAdvancedModal = false) => {
    if (isShowProduct) {
      setIsShowProduct(false);
      setSelectedProduct({});
      if (isProductSearchPopupOpen) {
        setTimeout(() => {
          dispatch(
            actionsOther.closeProductSearchPopup(!needToCloseAdvancedModal ? productsSearchingTotal > 0 : false),
          );
        }, 0);
      }
    }
  };

  const resetSearchField = () => {
    setSearchField('');
    setProducts([]);
  };

  return (
    <li
      className={isAvailable ? 'search-result-item' : 'search-hint_no search-result-item'}
      key={product.id}
      onClick={() => {
        // close the product popup when outside of it is clicked
        if (!isAvailable) {
          return;
        }
        closeProductCardPopup();
      }}>
      <figure className="search-hint_pic">
        {actualSalePrice && (
          <>
            {isLess991 && (
              <SaleDiscountWidget
                productOption={currentProductOption}
                needCountdown={false}
                price={actualPrice}
                salePrice={actualSalePrice}
              />
            )}
            {!isLess991 && (
              <span className="item-status item-status_sale">
                {getDiscountPercentage(actualSalePrice, actualPrice)}
              </span>
            )}
          </>
        )}
        <ProductImage
          product={{ ...product, shortDescription: '' }}
          width={30}
          height={30}
          onClick={() => {
            if (!isAvailable) {
              return;
            }
            selectProduct();
          }}
        />
      </figure>

      <div
        className="search-hint_description product-title-item"
        onClick={() => {
          if (!isAvailable) {
            return;
          }
          selectProduct();
        }}>
        {product.title}
        {capacity && (
          <p>
            <span className="weight-unit">{capacity}</span>
          </p>
        )}
      </div>

      <div className="search-hint_info">
        {isProductInCart && <CartCountItem item={cartProduct as ICartItem} />}

        {!isProductInCart && isAvailable && (
          <button onClick={handleOrder} className="btn btn-buy-sm btn-plus">
            +
          </button>
        )}

        {isAvailable ? (
          <span className="search-hint_price">
            {actualSalePrice ? (
              <>
                <span className="price-old">
                  {alternativeCountProduct === null ? formatPrice(+actualPrice, true) : formatPrice(+actualPrice, true)}
                </span>
                <span className="price-sale">
                  {alternativeCountProduct === null
                    ? formatPrice(+actualSalePrice, true)
                    : formatPrice(+actualSalePrice, true)}
                </span>
              </>
            ) : (
              <span className="search-hint_price">
                {alternativeCountProduct === null ? formatPrice(+actualPrice, true) : formatPrice(+actualPrice, true)}
              </span>
            )}
          </span>
        ) : (
          <span className="info-center">{buildLocale(currentTranslate, 'runOutProduct')}</span>
        )}
        {isShowProduct && (
          <ProductCard
            isOpen={isShowProduct}
            id={selectedProduct.id}
            onProductCardClose={closeProductCardPopup}
            isFavorite={isFavorite}
            setIsFavorite={setIsFavorite}
            resetSearchField={resetSearchField}
          />
        )}
      </div>
    </li>
  );
});

export default SearchedItem;
