import { encodeUrlTailParamsForFilters } from './convertUrlTailParamsForFilters';

const createHashFromFilter = (propertyName: string, array: any[]) => {
  if (array.length < 1) {
    return '';
  }

  try {
    return `${propertyName}=${array.map(({ nameDe }) => encodeUrlTailParamsForFilters(nameDe)).join(',')}&`;
  } catch (error) {
    return '';
  }
};

export default createHashFromFilter;
