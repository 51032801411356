import { useEffect, useRef } from 'react';

const useDebouncedFunction = (func, delay) => {
  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleBlur = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      func();
    }, delay);
  };

  return handleBlur;
};

export default useDebouncedFunction;
