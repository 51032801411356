export const blockBodyScroll = (block: boolean) => {
  const body = document && document.body;
  if (body) {
    if (block) {
      body.style.overflowY = 'hidden';
    } else {
      body.style.overflowY = 'auto';
    }
  }
};
