import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCart, selectorsCart } from '../../../redux/cart/cartReducer';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { selectorsOther } from '../../../redux/other/otherReducer';
import { useFormattingContext } from '../../../context/FormattingContext';
import { productDefaultImage } from '../../../constants/productDefaultImage';
import { buildLocale } from '../../../utils/buildLocale';
import { DL_helper } from '../../../utils/DataLayer/DL_helper';
import CartCountItem from '../../Cart/CartCountItem';
import OtherImage from '../../ImagesComponent/OtherImage';
import ProductCard from '../../Modals/ProductCard';
import ICDNImage from '../../../typings/ICDNImage';
import { IProduct } from '../../../typings/IProduct';
import { calculatePriceWithCount, formatWeightWithType, getUnitType } from '../../../utils/calculateUnits';
import { generateClassName } from '../../../utils/generateClassName';
import { selectorsDelivery } from '../../../redux/delivery/deliveryReducer';

interface IOrderedProductItemProps {
  product: IProduct;
  count: string;
  productPrice: string;
  comment: string;
  weight?: string | null;
}

const OrderedProductItem = (props: IOrderedProductItemProps) => {
  const { product, count, comment, productPrice, weight } = props;
  const [available, setAvailable] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const isActive = product?.isActive;
  const dispatch = useDispatch();
  const productCart = useSelector(selectorsCart.getCartProduct(product.id));
  const cart = useSelector(selectorsCart.getCartProducts);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const currentLocale = useSelector(selectorsOther.getLocale);
  const { formatPrice } = useFormattingContext();
  const formattedWeight = weight && formatWeightWithType(product.unit.type, '', weight);
  const formattedPriceWithCount = calculatePriceWithCount(product?.unit?.type, count, product.quantityPerPack).toFixed(
    2,
  );
  const unitType = getUnitType(product?.unit?.type);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const isBoxDeliveryForbidden = !product?.isBoxDeliveryAllowed && isInCourierArea === false;

  useEffect(() => {
    setAvailable(product?.productOptions[0]?.availableAmount > 0);
  }, [product]);

  const addProductToCart = (count = 1) => {
    dispatch(
      actionsCart.addProduct({
        count,
        product,
        comment: '',
        alternativeCount: null,
        services: [],
      }),
    );
    DL_helper(cart, 1, 'addToCart', product);
  };

  const openProductCardPopup = () => {
    if (isActive) {
      setSelectedProduct(product);
    }
  };

  const closeProductCardPopup = () => {
    setSelectedProduct(null);
  };

  const getProductImage = (product: IProduct): ICDNImage => {
    if (product.cdnImages && product.cdnImages.length > 0 && product.cdnImages[0]) {
      return product.cdnImages[0];
    }
    return productDefaultImage;
  };

  const image = getProductImage(product);

  const setProductCount = () => {
    if (weight) {
      return formattedWeight;
    }
    if (product.isWeightable) {
      return `${formattedPriceWithCount} ${unitType}`;
    }
    return count;
  };

  return (
    <>
      <div className="profile-order_history-item">
        <div className="inner">
          <figure
            className="pic"
            onClick={(event) => {
              event.stopPropagation();
              openProductCardPopup();
            }}>
            <OtherImage src={image.variants['400x300']} title="" width="30" height="25" />
          </figure>
          <div className="info">
            <div
              className="info-left"
              onClick={(event) => {
                event.stopPropagation();
                openProductCardPopup();
              }}>
              <p className={generateClassName('info-name', { inactive: !isActive })}>
                {currentLocale === 'de' ? product.titleDe : product.titleEn}
              </p>
              {isBoxDeliveryForbidden && (
                <p className="info-name_not">{buildLocale(currentTranslate, 'wantToOrderDisableForBox')}</p>
              )}
            </div>
            <p className="info-price">
              {setProductCount()} x <span className="price">{formatPrice(+productPrice)}</span>
            </p>
          </div>
          <div className="buy-cart">
            {available && (
              <>
                {productCart && isActive && <CartCountItem item={productCart} />}
                {!productCart && (
                  <button onClick={() => addProductToCart()} className="btn btn-buy-sm btn-plus">
                    +
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {comment && (
          <p className="comment">
            <span className="caption">{buildLocale(currentTranslate, 'cartComment')}:</span>
            {comment}
          </p>
        )}
      </div>
      {selectedProduct && <ProductCard id={selectedProduct.id} onProductCardClose={closeProductCardPopup} isOpen />}
    </>
  );
};

export default OrderedProductItem;
