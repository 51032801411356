import { FilterSearchingParams } from '../redux/filters/filtersTypes';

const getSortDataForFilter = (
  searchQueryData: FilterSearchingParams,
  selectedSort: string | undefined,
  sortFiledName = 'products/productOptions/price',
): string => {
  if (searchQueryData.isEmpty) {
    return !selectedSort?.length ? '' : `${sortFiledName} ${selectedSort}`;
  }

  return !searchQueryData.sortDirection?.length ? '' : `${sortFiledName} ${searchQueryData.sortDirection}`;
};

export default getSortDataForFilter;
