import { CreatorReducer } from '../base/base';
import { IOrderActions, IOrderState, StatusPayment } from './orderTypes';
import { RootState } from '../reducer';
import { TypeDelivery } from '../../constants/constantsId';
import config from '../../config';
import { actionsUser } from '../user/userReducer';
import { SelectorCity } from '../city/cityReducer';

const init: IOrderState = {
  address: null,
  deliveryType: null,
  paymentType: null,
  step: 1,
  contact: null,
  sellPoint: null,
  isCallBack: false,
  date: null,
  time: '',
  anonymousMessage: null,
  addressId: -1,
  commentOrder: null,
  numberOrder: null,
  idDeliveryPrice: null,
  statusPayment: StatusPayment.defult,
  draftId: null,
  expiredTime: null,
  deliveryCity: null,
  deliveryPrice: null,
  deliveryPriceOrderId: null,
  couponId: null,
  couponInfo: null,
  addressDraft: null,
  freeBag: false,
};

const creator = new CreatorReducer<IOrderActions, IOrderState>('order');
creator.addAction('setData', (state, action) => {
  return { ...state, ...action.payload };
});
creator.addAction('addContact', (state, action) => {
  return { ...state, contact: action.payload };
});
creator.addAction('updateContact', (state, action) => {
  return { ...state, contact: action.payload };
});
creator.addAction('setDeliveryPrice', (state, action) => {
  return { ...state, deliveryPrice: action.payload };
});
creator.addAction('setDeliveryPriceId', (state, action) => {
  return { ...state, deliveryPriceOrderId: action.payload };
});
creator.addAction('setCouponId', (state, action) => {
  return { ...state, couponId: action.payload };
});
creator.addAction('setCoupon', (state, action) => {
  return { ...state, couponInfo: action.payload };
});
creator.addAction<number>('removeContact', (state, action) => {
  return { ...state, contact: null };
});
creator.addAction<number>('changeAddressDraft', (state, action) => {
  return { ...state, addressDraft: action.payload };
});
creator.addAction('checkLast', (state) => {
  if (state.contact === null || state.contact.id === null) {
    return { ...state, contact: null };
  }
  return state;
});
creator.addAction('setExpiredTime', (state, action) => {
  const d = new Date(action.payload);
  d.setDate(+config.expiredTime + d.getDate());

  return { ...state, expiredTime: d.getTime() };
});
creator.addAction('clear', (state) => {
  return {
    ...init,
    deliveryType: state.deliveryType,
    sellPoint: state.sellPoint,
  };
});
creator.addAction(actionsUser.setData, (state) => {
  return {
    ...state,
    contact: null,
  };
});
creator.addAction('setFreeBag', (state, action) => {
  return { ...state, freeBag: action.payload };
});

const actionsOrder = creator.createActions();
const selectorsOrder = {
  getOrder: (state: RootState) => state.order,
  getStep: (state: RootState) => state.order.step,
  getAddress: (state: RootState) => state.order.address,
  isDeliveryCourier: (state: RootState) => {
    if (state.order.deliveryType === null) return false;
    return state.order.deliveryType!.code === TypeDelivery.courier;
  },
  isDeliverySelf: (state: RootState) => {
    if (state.order.deliveryType === null) return false;
    return state.order.deliveryType!.code === TypeDelivery.self;
  },
  isDeliveryExpress: (state: RootState) => {
    if (state.order.deliveryType === null) return false;
    return state.order.deliveryType!.code === TypeDelivery.express;
  },
  getCodePayment: (state: RootState) => (state.order.paymentType ? state.order.paymentType.code : ''),
  getSellPoint: (state: RootState) => state.order.sellPoint,
  getIdDeliveryPrice: (state: RootState) => state.order.idDeliveryPrice,
  getDeliveryPrice: (state: RootState) => {
    if (state.order.deliveryType && state.order.deliveryType.code === TypeDelivery.self) {
      return 0;
    }
    if (state.order.idDeliveryPrice === null) {
      return 0;
    }
    return SelectorCity.getDeliveryPriceById(state.order.idDeliveryPrice)(state);
  },
  getDeliveryPriceByOrder: (state: RootState) => {
    return state.order.deliveryPrice;
  },
  getDeliveryPriceId: (state: RootState) => {
    return state.order.deliveryPriceOrderId;
  },
  isCallBack: (state: RootState) => state.order.isCallBack,
  getContact: (state: RootState) => state.order.contact,
  getDate: (state: RootState) => state.order.date,
  getTime: (state: RootState) => state.order.time,
  getAnonymousMessage: (state: RootState) => state.order.anonymousMessage,
  getIsFromPrevStep: (state: RootState) => state.order.fromPrevStep,
  getAddressId: (state: RootState) => state.order.addressId,
  getCommentOrder: (state: RootState) => state.order.commentOrder,
  getNumberOrder: (state: RootState) => state.order.numberOrder,
  getStatusPayment: (state: RootState) => state.order.statusPayment,
  getIsEditable: (state: RootState) => state.order.step !== 4,
  getDeliveryType: (state: RootState) => state.order.deliveryType,
  getPaymentType: (state: RootState) => {
    return state.order.paymentType;
  },
  getDraftId: (state: RootState) => {
    return state.order.draftId;
  },
  getExpiredTime: (state: RootState) => {
    return state.order.expiredTime;
  },
  getSelectDeliveryCity: (state: RootState) => state.order.deliveryCity,
  getCouponId: (state: RootState) => state.order.couponId,
  getCoupon: (state: RootState) => state.order.couponInfo,
  getAddressDraft: (state: RootState) => state.order.addressDraft,
  getFreeBag: (state: RootState) => state.order.freeBag,
};

export { actionsOrder, selectorsOrder };
export default creator.createReducer(init);
