import React from 'react';
import { useSelector } from 'react-redux';
import iconCheck1 from '../../../assets/images/icon-checkout1.png';
import iconCheck2 from '../../../assets/images/icon-checkout2.png';
import iconCheck3 from '../../../assets/images/icon-checkout3.png';
import iconCheck4 from '../../../assets/images/icon-checkout4.png';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { usePaths } from '../../Routes/RouterList';
import { buildLocale } from '../../../utils/buildLocale';
import HeaderLogo from '../../common/HeaderLogo';
import { paymentTypeId } from '../../../constants/paymentTypeId';
import { useNavigate } from 'react-router';

interface IProps {
  finalData: any;
}

const FinalStep = (props: IProps) => {
  const { finalData } = props;
  const paths = usePaths();
  const navigate = useNavigate();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  if (!finalData) {
    return null;
  }

  const addZero = (i) => {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  };

  const orderTime = `${new Date(finalData.minExecuteDate).getHours()}:${addZero(
    new Date(finalData.minExecuteDate).getMinutes(),
  )}  - ${new Date(finalData.maxExecuteDate).getHours()}:${addZero(new Date(finalData.maxExecuteDate).getMinutes())} `;

  const orderDate = `${new Date(finalData.minExecuteDate).getDate()}.${
    new Date(finalData.minExecuteDate).getMonth() + 1
  }.${new Date(finalData.minExecuteDate).getFullYear()}`;

  const paymentTypeInfo = () => {
    switch (finalData.paymentType?.id) {
      case paymentTypeId.card:
        return 'footerPaymentCard';
      case paymentTypeId.paypal:
        return 'footerPaymentPaypal';
      case paymentTypeId.invoice:
        return 'payPageInvoice';
      default:
        return '';
    }
  };

  return (
    <div className="pay-page pay-page_thankyou">
      <header className="pay-page_header">
        <HeaderLogo />
        <span className="pay-page_close" onClick={() => navigate(paths.home)}></span>
      </header>
      <div className="container">
        <section className="checkout-thankyou">
          <div className="checkout-thankyou_block">
            <span className="icon">
              <span className="icon-check"></span>
            </span>
            <p className="thankyou">
              <span className="font-bold">{buildLocale(currentTranslate, 'thank')}</span>
              {buildLocale(currentTranslate, 'youOrderNumber')}
              {finalData.id} {buildLocale(currentTranslate, 'orderAccept')}
            </p>
            <article className="checkout-thankyou_info">
              <p>
                {finalData.minExecuteDate && (
                  <span className="font-bold">
                    {buildLocale(currentTranslate, 'successfulPaymentAddressText2')} {orderDate}, {orderTime}{' '}
                    {buildLocale(currentTranslate, 'uhr')}
                  </span>
                )}
                {buildLocale(currentTranslate, 'successfulPaymentAddressText3')}{' '}
                <span className="font-bold">{finalData.orderAddress}</span>
              </p>
              <div className="order-info">
                <p>
                  {buildLocale(currentTranslate, 'successfulPaymentOrderInfo1')}{' '}
                  <span className="font-bold">{buildLocale(currentTranslate, paymentTypeInfo())}</span>
                </p>
                <p>
                  {buildLocale(currentTranslate, 'successfulPaymentOrderInfo2')}{' '}
                  <span className="font-bold">{finalData.email || ''}</span>{' '}
                  {buildLocale(currentTranslate, 'successfulPaymentOrderInfo3')}
                </p>
                <p>{buildLocale(currentTranslate, 'successfulPaymentOrderInfo4')}</p>
              </div>
            </article>
            <div className="checkout-thankyou_btn">
              <button className="btn" onClick={() => navigate(paths.home)}>
                {buildLocale(currentTranslate, 'btnContinueOrder')}
              </button>
              <button className="btn btn-border btn-orange-border" onClick={() => navigate(paths.home)}>
                {buildLocale(currentTranslate, 'payPageToMainBtn')}
              </button>
            </div>
          </div>

          <div className="checkout-info">
            <article className="checkout-info_one">
              <span className="icon">
                <img src={iconCheck1} alt="" />
              </span>
              <div className="text">
                <p className="font-medium">
                  {finalData.minExecuteDate && (
                    <>
                      {buildLocale(currentTranslate, 'payPageFinalDesc1')} {orderTime}{' '}
                    </>
                  )}
                  {buildLocale(currentTranslate, 'successfulPaymentAddressText3')} {finalData.orderAddress}
                </p>
                <p>{buildLocale(currentTranslate, 'successfulPaymentCheckoutInfoText1')}</p>
                <p>{buildLocale(currentTranslate, 'successfulPaymentCheckoutInfoText2')}</p>
                <p>{buildLocale(currentTranslate, 'successfulPaymentCheckoutInfoText3')}</p>
              </div>
            </article>
            <article className="checkout-info_one">
              <span className="icon">
                <img src={iconCheck2} alt="" />
              </span>
              <div className="text">
                <p className="font-medium">{buildLocale(currentTranslate, 'successfulPaymentOtherBlock1Text1')}</p>
                <p>{buildLocale(currentTranslate, 'successfulPaymentOtherBlock1Text2')}</p>
              </div>
            </article>
            <article className="checkout-info_one">
              <span className="icon">
                <img src={iconCheck3} alt="" />
              </span>
              <div className="text">
                <p className="font-medium">{buildLocale(currentTranslate, 'successfulPaymentOtherBlock2Text1')}</p>
                <p>{buildLocale(currentTranslate, 'successfulPaymentOtherBlock2Text2')}</p>
              </div>
            </article>
            <article className="checkout-info_one">
              <span className="icon">
                <img src={iconCheck4} alt="" />
              </span>
              <div className="text">
                <p className="font-medium">{buildLocale(currentTranslate, 'successfulPaymentOtherBlock3Text1')}</p>
                <p>{buildLocale(currentTranslate, 'successfulPaymentOtherBlock3Text2')}</p>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FinalStep;
