import React, { useEffect } from 'react';
import { PayPalButtons, SCRIPT_LOADING_STATE, DISPATCH_ACTION, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import service from '../../../services/service';
import { getLocaleStorageItem } from '../../../utils/localStorageHelper';
import { DATA_STEP, draftId } from '../../../constants/payData';
import HeaderLogo from '../../common/HeaderLogo';

interface IProps {
  price: number;
  createOrder: any;
  setPayStep: (value: string) => void;
  resetChecked: () => void;
}

const PaypalComponent = (props: IProps) => {
  const { price, createOrder, setPayStep, resetChecked } = props;
  const [{ isInitial }, dispatch] = usePayPalScriptReducer();
  const draft = getLocaleStorageItem(draftId);

  useEffect(() => {
    if (isInitial) {
      dispatch({ type: DISPATCH_ACTION.LOADING_STATUS, value: SCRIPT_LOADING_STATE.PENDING });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitial]);

  return (
    <div className="pay-page">
      <header className="pay-page_header">
        <HeaderLogo />
        <span
          className="pay-page_close"
          onClick={() => {
            resetChecked();
            setPayStep(DATA_STEP);
          }}></span>
      </header>
      <div className="container">
        <div className="pay-block">
          <div className="pay-block_one pay-block_card">
            <PayPalButtons
              className="paypal-card"
              createOrder={(data, actions) => {
                return service.paypalPayment(+price.toFixed(2), draft ? +draft : null).then((value) => {
                  return value.data.id;
                });
              }}
              fundingSource={'paypal'}
              onApprove={async (data, actions) => {
                await service.paypalCapture(data.orderID).then(() => createOrder());
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaypalComponent;
