import React from 'react';

import ContentLoader from 'react-content-loader';

const box = 40;
const OrderLoader = () => (
  <ContentLoader
    speed={2}
    style={{ width: '100%' }}
    backgroundColor="#f3f3f3"
    foregroundColor="#00BFF6"
    className="profile-order"
    foregroundOpacity={0.06}>
    <rect x="7" y="6" rx="3" ry="3" width="20%" height="20" />
    <rect x="7" y="41" rx="3" ry="3" width="20%" height="20" />
    <rect x="30%" y="6" rx="3" ry="3" width="20%" height="20" />
    <rect x="30%" y="41" rx="3" ry="3" width="20%" height="20" />

    <rect x="70%" y="4" rx="3" ry="3" width="30%" height="45" />

    <rect x="7" y="60%" rx="3" ry="3" width={box} height={box} />
    <rect x="57" y="60%" rx="3" ry="3" width={box} height={box} />
    <rect x="107" y="60%" rx="3" ry="3" width={box} height={box} />
    <rect x="157" y="60%" rx="3" ry="3" width={box} height={box} />
    <rect x="207" y="60%" rx="3" ry="3" width={box} height={box} />
    <rect x="257" y="60%" rx="3" ry="3" width={box} height={box} />
  </ContentLoader>
);

export default OrderLoader;
