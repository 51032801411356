import { CreatorReducer } from '../base/base';
import { RootState } from '../reducer';
import { IDeliveryActions, IDeliveryPriceSettings, IDeliveryState } from './deliveryTypes';

const init: IDeliveryState = {
  zipCode: '',
  isInCourierArea: null,
  deliveryPriceSettings: [],
};

const creator = new CreatorReducer<IDeliveryActions, IDeliveryState>('delivery');
creator.addAction('setZipCode', (state, action) => {
  return { ...state, zipCode: action.payload };
});
creator.addAction('setIsInCourierArea', (state, action) => {
  return { ...state, isInCourierArea: action.payload };
});
creator.addAction('setDeliveryPriceSettings', (state, action) => {
  return { ...state, deliveryPriceSettings: action.payload };
});

const actionsDelivery = creator.createActions();

const selectorsDelivery = {
  getZipCode: (state: RootState) => state.delivery.zipCode?.trim() ?? null,
  isInCourierArea: (state: RootState) => state.delivery.isInCourierArea,
  getDeliveryPriceSettings: (state: RootState) => state.delivery.deliveryPriceSettings,
  getPriceDiffForCheaperDelivery: (currentPrices: number) => (state: RootState) => {
    const deliveryPrices = selectorsDelivery.getDeliveryPriceSettings(state) || [];
    if (!deliveryPrices?.length) {
      return null;
    }
    const itemForCheaperDelivery = deliveryPrices
      .filter((item: IDeliveryPriceSettings) => item.amountFrom !== null)
      .sort((a: IDeliveryPriceSettings, b: IDeliveryPriceSettings) => Number(a.amountFrom) - Number(b.amountFrom))
      .find((item: IDeliveryPriceSettings) => Number(item.amountFrom) > currentPrices);

    return itemForCheaperDelivery ? Number(itemForCheaperDelivery.amountFrom) - currentPrices : null;
  },
};

export { actionsDelivery, selectorsDelivery };

export default creator.createReducer(init);
