import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CartList from '../components/CartListPage/CartList';
import { selectorsCart } from '../redux/cart/cartReducer';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { actionsOther, selectorsOther } from '../redux/other/otherReducer';
import { selectorsUser } from '../redux/user/userReducer';
import { usePaths } from '../components/Routes/RouterList';
import service from '../services/service';
import { buildLocale } from '../utils/buildLocale';
import { getLocaleStorageItem } from '../utils/localStorageHelper';
import { actionsFilters } from '../redux/filters/filtersReducer';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import { useNavigate } from 'react-router';

const CartListPage = () => {
  const navigate = useNavigate();
  const paths = usePaths();
  const dispatch = useDispatch<any>();
  const items: any = useSelector(selectorsCart.getCartDataProducts);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const minPrice = useSelector(selectorsOther.getMinDeliveryPrice);
  const notAvailableProducts = useSelector(selectorsCart.getNotAvailableProducts);
  const isAuth = useSelector(selectorsUser.isAuth);
  const sum = useSelector(selectorsCart.getProductsPrice);
  const zipCode = useSelector(selectorsDelivery.getZipCode);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    dispatch(actionsFilters.clearAllSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    service.getCart(zipCode).then(() => {
      dispatch(actionsOther.setIsOpenNonAuth(!isAuth && !getLocaleStorageItem('asGuest')));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    setIsDisabled(
      !items ||
        items?.length === 0 ||
        minPrice > (sum || 0) ||
        (notAvailableProducts && notAvailableProducts.length > 0),
    );
    if (items?.length === 0) {
      navigate(paths.home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, minPrice, notAvailableProducts, sum]);

  const toPayPage = () => {
    navigate(paths['pay-page']);
  };

  return (
    <section className="basket-page">
      {isDisabled && minPrice > (sum || 0) && (
        <div className="pay-error">
          {buildLocale(currentTranslate, 'toLowOrderSum')} {minPrice}€
        </div>
      )}
      {isDisabled && (!items || items?.length === 0) && (
        <div className="pay-error">{buildLocale(currentTranslate, 'payPageNoProductError')}</div>
      )}
      {isDisabled && notAvailableProducts.length > 0 && (
        <div className="pay-error">{buildLocale(currentTranslate, 'payPageNonAvailable')}</div>
      )}
      <div className="container">
        <h1>{buildLocale(currentTranslate, 'cartTitle')}</h1>
        <CartList />
        <button className="btn" onClick={() => toPayPage()} disabled={isDisabled}>
          {buildLocale(currentTranslate, 'btnOrderLong')}
        </button>
      </div>
    </section>
  );
};

export default CartListPage;
