export const isIdPresent = (obj: any, id: number) => {
  if (obj.id === id) {
    return true;
  }

  if (obj.children && obj.children.length) {
    for (let i = 0; i < obj.children.length; i++) {
      if (isIdPresent(obj.children[i], id)) {
        return true;
      }
    }
  }

  return false;
};
