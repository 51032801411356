import React, { useMemo } from 'react';
import config from '../../config';
import { Helmet } from 'react-helmet-async';
import { generateHreflang, getFullLocaleByLocale } from '../../assets/i18n/config';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorsOther } from '../../redux/other/otherReducer';
import { isBrowser } from '../../utils/isBrowser';
import { useHelmetMetaLinkQueryParamsForBrandsProduct } from '../../useHooks/useHelmetMetaLinkQueryParamsForBrandsProduct';

interface ICommonHalmetProps {
  locale?: string;
  title?: string;
  description?: string;
  keywords?: string;
  children?: any;
  ogImg?: string;
}

const CommonHelmet = React.memo(({ children, description, title, ogImg, keywords }: ICommonHalmetProps) => {
  const location = useLocation();
  const currentLocale = useSelector(selectorsOther.getLocale)!;
  const fullLocale = getFullLocaleByLocale(currentLocale);
  const pathname = location.pathname;
  const url = config.canLink + pathname;

  if (isBrowser) {
    return <Helmet title={title} />;
  }
  return (
    <Helmet
      title={title}
      htmlAttributes={{
        lang: currentLocale,
      }}>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      <meta property="og:locale" content={fullLocale} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {ogImg && <meta property="og:image" content={ogImg} />}
      {children}
    </Helmet>
  );
});

const HelmetOnce = () => {
  const location = useLocation();
  const helmetMetaLinkQueryParams = useHelmetMetaLinkQueryParamsForBrandsProduct();
  const currentLocale = useSelector(selectorsOther.getLocale)!;
  const hreFlangs = useMemo(() => {
    return generateHreflang(currentLocale, location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isBrowser) {
    return null;
  }
  return (
    <Helmet>
      {hreFlangs.map((item, index) => {
        return (
          <link rel="alternate" key={index} hrefLang={item.hreflang} href={item.url + helmetMetaLinkQueryParams} />
        );
      })}
    </Helmet>
  );
};

export { HelmetOnce };
export default CommonHelmet;
