import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { actionsOther } from '../../../redux/other/otherReducer';
import { usePaths } from '../../Routes/RouterList';
import { buildLocale } from '../../../utils/buildLocale';
import { getLocaleStorageItem, setLocaleStorageItem } from '../../../utils/localStorageHelper';
import { buildNumber, city, entrance, flatNumber, floor, street } from '../../../constants/payData';
import AddressesDropdown from './AddressesDropdown';
import { MiniItemInput } from '../../Forms/Components/ItemInput';
import { IRegister } from '../../../typings/IRegister';
import { actionsDelivery, selectorsDelivery } from '../../../redux/delivery/deliveryReducer';
import { useNavigate } from 'react-router';

interface IProps {
  errors: any;
  register: (name: string, validation: any) => IRegister;
  debCreateDraft: () => void;
  isSelectAddress: boolean;
  setIsSelectAddress: (isSelect: boolean) => void;
}

const AddressDataComponent: React.FC<IProps> = ({
  errors,
  register,
  debCreateDraft,
  setIsSelectAddress,
  isSelectAddress,
}) => {
  const paths = usePaths();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const zipCode = useSelector(selectorsDelivery.getZipCode);
  const [addressData, setAddressData] = useState<any>(null);
  // const cityRegister = register('city', validation.required);
  // const streetRegister = register('street', validation.required);
  // const buildNumberRegister = register('buildNumber', validation.required);
  useEffect(() => {
    setInitialAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialAddress = () => {
    setAddressData((prevData: any) => ({ ...prevData, zipCode }));
    setInitialHelper(city);
    setInitialHelper(street);
    setInitialHelper(buildNumber);
    setInitialHelper(flatNumber);
    setInitialHelper(entrance);
    setInitialHelper(floor);
  };

  const setInitialHelper = (key: string) => {
    const keyValue = getLocaleStorageItem(key);

    if (keyValue) {
      setAddressData((prevData: any) => ({ ...prevData, [key]: keyValue }));
    }
  };

  const changeData = (key: string, value: string) => {
    setLocaleStorageItem(key, value);
    setAddressData((prevData: any) => ({ ...prevData, [key]: value }));
  };

  const updateStorageAddress = (address: any) => {
    setLocaleStorageItem(city, address?.city || '');
    dispatch(actionsDelivery.setZipCode(address?.zipCode || ''));
    setLocaleStorageItem(street, address?.street || '');
    setLocaleStorageItem(buildNumber, address?.buildNumber || '');
    setLocaleStorageItem(flatNumber, address?.flatNumber || '');
    setLocaleStorageItem(entrance, address?.entrance || '');
    setLocaleStorageItem(floor, address?.floor || '');
    setIsSelectAddress(!isSelectAddress);
  };

  const changeZipCode = () => {
    navigate(paths.home);
    dispatch(actionsOther.setIsOpenZipCode(true));
  };

  return (
    <div>
      <div className="pay-block_title">
        <div className="title">
          <span className="number">2</span> {buildLocale(currentTranslate, 'payPageAddress')}
        </div>
        <AddressesDropdown setAddressData={setAddressData} updateStorageAddress={updateStorageAddress} />
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-item">
            <MiniItemInput
              errors={addressData?.city ? [] : { city: { message: 'Pflichtfeld' } }}
              label={buildLocale(currentTranslate, 'addressCity')}
              value={addressData?.city || ''}
              defaultValue={addressData?.city || ''}
              placeholder={buildLocale(currentTranslate, 'addressCity')}
              name={'city'}
              // ref={cityRegister.ref}
              onChange={(e) => {
                changeData(city, e.target.value);
                // cityRegister.onChange(e);
              }}
              onBlur={(e) => {
                debCreateDraft();
                // cityRegister.onBlur(e);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <MiniItemInput
              errors={[]}
              label={buildLocale(currentTranslate, 'zipPlaceholder')}
              value={addressData?.zipCode || ''}
              defaultValue={addressData?.zipCode || ''}
              placeholder={buildLocale(currentTranslate, 'zipPlaceholder')}
              name="zipCode"
              disabled
            />
            <span className="change-zip-link" onClick={changeZipCode}>
              {buildLocale(currentTranslate, 'payPageChangeZipCode')}
            </span>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <MiniItemInput
              errors={addressData?.street ? [] : { street: { message: 'Pflichtfeld' } }}
              label={buildLocale(currentTranslate, 'addressStreet')}
              value={addressData?.street || ''}
              defaultValue={addressData?.street || ''}
              placeholder={buildLocale(currentTranslate, 'addressStreet')}
              name={'street'}
              // ref={streetRegister.ref}
              onChange={(e) => {
                changeData(street, e.target.value);
                // streetRegister.onChange(e);
              }}
              onBlur={(e) => {
                debCreateDraft();
                // streetRegister.onBlur(e);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <MiniItemInput
              errors={addressData?.buildNumber ? [] : { buildNumber: { message: 'Pflichtfeld' } }}
              label={buildLocale(currentTranslate, 'addressBuild')}
              value={addressData?.buildNumber || ''}
              defaultValue={addressData?.buildNumber || ''}
              placeholder={buildLocale(currentTranslate, 'addressBuild')}
              name={'buildNumber'}
              // ref={buildNumberRegister.ref}
              onChange={(e) => {
                changeData(buildNumber, e.target.value);
                // buildNumberRegister.onChange(e);
              }}
              onBlur={(e) => {
                debCreateDraft();
                // buildNumberRegister.onBlur(e);
              }}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-item">
            <MiniItemInput
              errors={[]}
              label={buildLocale(currentTranslate, 'tiFlatPlaceholder')}
              name="flatNumber"
              value={addressData?.flatNumber || ''}
              defaultValue={addressData?.flatNumber || ''}
              placeholder={buildLocale(currentTranslate, 'tiFlatPlaceholder')}
              onChange={(e) => changeData(flatNumber, e.target.value)}
              onBlur={debCreateDraft}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-item">
            <MiniItemInput
              errors={[]}
              label={buildLocale(currentTranslate, 'tiEntrancePlaceholder')}
              name="entrance"
              defaultValue={addressData?.entrance || ''}
              value={addressData?.entrance || ''}
              placeholder={buildLocale(currentTranslate, 'tiEntrancePlaceholder')}
              onChange={(e) => changeData(entrance, e.target.value)}
              onBlur={debCreateDraft}
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="form-item">
            <MiniItemInput
              errors={[]}
              label={buildLocale(currentTranslate, 'tiFloorPlaceholder')}
              name="floor"
              value={addressData?.floor || ''}
              defaultValue={addressData?.floor || ''}
              placeholder={buildLocale(currentTranslate, 'tiFloorPlaceholder')}
              onChange={(e) => changeData(floor, e.target.value)}
              onBlur={debCreateDraft}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressDataComponent;
