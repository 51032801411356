import { Organization, WithContext } from 'schema-dts';
import { isMetroShop } from '../constants/shops';
import config from '../config';

const useGenerateSchemaOrgJson = (): WithContext<Organization> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Mybio GmbH',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'München, Germany',
      addressCountry: 'DE',
      postalCode: '80939',
      streetAddress: 'Maria Probst Strasse 31',
    },
    telephone: '089 6666 7633',
    logo: isMetroShop(config.shopId)
      ? 'https://files.mybio.de/logo/metro-mybio-schema-org.jpg'
      : 'https://files.mybio.de/logo/mybio-schema-org.jpg',
    description:
      'MyBio bietet nicht nur eine große Auswahl an Produkten, sondern auch eine schnelle und zuverlässige Lieferung. Innerhalb von nur 3 Stunden werden die Einkäufe direkt vor die Haustür der Kunden geliefert. So können Kunden den Wocheneinkauf bequem von zu Hause aus erledigen und haben mehr Zeit für die wirklich wichtigen Dinge im Leben. MyBio steht für schnellen und guten Service, der den Kunden das Leben einfacher macht und gleichzeitig die Umwelt schont.',
    url: 'https://mybio.de/',
    sameAs: [
      'https://www.facebook.com/mybiogmbh',
      'https://www.instagram.com/mybio.de/',
      'https://www.youtube.com/@mybiogmbh',
    ],
  };
};

export default useGenerateSchemaOrgJson;
