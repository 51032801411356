import React from 'react';
import { buildLocale } from '../../utils/buildLocale';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

interface IProps {
  location: any;
  translate: any;
  navigate: NavigateFunction;
  containerClassName?: string;
  buttonTitle?: string;
  categoryTitle?: string;
}

const GoBackBtn = (props: IProps) => {
  const { location, translate, navigate, containerClassName, buttonTitle, categoryTitle } = props;
  const actualPathname = location.pathname;
  const historyItem = `${actualPathname}${location.search}`;
  const historyData = location?.state?.from?.length
    ? location.state.from.filter((item, i) => location.state.from.indexOf(item) === i)
    : [];

  const actualHistoryData = historyData.filter((link: string) => link !== historyItem);
  if (!actualHistoryData?.length) {
    return '';
  }

  let historyFiltersData = location?.state?.filters || null;
  if (historyFiltersData && historyFiltersData.hasOwnProperty(actualPathname)) {
    const { [actualPathname]: _, ...actualHistoryFiltersData } = historyFiltersData;
    historyFiltersData = actualHistoryFiltersData;
  }

  const backLink = actualHistoryData.at(actualHistoryData.length - 1);
  const options = { state: { from: actualHistoryData, filters: historyFiltersData } };
  return (
    <div className={containerClassName ?? 'mobile-categories_top'}>
      <div className="mobile-categories_back" onClick={() => navigate(backLink, options)}>
        <span className="arrow"></span>
        <span className="text">
          {!!buttonTitle?.length ? buttonTitle : buildLocale(translate, 'categoriesBackBtn')}
        </span>
      </div>
      {!!categoryTitle?.length && <h1>{categoryTitle}</h1>}
    </div>
  );
};

export default GoBackBtn;
