import React, { ReactNode } from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import config from '../../config';

interface IProps {
  children: ReactNode;
}

const PayPalProvider = (props: IProps) => {
  const initialOptions = {
    clientId: config.paypalClientId as string,
    currency: 'EUR',
    intent: 'authorize',
  };

  return (
    <PayPalScriptProvider options={initialOptions} deferLoading={true}>
      {props.children}
    </PayPalScriptProvider>
  );
};

export default PayPalProvider;
