export enum productUnits {
  kg = 5,
  portion = 6,
  piece = 7,
  pack = 9,
  g = 11,
  l = 12,
  m = 13,
  ml = 14,
  pair = 15,
  m2 = 16,
  cm2 = 17,
  cm = 18,
}
