import React from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import decodeQuery from '../../utils/decodeQuery';

interface IProps {
  searchQuery: string;
  className?: string;
}

const NoSearchResult = ({ searchQuery, className }: IProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const decodedSearchQuery = decodeQuery(searchQuery);

  return (
    <div className={className}>
      <p className="zero-result">{`${buildLocale(currentTranslate, 'zeroResult')} "${decodedSearchQuery}"`}</p>
      <p>{buildLocale(currentTranslate, 'nothingFound')}</p>
    </div>
  );
};

export default NoSearchResult;
