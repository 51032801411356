import React from 'react';
import { useSelector } from 'react-redux';
import config from '../../config';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { getLinkByCode } from '../../utils/bannerPrictures';
import { buildLocale } from '../../utils/buildLocale';
import { useLessThen991 } from '../../utils/mediaQuery';
import BannerItem from '../common/BannerItem';
import BannersSlider from './BannersSlider';
import useApi from 'react-use-api';
import queries from '../../services/queries';
import { selectorsOther } from '../../redux/other/otherReducer';

interface IProps {
  video?: boolean;
}

const HomePageBanner = (props: IProps) => {
  const { video } = props;
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [pictures] = useApi(queries.getPictures());

  const banners = [
    {
      code: 'home.banner.main1',
      title1: 'mainHeadTitle1',
      title2: 'mainHeadDesc1',
      linkTitle: 'mainHeadLink1',
      containerClass: 'home-adv_big',
    },
    {
      code: 'home.banner.main2',
      title1: 'mainHeadTitle2',
      title2: 'mainHeadDesc2',
      linkTitle: 'mainHeadLink2',
      containerClass: 'home-adv_sm',
    },
    {
      code: 'home.banner.main3',
      title1: 'mainHeadTitle3',
      title2: 'mainHeadDesc3',
      linkTitle: 'mainHeadLink3',
      containerClass: 'home-adv_sm',
    },
  ];

  if (!Array.isArray(pictures)) {
    return null;
  }

  if (isLess991) {
    return <BannersSlider slider={pictures.find((p) => p.code === 'mobile.banner')?.slider || []} />;
  }

  return (
    <div className="container">
      <div className="row home-adv">
        <div className="col-lg-8">
          {banners.map((banner, index) => {
            if (index !== 0) {
              return null;
            }
            const useVideo = !!pictures.find((b) => b.code === 'home.banner.main1')?.useVideo;
            if (video && pictures.length > 0 && useVideo) {
              const videoPath = pictures.find((b) => b.code === 'home.banner.main1')?.video?.path;
              const fullPath = videoPath ? config.videoURL + videoPath : '/Videos/banner-video.mp4';
              return (
                <div key={index} className={`banner-mobile ${banners[0].containerClass}`}>
                  <figure className="banner-pic">
                    {pictures.length > 0 && (
                      <video style={{ width: '100%', height: '100%' }} autoPlay loop muted>
                        <source src={fullPath} type="video/mp4" />
                      </video>
                    )}
                  </figure>
                  <div className="banner-text">
                    {/* <h1>{buildLocale(currentTranslate, banners[0].title1)}</h1>
                    <h2>{buildLocale(currentTranslate, banners[0].title2)}</h2> */}
                    <a href={getLinkByCode(banners[0].code, pictures)} className="btn">
                      {buildLocale(currentTranslate, banners[0].linkTitle)}
                    </a>
                  </div>
                </div>
              );
            }
            return (
              <BannerItem
                code={banner.code}
                linkTitle={banner.linkTitle}
                // title1={banner.title1}
                // title2={banner.title2}
                key={banner.code}
                pictures={pictures}
                containerClass={banner.containerClass}
              />
            );
          })}
        </div>
        <aside className="col-lg-4">
          <div className="home-adv_column">
            {banners.map((banner, index) => {
              if (index === 0) {
                return null;
              }
              return (
                <BannerItem
                  code={banner.code}
                  linkTitle={banner.linkTitle}
                  // title1={banner.title1}
                  // title2={banner.title2}
                  key={banner.code}
                  pictures={pictures}
                  containerClass={banner.containerClass}
                />
              );
            })}
          </div>
        </aside>
      </div>
    </div>
  );
};
export default HomePageBanner;
