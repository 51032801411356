import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { usePaths } from '../../components/Routes/RouterList';
import OtherImage from '../../components/ImagesComponent/OtherImage';
import { useDispatch } from 'react-redux';
import { actionsOther } from '../../redux/other/otherReducer';
import useDidUpdateEffect from '../../useHooks/useDidUpdateEffect';
import { useRouterStaticContext } from '../../context/RouterStaticContext';
import picError from '../../assets/images/pic-error-blue.svg';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';

const NotFoundComponent = (props: any) => {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const staticContext = useRouterStaticContext();

  if (staticContext) {
    staticContext.statusCode = 404;
  }

  const paths = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  useDidUpdateEffect(() => {
    dispatch(
      actionsOther.setData({
        is404Error: false,
      }),
    );
  }, [location.pathname]);

  return (
    <section className="section-404">
      <figure className="section-404_pic">
        <OtherImage src={picError} title="" width="408" height="244" />
      </figure>
      <div className="section-404_text">
        <p>{buildLocale(currentTranslate, 'errorClient')}</p>
        {buildLocale(currentTranslate, 'commonReturnMain')}
      </div>
      <Link to={paths.main} className="btn btn-link btn-blue">
        {buildLocale(currentTranslate, 'commonToMain')}
      </Link>
    </section>
  );
};

export default NotFoundComponent;
