import React from 'react';

interface IProps {
  onClose: () => void;
}

const CloseTopBtn = (props: IProps) => {
  const { onClose } = props;
  return (
    <span
      className="top-close"
      onClick={(e) => {
        onClose();
        e.stopPropagation();
      }}></span>
  );
};

export default CloseTopBtn;
