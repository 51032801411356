import { FRACTION_DIGIT } from '../constants/constantsId';
import { ICartItem } from '../typings/ICartItem';

const parseCartItem = (products: ICartItem[]) => {
  return products.map((p) => {
    return {
      count: +p.count.toFixed(FRACTION_DIGIT),
      product: {
        id: p.product.id,
      },
      comment: p.comment,
      alternativeCount: p.alternativeCount ? p.alternativeCount.toString() : null,
      services: p.services,
    };
  });
};

export default parseCartItem;
