const sortFilters = (array: any) => {
  if (!array?.length) {
    return [];
  }
  return [...array].sort((a, b) => {
    return a.nameDe?.toLowerCase().localeCompare(b.nameDe?.toLowerCase());
  });
};

export default sortFilters;
