export const handleData = (data: any, state: any) => {
  const { prevData = [], response, dependencies, error } = state;
  if (!error) {
    const { data } = response;
    const { perPage } = dependencies;
    if (data.length === 0 || data.length < perPage) {
      // update hasMore
      state.hasMore = false;
    }
    return [...prevData, ...data];
  }
  return [...prevData];
};
