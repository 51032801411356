import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, Elements, PaymentElement } from '@stripe/react-stripe-js';
import config from '../../../config';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';

const stripe = loadStripe(config.stripeKey || '', {
  // If collect shipping
  betas: ['shipping_address_element_beta_1'],
  locale: (config.defaultLng as any) || 'de',
});

const appearance = {
  /* ... */
};

// Enable the skeleton loader UI for the optimal loading experience.
const loader = 'auto';

export const CheckoutPage = ({ clientSecret }) => (
  <Elements stripe={stripe} options={{ clientSecret, appearance, loader }}>
    <CheckoutForm />
  </Elements>
);

export const CheckoutForm = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const stripe = useStripe();
  const elements: any = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [btnDisabled, setBtnDisabled] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    if (!stripe) {
      return;
    }
    if (!config.paymentRedirect) {
      return;
    }

    setBtnDisabled(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: config.paymentRedirect,
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setBtnDisabled(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && (
        <div>
          <span style={{ color: 'red' }}>{errorMessage}</span>
          <br />
        </div>
      )}
      <button type="submit" disabled={btnDisabled} className="btn">
        {btnDisabled ? buildLocale(currentTranslate, 'loading') : buildLocale(currentTranslate, 'submitPaymentBtn')}
      </button>
    </form>
  );
};
