import { CreatorReducer } from '../base/base';
import { RootState } from '../reducer';
import { IOwnCategoriesActions, IOwnCategoriesState } from './ownCategoriesTypes';

const init: IOwnCategoriesState = {
  limit: 4,
};

const creator = new CreatorReducer<IOwnCategoriesActions, IOwnCategoriesState>('own_categories');
creator.addAction('setLimit', (state, action) => {
  return { ...state, limit: action.payload };
});
creator.addAction('resetLimit', (state, action) => {
  return { ...state, limit: 4 };
});

const actionsOwnCategories = creator.createActions();

const selectorOwnCategories = {
  getLimit: (state: RootState) => state.ownCategories.limit,
};

export { selectorOwnCategories, actionsOwnCategories };

export default creator.createReducerFetch(init);
