import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsUser, selectorsUser } from '../../redux/user/userReducer';
import ItemInput from './Components/ItemInput';
import { useForm } from 'react-hook-form';
import FieldAddContact from '../ProfilePage/Components/FieldAddContact';
import FieldAddAddress from '../ProfilePage/Components/FieldAddAddress';
import { getIsCustomPhone } from '../../utils/normalizePhone';
import { useAxios } from '../../useHooks/useAxios';
import service from '../../services/service';
import useDidUpdateEffect from '../../useHooks/useDidUpdateEffect';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import PhoneInput from 'react-phone-input-2';
import ShowError from './Components/ShowError';
import { isMetroShop } from '../../constants/shops';
import config from '../../config';
import validation from '../../utils/validation';
import FieldChangeEmail from '../ProfilePage/Components/FieldChangeEmail';
import FieldChangePassword from '../ProfilePage/Components/FieldChangePassword';

const FormSettings = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const dispatch = useDispatch<any>();
  const user = useSelector(selectorsUser.getUser)!;
  const addresses = useSelector(selectorsUser.getAddresses);
  const contacts = useSelector(selectorsUser.getContacts);
  const [phoneNum, setPhoneNum] = useState<string>('');
  const isMetro = isMetroShop(config.shopId);
  const {
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    register,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: user,
  });
  const phoneRegister = register('phone', validation.phone);
  const { data, isLoading, request } = useAxios(service.updateProfile);

  useEffect(() => {
    if (user) {
      setPhoneNum(user.phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    const data: any = getValues();

    data.isPhoneCustom = getIsCustomPhone(data.phone);
    data.phone = phoneNum;

    await request(data);
  };

  useDidUpdateEffect(() => {
    if (data) {
      dispatch(actionsUser.updateUser(data));
    }
  }, [data]);

  return (
    <form className="profile-form">
      <ItemInput
        errors={errors}
        label={buildLocale(currentTranslate, 'tINameLabel')}
        {...register('firstName', validation.required)}
      />
      <ItemInput errors={errors} label={buildLocale(currentTranslate, 'tILastNameLabel')} {...register('lastName')} />
      {isMetro && (
        <ItemInput errors={errors} label={buildLocale(currentTranslate, 'tICompanyLabel')} {...register('company')} />
      )}
      <div className="profile-info_row">
        <label className="profile-info_label">{buildLocale(currentTranslate, 'tIPhoneLabel')}</label>{' '}
        <div className="profile-form-item phone-number">
          <PhoneInput
            inputProps={{
              errors,
              name: phoneRegister.name,
              ref: phoneRegister.ref,
            }}
            value={phoneNum}
            onChange={(phone, _, e) => {
              setPhoneNum(phone);
              phoneRegister.onChange(e);
            }}
            onBlur={(e) => phoneRegister.onBlur(e)}
            enableSearch
            placeholder={buildLocale(currentTranslate, 'tIPhoneLabel')}
            country={'de'}
          />
          {errors && <ShowError errors={errors} name={'phone'} />}
        </div>
      </div>

      <div className="profile-info_row">
        <p className="profile-info_label">{buildLocale(currentTranslate, 'tIEmailLabel')}</p>
        <div className="profile-info_txt">
          {user.email && <p>{user.email}</p>}
          <FieldChangeEmail />
        </div>
      </div>
      <div className="profile-info_row">
        <p className="profile-info_label">{buildLocale(currentTranslate, 'tIPasswordLabel')}</p>
        <div className="profile-info_txt">
          <p>********</p>
          <FieldChangePassword />
        </div>
      </div>
      <div className="profile-info_row">
        <p className="profile-info_label">{buildLocale(currentTranslate, 'commonAddress')}</p>
        <div className="profile-info_txt">
          <div className="profile-info-addresses">
            {addresses.map((a) => (
              <FieldAddAddress key={a.id} defaultAddress={a} />
            ))}
          </div>
          <FieldAddAddress />
        </div>
      </div>
      <div className="profile-info_row">
        <p className="profile-info_label">{buildLocale(currentTranslate, 'profileSaveContacts')}</p>
        <div className="profile-info_txt">
          <div className="profile-info-addresses">
            {contacts.map((c) => (
              <FieldAddContact key={c.id} defaultContact={c} />
            ))}
          </div>
          <FieldAddContact />
        </div>
      </div>
      <div className="profile-info_row profile-info_agree">
        <p className="profile-info_label">&nbsp;</p>
        <div className="profile-info_txt">{buildLocale(currentTranslate, 'confirmPersonalInfo')}</div>
      </div>
      {Object.keys(errors).length > 0 && (
        <div className="profile-info_row ">
          <p className="profile-info_label">&nbsp;</p>
          <div className="profile-info_txt">
            <p className="pay-page_fill">{buildLocale(currentTranslate, 'payPageFieldError')}</p>
          </div>
        </div>
      )}

      <div className="profile-info_row profile-info_button">
        <p className="profile-info_label">&nbsp;</p>
        <button type="submit" className="btn" onClick={handleSubmit(onSubmit)} disabled={isLoading || !isValid}>
          {buildLocale(currentTranslate, 'btnSave')}
        </button>
      </div>
    </form>
  );
};

export default FormSettings;
