import { useLocation, useSearchParams } from 'react-router-dom';

export const useHelmetMetaLinkQueryParamsForBrandsProduct = (): string => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (searchParams.size !== 1 || !searchParams.has('brands')) {
    return '';
  }
  const brandsParam = searchParams.get('brands');
  if (brandsParam?.length && brandsParam.split(',')?.length > 1) {
    return '';
  }

  return location.search;
};
