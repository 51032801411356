import React from 'react';
import { Link } from 'react-router-dom';
import { usePaths } from '../Routes/RouterList';
import wishlistIcon from '../../assets/images/ic-heart_o.svg';
import wishlistIconNonActive from '../../assets/images/ic-heart.svg';
import { buildLocale } from '../../utils/buildLocale';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { actionsCart } from '../../redux/cart/cartReducer';
import { generateClassName } from '../../utils/generateClassName';
import Modal from 'react-modal';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import ProductImagesSlider from '../Products/ProductImagesSlider';
import BadgesList from '../common/BadgesList';
import PriceBlock from '../common/PriceBlock';
import { isContentNotEmpty } from '../../utils/isContentNotEmpty';
import RelatedProductsWidget from '../Products/RelatedProductsWidget';
import { carouselRelatedProductsPopup } from '../../constants/configCarousel';
import { IProduct } from '../../typings/IProduct';
import SaleDiscountWidget from '../Products/SaleDiscountWidget';

interface IProps {
  product: any;
  salePrice: any;
  imageProps: any;
  closeModal: () => void;
  resetSearchField: any;
  changeWishlist: () => void;
  isFavorite: boolean;
  addProductToCart: any;
  orderBtnTitle: string;
  tab: any;
  KeysTab: any;
  setTab: any;
  isOpen: boolean;
  productRecommendations: IProduct[];
  productVariants: IProduct[];
}

const ProductCardMobile = (props: IProps) => {
  const paths = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const {
    product,
    salePrice,
    closeModal,
    resetSearchField,
    changeWishlist,
    isFavorite,
    addProductToCart,
    orderBtnTitle,
    KeysTab,
    tab,
    setTab,
    isOpen,
    productRecommendations,
    productVariants,
  } = props;
  const currentProductOption = product?.productOptions?.length ? product.productOptions.at(0) : null;
  const productId = Number(product?.id);
  const dispatch = useDispatch<any>();

  const closeCart = () => {
    dispatch(actionsCart.toggleCart(false));
    blockBodyScroll(false);
  };

  return (
    <Modal
      id="product-card-popup-model"
      className={`product-modal`}
      shouldCloseOnOverlayClick
      overlayClassName="edit_overlay payment_overlay modal_assortmant"
      isOpen={isOpen}>
      <span
        className="top-close"
        onClick={() => {
          closeModal();
        }}></span>
      <div className="product-modal_scroll">
        <div
          className="row"
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}>
          <div className="col-md-6">
            <figure className={`product-pic${salePrice !== null ? ' item-sale' : ''}`}>
              {salePrice !== null && <SaleDiscountWidget productOption={currentProductOption} needCountdown={true} />}
              <BadgesList product={product} isBigBadge={true} />
              <ProductImagesSlider product={product} />
              <button
                onClick={() => changeWishlist()}
                className="btn btn-wish"
                aria-label="Zu den Favoriten hinzufügen">
                <img src={isFavorite ? wishlistIcon : wishlistIconNonActive} alt="" width="20" height="19" />
              </button>
              <div className={'product-pic-may-differ'}>
                <span>{buildLocale(currentTranslate, 'productImageMayDifferFromOriginal')}</span>
              </div>
            </figure>
          </div>
          <div className="col-md-6">
            <div className="product-card">
              <h1>
                <Link
                  to={paths.product(productId)}
                  onClick={() => {
                    closeModal();
                    closeCart();
                    resetSearchField && resetSearchField();
                  }}>
                  {product.title}
                </Link>
              </h1>
              <div
                className="product-short"
                dangerouslySetInnerHTML={{
                  __html: product.shortDescription,
                }}></div>
              <PriceBlock
                product={product}
                addProductToCart={addProductToCart}
                closeModal={closeModal}
                orderBtnTitle={orderBtnTitle}
              />
            </div>
          </div>
          <div className="col-12">
            <section className="product-reviews">
              <article className="product-reviews_article">
                <p className="subtitle">{buildLocale(currentTranslate, 'productCardTabDesc')}</p>
                <div className="">
                  {product.fullDescription ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: product.fullDescription,
                      }}></p>
                  ) : (
                    <p> - </p>
                  )}
                </div>
              </article>
              <ul className="nav nav-tabs">
                {isContentNotEmpty(product.garnish) && (
                  <li className="nav-item">
                    <button
                      className={generateClassName('nav-link', { active: tab === KeysTab.garnish })}
                      onClick={() => setTab(KeysTab.garnish)}>
                      {buildLocale(currentTranslate, 'productCardTabGarnish')}
                    </button>
                  </li>
                )}
                {isContentNotEmpty(product.energyValue) && (
                  <li className="nav-item">
                    <button
                      className={generateClassName('nav-link', { active: tab === KeysTab.energyValue })}
                      onClick={() => setTab(KeysTab.energyValue)}>
                      {buildLocale(currentTranslate, 'productCardTabEnergyValue')}
                    </button>
                  </li>
                )}
                {isContentNotEmpty(product.ingredients) && (
                  <li className="nav-item">
                    <button
                      className={generateClassName('nav-link', { active: tab === KeysTab.ingredients })}
                      onClick={() => setTab(KeysTab.ingredients)}>
                      {buildLocale(currentTranslate, 'productCardTabWarehouse')}
                    </button>
                  </li>
                )}
              </ul>
              <div className="tab-content">
                {tab === KeysTab.garnish && (
                  <div className={generateClassName('tab-pane', { active: tab === KeysTab.garnish })}>
                    {product.garnish ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: product.garnish,
                        }}></p>
                    ) : (
                      <p> - </p>
                    )}
                  </div>
                )}
                {tab === KeysTab.energyValue && (
                  <div className={generateClassName('tab-pane', { active: tab === KeysTab.energyValue })}>
                    {product.energyValue ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: product.energyValue,
                        }}></p>
                    ) : (
                      <p> - </p>
                    )}
                  </div>
                )}
                {tab === KeysTab.ingredients && (
                  <div className={generateClassName('tab-pane', { active: tab === KeysTab.ingredients })}>
                    {product.ingredients ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: product.ingredients,
                        }}></p>
                    ) : (
                      <p> - </p>
                    )}
                  </div>
                )}
              </div>
            </section>
          </div>
          <RelatedProductsWidget
            productId={productId}
            productRecommendations={productRecommendations}
            productVariants={productVariants}
            responsiveConfig={carouselRelatedProductsPopup}
            carouselItemClass={'carousel-item-popup-li'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProductCardMobile;
