import { OAuthProvider, signInWithPopup, Auth, UserCredential } from 'firebase/auth';

import { AuthorizationStrategy, FirebaseError } from '../types';

export class AppleAuthorization implements AuthorizationStrategy {
  private provider: OAuthProvider;

  constructor() {
    this.provider = new OAuthProvider('apple.com');

    this.provider.addScope('email');
    this.provider.addScope('name');
  }

  public getProviderID() {
    return this.provider.providerId;
  }

  public authenticate(auth: Auth): Promise<UserCredential> {
    return signInWithPopup(auth, this.provider);
  }

  public credentialFromResult(data: UserCredential): any {
    return OAuthProvider.credentialFromResult(data);
  }

  public credentialFromError(error: FirebaseError) {
    return OAuthProvider.credentialFromError(error);
  }
}
