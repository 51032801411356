import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OwnCategoriesComponent from '../components/common/CustomSlider';
import { selectorsCategory } from '../redux/category/categoryReducer';
import { selectorsLocale } from '../redux/locale/localeReducer';

import { buildLocale } from '../utils/buildLocale';
import service from '../services/service';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductLoader from '../components/Loaders/ProductLoader';
import MainInfoModal from '../components/common/MainInfoModal';
import { getLinkByCode, getPictureByCode } from '../utils/bannerPrictures';
import { getLocaleStorageItem } from '../utils/localStorageHelper';

const LandingPage1 = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const categories = useSelector(selectorsCategory.getVisibleRootCategories);
  const [pictures, setPictures] = useState<Array<any>>([]);
  const [limit, setLimit] = useState(4);
  const [isHasMore, setIsHasMore] = useState(true);
  const [shownCategories, setShownCategories] = useState<Array<any>>([]);
  const [isOpenMainModal, setIsOpenMainModal] = useState(false);

  useEffect(() => {
    const isOpenMain = getLocaleStorageItem('isOpenMainModal');
    if (!isOpenMain) {
      setIsOpenMainModal(true);
    }
  }, []);

  useEffect(() => {
    getPictures();
  }, []);

  useEffect(() => {
    categories && setShownCategories(categories.slice(0, limit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPictures = () => {
    service.getPictures().then((res) => {
      if (res) {
        setPictures(res.data);
      }
    });
  };

  const changeLimit = () => {
    const newLimit = limit + 1;
    setLimit(newLimit);
    setShownCategories(categories.slice(0, newLimit));
    if (shownCategories.length > 0 && shownCategories.length >= categories.length) {
      setIsHasMore(false);
    }
  };

  return (
    <section className="home-page home-page_landing">
      {isOpenMainModal && <MainInfoModal isOpen={isOpenMainModal} setIsOpenMainModal={setIsOpenMainModal} />}
      <section className="section-main">
        <div className="search-main">
          <figure className="search-main_pic">
            <img src={getPictureByCode('landing.banner', pictures)} alt="" />
          </figure>
          <div className="container">
            <div className="search-form_block">
              <h1 style={{ color: '#fff' }}>{buildLocale(currentTranslate, 'landingPageTitle')}</h1>
              <h2 style={{ color: '#fff' }}>{buildLocale(currentTranslate, 'landingPageDesc')}</h2>
              <a href={getLinkByCode('home.banner1', pictures)} className="btn">
                {buildLocale(currentTranslate, 'landingBtnDesc')}
              </a>
            </div>
          </div>
        </div>
      </section>
      {shownCategories.length > 0 && (
        <InfiniteScroll
          dataLength={shownCategories.length}
          style={{ overflowX: 'hidden' }}
          next={() => changeLimit()}
          hasMore={isHasMore}
          loader={<ProductLoader />}>
          {shownCategories.map((category: any) => {
            return (
              <div key={category.id}>
                <OwnCategoriesComponent
                  category={category}
                  limit={limit}
                  setLimit={changeLimit}
                  route={'own_categories'}
                />
              </div>
            );
          })}
        </InfiniteScroll>
      )}
    </section>
  );
};

export default LandingPage1;
