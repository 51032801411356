export interface IBrand {
  id: number;
  nameDe: string;
  nameEn: string;
  name: string;
  descriptionDe: string;
  descriptionEn: string;
  description: string;
}

export interface IAttributeValue {
  id: number;
  nameDe: string;
  nameEn: string;
  name: string;
}

export interface IAttribute {
  id: number;
  nameDe: string;
  nameEn: string;
  name: string;
  attributeValues?: Array<IAttributeValue>;
}

export type AttributesType = Array<any>;

export interface IAttributeMain {
  id: number;
  nameDe: string;
  nameEn: string;
  name: string;
  attributes: AttributesType;
}

export type AttributesArrayType = Array<IAttributeMain>;

export type IBrands = Array<IBrand>;

export type ISelectedFilter = IBrand | IAttribute;

export type ISelectedFilters = Array<ISelectedFilter>;

const CATEGORY = 'CATEGORY';

const OWNCATEGORY = 'OWN_CATEGORY';

const SEARCH = 'SEARCH';

const WISHLIST = 'WISHLIST';

const BRAND = 'BRANDS';

export const FILTER_TYPE = {
  CATEGORY,
  OWNCATEGORY,
  SEARCH,
  WISHLIST,
  BRAND,
};

const BRANDS = 'BRANDS';

const BADGES = 'BADGES';

const ALLERGICH = 'ALLERGICH';

const NUTRION = 'NUTRION';

const PRICE_RANGES = 'PRICE_RANGES';

export const FILTERS_TYPE = {
  BRANDS,
  BADGES,
  ALLERGICH,
  NUTRION,
  PRICE_RANGES,
};

export interface IMainFilters {
  id: number;
  title: string;
  type: string;
}

export interface IFiltersDTO {
  brands?: Array<number> | null;
  badges: Array<number> | null;
  selectedAllergich: ISelectedFilters;
  selectedNutrion: ISelectedFilters;
  hasPriceRanges: boolean;
  hasBadges: boolean;
  hasSalePrice: boolean;
  searchFilter: string | null;
}
