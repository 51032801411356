import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import HeaderComponent from './components/common/HeaderComponent';
import { createBrowserHistory, createMemoryHistory } from 'history';
import ProviderScrollToTop from './context/ScrollToTopContext';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import ProviderFormattingContext from './context/FormattingContext';
import { isBrowser } from './utils/isBrowser';
import { actionsCart, selectorsCart } from './redux/cart/cartReducer';
import ErrorBoundary from './views/common/ErrorHandler';
import { actionsOther, selectorsOther } from './redux/other/otherReducer';
import SystemErrorPage from './views/common/SystemErrorPage';
import service from './services/service';
import CommonHelmet, { HelmetOnce } from './components/common/CommonHelmet';
import { actionsOrder, selectorsOrder } from './redux/order/orderReducer';
import config from './config';
import config18n from './assets/i18n/config';
import NotFoundComponent from './views/common/NotFoundPage';
import ProviderRouterStaticContext from './context/RouterStaticContext';
import DL_PageView from './utils/DataLayer/DL_PageView';
import FooterComponent from './components/common/FooterComponent';
import useApi from 'react-use-api';
import queries from './services/queries';
import { useLessThen991 } from './utils/mediaQuery';
import FooterMobile from './components/common/FooterMobile';
import ZipCodeModal from './components/Modals/ZipCodeModal';
import { selectorsDelivery } from './redux/delivery/deliveryReducer';
import { SeoTexts } from './constants/seoTexts';
import RoutesComponent from './components/Routes/RoutesComponent';
import RouterList from './components/Routes/RouterList';
import { getLocaleStorageItem, removeFromLocaleStorage } from './utils/localStorageHelper';
import { BlockInfoOptions } from './redux/other/otherTypes';

let history: any = {};
history = !isBrowser ? createMemoryHistory() : createBrowserHistory();

Modal.setAppElement('#modal-root');

// init Google Tag Manager
if (config.enableGTM && isBrowser && config.GTM) {
  const GTMArgs = {
    gtmId: config.GTM,
  };
  TagManager.initialize(GTMArgs);
  history.listen(() => {
    DL_PageView();
  });
}

const App = memo((props: any) => {
  if (props.url) history.replace(props.url);

  const Content = () => {
    const [seo] = useApi(queries.getSeoTextByRoot());
    const dispatch = useDispatch();
    const sum = useSelector(selectorsCart.getGeneralSum);
    const isError = useSelector(selectorsOther.getIsSystemError);
    const is404Error = useSelector(selectorsOther.getIs404Error);
    const isZipCodeOpen = useSelector(selectorsOther.getOpenZipCode);

    const [show, setShow] = useState(false);
    const location = useLocation();
    const expiredTime = useSelector(selectorsOrder.getExpiredTime);
    const localeState = useSelector(selectorsOther.getLocale) || 'de';
    const locale = localeState === config18n.defaultLocale ? '' : localeState;
    const isDesktop = useSelector(selectorsOther.isDesktop);
    const isLess991 = useLessThen991(isDesktop);

    const isProductSearchPopupOpen = useSelector(selectorsOther.getProductSearchPopupOpenState);
    const { isOpenLogin } = useSelector(selectorsOther.getOpenLoginData);
    const isOpenCartModal = useSelector(selectorsCart.getIsOpenCart);

    const zipCode = useSelector(selectorsDelivery.getZipCode);
    const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);

    const blockInfoOptions: BlockInfoOptions = useSelector(selectorsOther.getBlockInfoOptions);

    const CSS_HEADER_DEFAULT_TOP_PADDING = 15.1; //padding in rem
    const PXS_IN_REM = 16; // 1rem = 16px
    const PXS_FIX_FOR_MULTI_BOX = 8;

    useEffect(() => {
      const timer = getLocaleStorageItem('timer');
      if (timer) {
        removeFromLocaleStorage('timer');
      }
    }, [location.pathname]);

    useEffect(() => {
      if (expiredTime) {
        service.getCurrentTime().then((res) => {
          if (res.getTime() > expiredTime) {
            dispatch(
              actionsOrder.setData({
                expiredTime: null,
                draftId: null,
              }),
            );
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!zipCode || isInCourierArea === null) {
        dispatch(actionsOther.setIsOpenZipCode(true));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCode, isInCourierArea]);

    if (isError) {
      return <SystemErrorPage />;
    }

    const closeProductSearchPopup = () => {
      if (isProductSearchPopupOpen) {
        dispatch(actionsOther.closeProductSearchPopup(false));
      }
    };

    const closeLoginPopup = () => {
      if (isOpenLogin) {
        dispatch(actionsOther.setOpenLoginData({ isOpenLogin: false, redirectLink: '' }));
      }
    };

    const closeCartModal = () => {
      if (isOpenCartModal) {
        dispatch(actionsCart.toggleCart(false));
      }
    };

    const getActualCssFixForMainBlock = () => {
      if (!blockInfoOptions?.displayedmMessagesTotal) {
        return {};
      }
      const fixedInfoHeight =
        blockInfoOptions?.displayedmMessagesTotal > 1
          ? blockInfoOptions.headerHightPx - PXS_FIX_FOR_MULTI_BOX
          : blockInfoOptions.headerHightPx;
      const actualInfoHeight = blockInfoOptions?.displayedmMessagesTotal * fixedInfoHeight;
      const paddingValue = actualInfoHeight / PXS_IN_REM + CSS_HEADER_DEFAULT_TOP_PADDING;
      return { paddingTop: paddingValue.toFixed(1) + 'rem' };
    };

    return (
      <div
        className="App"
        onClick={() => {
          // close modal when outside of modal is clicked
          closeProductSearchPopup();
          closeLoginPopup();
          closeCartModal();
        }}>
        <HelmetOnce />
        <CommonHelmet
          locale={locale}
          title={seo?.title ?? SeoTexts.title}
          description={seo?.description ?? SeoTexts.description}
        />
        {!location.pathname.includes('pay-page') && <HeaderComponent sum={sum} />}
        <main
          style={getActualCssFixForMainBlock()}
          className={location.pathname.includes('pay-page') ? 'content pay-page' : 'content'}>
          {show && (
            <div className={'betatest'}>
              {/* <p>{t('testVersionApp')}</p> */}
              <button className="btn-delete" onClick={() => setShow(false)} />
            </div>
          )}
          {is404Error ? (
            <NotFoundComponent />
          ) : (
            <ErrorBoundary>
              <RoutesComponent options={RouterList()} />
            </ErrorBoundary>
          )}
        </main>
        {isLess991 ? <FooterMobile /> : <FooterComponent />}
        {isZipCodeOpen && (
          <ZipCodeModal isOpen={isZipCodeOpen} onClose={() => dispatch(actionsOther.setIsOpenZipCode(false))} />
        )}
      </div>
    );
  };

  return (
    <ProviderScrollToTop>
      <ProviderFormattingContext>
        <ProviderRouterStaticContext context={props.context}>
          <Content />
        </ProviderRouterStaticContext>
      </ProviderFormattingContext>
    </ProviderScrollToTop>
  );
});

export default App;
