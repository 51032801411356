import React from 'react';
import { useSelector } from 'react-redux';
import { useFormattingContext } from '../../context/FormattingContext';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { ProductPriceRangesType } from '../../typings/IProduct';
import { buildLocale } from '../../utils/buildLocale';

interface IProps {
  productPriceRanges: ProductPriceRangesType;
  price: string | number;
}

interface IItemProps {
  quantity: number | string;
  getPercent: (num: number) => number;
  price: number;
  index: number;
}

const RangeItem = (props: IItemProps) => {
  const { formatPrice } = useFormattingContext();
  const { quantity, price, getPercent, index } = props;
  return (
    <tr>
      <td>{quantity}</td>
      {index !== 0 && (
        <td className="percent">
          <span className="percent-value">
            {getPercent(+price)}
            <span>%</span>
          </span>
        </td>
      )}
      <td colSpan={2}>{formatPrice(price, true)}</td>
    </tr>
  );
};
const PriceRangeTable = (props: IProps) => {
  const { productPriceRanges, price } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const setViewRange = (rangesArray: ProductPriceRangesType) => {
    const sortedArray = rangesArray.sort((a: any, b: any) => a.quantity - b.quantity);
    return sortedArray.map((item, index: number) => {
      if (sortedArray.length === index + 1) {
        return {
          quantity: `${item.quantity}+`,
          price: item.price,
        };
      }
      const nextQuantity = sortedArray.find((item, i) => i - index === 1)?.quantity as number;
      if (nextQuantity - 1 === item.quantity) {
        return item;
      }
      return {
        quantity: `${item.quantity}-${nextQuantity - 1}`,
        price: item.price,
      };
    });
  };

  const getFirstRange = (rangesArray: ProductPriceRangesType) => {
    const sortedArray = rangesArray.sort((a: any, b: any) => a.quantity - b.quantity);
    const nextQuantity = sortedArray[0].quantity;
    if (nextQuantity - 1 === 1) {
      return 1;
    }
    return `1-${nextQuantity - 1}`;
  };

  const getPercent = (rangePrice: number) => {
    if (price && rangePrice) {
      return Math.round(100 - (+rangePrice / +price) * 100);
    }
    return 0;
  };

  if (!productPriceRanges || productPriceRanges.length === 0) {
    return null;
  }

  return (
    <table className="product-price_table">
      <thead>
        <tr>
          <th>{buildLocale(currentTranslate, 'productPriceRangesQuantity')}</th>
          <th colSpan={2}>{buildLocale(currentTranslate, 'productPriceRangesUnit')}</th>
        </tr>
      </thead>
      <tbody>
        <RangeItem getPercent={getPercent} index={0} price={+price} quantity={getFirstRange(productPriceRanges)} />
        {setViewRange(productPriceRanges).map((p, index: number) => (
          <RangeItem key={index} getPercent={getPercent} index={index + 1} price={+p.price} quantity={p.quantity} />
        ))}
      </tbody>
    </table>
  );
};

export default PriceRangeTable;
