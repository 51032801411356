import React from 'react';
import CategoryImage from '../ImagesComponent/CategoryImage';
import { useNavigate } from 'react-router';

interface IProps {
  items: any;
}

const TabsList = (props: IProps) => {
  const { items } = props;
  const navigate = useNavigate();

  const Item = (props: any) => {
    const { item } = props;
    return (
      <div className="category-tabs_col" key={item.id}>
        <div
          className="category-tabs_one category-tab-item"
          onClick={() => {
            navigate(`/products/${item.id}`);
          }}>
          <a href={`/products/${item.id}`} onClick={(e) => e.preventDefault()}>
            {item.name}
          </a>
          <figure className="category-tabs_pic">
            <a href={`/products/${item.id}`} onClick={(e) => e.preventDefault()}>
              <CategoryImage category={item} />
            </a>
          </figure>
        </div>
      </div>
    );
  };

  return (
    <div className="category-tabs">
      <div className="row">{items?.length > 0 && items.map((item: any) => <Item item={item} key={item.id} />)}</div>
    </div>
  );
};

export default React.memo(TabsList);
