import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { buildLocale } from '../../utils/buildLocale';
import { IBaseCategory } from '../../typings/ICategory';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { actionsFilters } from '../../redux/filters/filtersReducer';
import { selectorsCategory } from '../../redux/category/categoryReducer';
import { isIdPresent } from '../../utils/isIdPresent';
import { useNavigate } from 'react-router';

interface IProps {
  children: IBaseCategory[];
  closePopup: () => void;
  category: any;
  handleMouseLeave: () => void;
}

const CategoryChildren = (props: IProps) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { children, closePopup, category, handleMouseLeave } = props;
  const [isMore, setIsMore] = useState(false);
  const params: any = useParams();
  const location = useLocation();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const locationId = location.pathname.substring(location.pathname.includes('own_categories') ? 16 : 10);
  const id = params.id || params['category'] || locationId;
  const chainsCategory = useSelector(selectorsCategory.getChainsCategories(category.id));

  return (
    <div>
      <ul className="category-popup_subcategory">
        {children.map((child, i) => (
          <React.Fragment key={i}>
            {i < 2 && (
              <li>
                <a
                  href={`/products/${child.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isIdPresent(chainsCategory[0], +id)) {
                      dispatch(actionsFilters.clearAllSelected());
                    }
                    handleMouseLeave();
                    closePopup();
                    navigate(`/products/${child.id}`);
                  }}>
                  {child.name}
                </a>
              </li>
            )}
          </React.Fragment>
        ))}
        {isMore &&
          children.map((child, i) => (
            <React.Fragment key={child.id}>
              {i > 1 && (
                <li>
                  <a
                    href={`/products/${child.id}`}
                    key={i}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isIdPresent(chainsCategory[0], +id)) {
                        dispatch(actionsFilters.clearAllSelected());
                      }
                      handleMouseLeave();
                      closePopup();
                      navigate(`/products/${child.id}`);
                    }}>
                    {child.name}
                  </a>
                </li>
              )}
            </React.Fragment>
          ))}
      </ul>
      <button
        className="btn-more"
        style={{ display: `${children!.length > 2 ? 'block' : 'none'}` }}
        onClick={() => setIsMore(!isMore)}>
        {buildLocale(currentTranslate, !isMore ? 'childrenMore' : 'childrenLess')}
      </button>
    </div>
  );
};

export default CategoryChildren;
