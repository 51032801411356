import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const useT = () => {
  const { t } = useTranslation();

  return useCallback((key: string, data?: any) => {
    return t(key, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
