import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { IRoute } from './RouterList';

interface IRoutes {
  options: IRoute[];
}
const RoutesComponent = (props: IRoutes) => {
  let routeKeyIndex = 0;
  const parseRouteItem = (items: IRoute[]) => {
    let result: any[] = [];
    items.forEach((route: IRoute) => {
      if (route?.routes?.length) result = [...result, ...parseRouteItem(route.routes)];

      if (route?.element) {
        result.push(<Route key={routeKeyIndex++} path={route.path} element={route.element} />);
      }
    });

    return result;
  };

  return <Routes>{parseRouteItem(props.options)}</Routes>;
};

export default RoutesComponent;
