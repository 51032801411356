import React from 'react';
import { IBrand } from '../../../../../typings/IFilters';

interface IProps {
  brand: IBrand;
  isChecked: (id: number) => boolean;
  removeBrand: (id: IBrand) => void;
  addBrand: (id: IBrand) => void;
  isLoading: boolean;
}

const CustomSelectOption = (props: IProps) => {
  const { brand, isChecked, removeBrand, addBrand, isLoading } = props;
  return (
    <div
      className="mobile-filter_checkbox"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        isChecked(brand.id) ? removeBrand(brand) : addBrand(brand);
      }}>
      <input
        type="checkbox"
        id={`select-filter${brand.id}`}
        checked={isChecked(brand.id)}
        onChange={() => {}}
        disabled={isLoading}
      />
      <label htmlFor={`select-filter${brand.id}`} className="select-filter-label">
        {brand.nameDe}
      </label>
    </div>
  );
};

export default CustomSelectOption;
