import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsOther } from '../../redux/other/otherReducer';
import { SystemMessageOptions } from '../../redux/other/otherTypes';
import { generateClassName } from '../../utils/generateClassName';

enum MessageTypeClass {
  error = 'error',
  success = 'success',
  none = 'none',
}

const defaultMessageOptions = { type: MessageTypeClass.none, isOpen: false, message: '' };

const SystemMessage = React.memo(() => {
  const toggleSystemMessage: SystemMessageOptions = useSelector(selectorsOther.toggleSystemMessage);
  const [options, setOptions] = useState<SystemMessageOptions>(defaultMessageOptions);
  const [timeoutId, setTimeoutId] = useState<any>();

  useEffect(() => {
    const { type, message, isOpen, duration = 3000 } = toggleSystemMessage;
    if (isOpen) {
      // set external new values
      setOptions({ type, message, isOpen, duration });
    }
  }, [toggleSystemMessage]);

  useEffect(() => {
    if (options.isOpen === true) {
      const tId = setTimeout(() => {
        // reset default values and close the message bar
        setOptions(defaultMessageOptions);
      }, options.duration);

      setTimeoutId(tId);
    }

    if (options.isOpen === false && typeof timeoutId === 'number') {
      clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.isOpen]);

  const closeSystemMessage = () => {
    setOptions(defaultMessageOptions);
  };

  return (
    <div
      className={generateClassName('system', {
        [MessageTypeClass[options.type]]: Object.values(MessageTypeClass).includes(options.type as MessageTypeClass),
      })}>
      {options.message}
      <span className="system-close" onClick={() => closeSystemMessage()}>
        ✕
      </span>
    </div>
  );
});

export default SystemMessage;
