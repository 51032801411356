import React, { useRef, useState } from 'react';
import { IOrderFull } from '../../../typings/IOrderItem';
import { IProduct } from '../../../typings/IProduct';
import { formatTime, useFormattingContext } from '../../../context/FormattingContext';
import service from '../../../services/service';
import OrderPartItem from './OrderPartItem';
import ClipLoader from 'react-spinners/ClipLoader';
import useDidUpdateEffect from '../../../useHooks/useDidUpdateEffect';
import { useDispatch, useSelector } from 'react-redux';
import { useLessThen640, useLessThen991 } from '../../../utils/mediaQuery';
import { generateClassName } from '../../../utils/generateClassName';
import useComponentSize from '@rehooks/component-size';
import InfoModal from '../../Modals/InfoModal';
import OtherImage from '../../ImagesComponent/OtherImage';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import ICDNImage from '../../../typings/ICDNImage';
import { productDefaultImage } from '../../../constants/productDefaultImage';
import { setLoaderColor } from '../../../utils/setLoaderColor';
import { actionsCart, selectorsCart } from '../../../redux/cart/cartReducer';
import { ICartItem } from '../../../typings/ICartItem';
import { usePaths } from '../../Routes/RouterList';
import { checkAllProductsAvailability, isAllProductsNonAvailable } from '../../../utils/checkAllProductsAvailability';
import { selectorsDelivery } from '../../../redux/delivery/deliveryReducer';
import { useNavigate } from 'react-router';
import { selectorsOther } from '../../../redux/other/otherReducer';

interface IOrderItemProps {
  order: IOrderFull;
}

const OrderItem = ({ order }: IOrderItemProps) => {
  const paths = usePaths();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess640 = useLessThen640();
  const isLess991 = useLessThen991(isDesktop);
  const { formatPrice } = useFormattingContext();
  const { longFormatDate } = useFormattingContext();
  const isOpenCart = useSelector(selectorsCart.getIsOpenCart);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const refCon = useRef(null);
  let size = useComponentSize(refCon);
  const [data, setData] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [countMore, setCountMore] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const areAllProductsAvailable = checkAllProductsAvailability(order);
  const areAllProductsNonAvailable = isAllProductsNonAvailable(order);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const totalPrice = order.totalPrice ? +order?.totalPrice : 0;
  const totalWithSecurityDeposit = order.totalWithSecurityDeposit ? +order.totalWithSecurityDeposit : 0;
  const shownPrice =
    ['newWeb', 'new', 'confirm', 'inprogress', 'ready', 'reject'].includes(order?.orderStatus?.code) &&
    totalWithSecurityDeposit
      ? totalWithSecurityDeposit
      : totalPrice;

  const isAllProductsBoxDeliveryAllowed =
    isInCourierArea === false && order?.purchases.every(({ product }) => !product?.isBoxDeliveryAllowed);

  const isSomeProductNotBoxDeliveryAllowed =
    isInCourierArea === false && order?.purchases.find(({ product }) => !product?.isBoxDeliveryAllowed);

  useDidUpdateEffect(() => {
    let { width } = size;
    const res = Math.floor(width / 72);
    if (res < images.length) setCountMore(res);
    else {
      setCountMore(0);
    }
  }, [size]);

  const getDateOrder = () => {
    let str = longFormatDate(new Date(order.minExecuteDate));
    if (order.orderAddress) {
      str += ` | ${formatTime.format(new Date(order.minExecuteDate))} -  ${formatTime.format(
        new Date(order.maxExecuteDate),
      )}`;
    } else {
      str += ` | ${formatTime.format(new Date(order.minExecuteDate))}`;
    }
    return str;
  };

  const getProductImage = (product: IProduct): ICDNImage => {
    if (product.cdnImages && product.cdnImages.length > 0 && product.cdnImages[0]) {
      return product.cdnImages.sort((a: any, b: any) => a.id - b.id)[0];
    }

    return productDefaultImage;
  };

  const images = order.purchases.map((purchase) => {
    return getProductImage(purchase.product);
  });

  const handleClick = async () => {
    setIsLoading(true);
    service
      .getOrder(order.id)
      .then((res) => {
        setData(res.data);
        setIsOpen(true);
      })
      .catch((e) => {
        console.log('ERROR');
      })
      .finally(() => setIsLoading(false));
  };

  const repeatOrder = () => {
    service
      .getOrder(order.id)
      .then((res) => {
        service
          .getProducts(
            res.data!.purchases.map((p) => p.product.id),
            isInCourierArea,
          )
          .then((productsRes) => {
            dispatch(actionsCart.clear());
            const result: ICartItem[] = res.data.purchases
              .map((pur) => {
                const cartItem: ICartItem = {
                  count: +pur.count,
                  comment: pur.comment,
                  product: productsRes.data.find((p) => p.id === pur.product.id)!,
                  alternativeCount: pur.alternativeCount,
                  services: [],
                };

                return cartItem;
              })
              .filter((item) => item.product && item?.product?.productOptions[0]?.availableAmount);

            dispatch(actionsCart.setData(result));
            if (isLess991) {
              navigate(paths.home);
              if (result.length > 0) dispatch(actionsCart.toggleCart(!isOpenCart));
              return;
            }
            navigate(paths['cart-list']);
          })
          .catch((e) => {
            console.log('ERROR');
          });
      })
      .catch((e) => {
        console.log('ERROR');
      });
  };

  const downloadInvoice = () => {
    if (!order.id) {
      return;
    }

    service.downloadInvoice(order.id).then((response) => {
      const blob: any = new Blob([response.data]);
      const a = document.createElement('a');
      a.download = `order_${order.id}.pdf`;
      a.href = URL.createObjectURL(blob);
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
    });
  };
  const filterImages = images.filter((_, index) => countMore === 0 || index < countMore * (isLess640 ? 2 : 1));

  return (
    <article
      className={generateClassName('profile-order', {
        open: isOpen,
      })}>
      <InfoModal
        title={'Unmöglich'}
        onSubmit={() => setIsOpenModal(false)}
        submitTitle={'Zurückkehren'}
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}>
        <p>{buildLocale(currentTranslate, 'orderAllComponentsMissing')}</p>
      </InfoModal>
      <div className="row profile-order_top">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="profile-order_number">
            <p className={`number number-${order.orderStatus.code}`}>№ {order.id + ''}</p>
            <span className="status-price">{formatPrice(shownPrice)}</span>
          </div>
          <span className="profile-order_number-caption">{buildLocale(currentTranslate, 'cartSum')}</span>
        </div>
        <div className="col-12 col-md-6 col-lg-8 profile-order_top-right">
          <span className="profile-order_date">{getDateOrder()}</span>
          <div className="profile-order_status">
            <span className="caption caption-status">{buildLocale(currentTranslate, 'commonStatus')}:</span>
            <span className={`status status-${order.orderStatus.code}`}>{order.orderStatus.status}</span>
          </div>
          <div className="profile-order_buttons">
            <button
              onClick={repeatOrder}
              className="btn"
              disabled={areAllProductsNonAvailable || isAllProductsBoxDeliveryAllowed}>
              {buildLocale(currentTranslate, 'btnRepeatOrder')}
            </button>
            {order.orderInvoice && (
              <button onClick={downloadInvoice} className="btn">
                {buildLocale(currentTranslate, 'profileDownloadInvoice')}
              </button>
            )}
          </div>
          {!areAllProductsAvailable && (
            <p className="profile-order-not-available">
              {buildLocale(currentTranslate, 'profileNotAllProductsAvailableInfo')}
            </p>
          )}
          {isSomeProductNotBoxDeliveryAllowed && (
            <p className="profile-order-not-available">
              {buildLocale(currentTranslate, 'profileNotAllProductsAvailableForBoxDeliveryInfo')}
            </p>
          )}
        </div>
      </div>
      {!isOpen && (
        <div
          className={generateClassName('profile-order_description', {
            'profile-order_description-more': isLess640,
          })}
          onClick={handleClick}>
          <ClipLoader size={30} color={setLoaderColor()} loading={isLoading} />
          <div className="inner" ref={refCon}>
            <div className="profile-order_list">
              {filterImages.map((img, index) => {
                return (
                  <figure key={index} className="profile-order_product">
                    {img && <OtherImage src={img.variants['400x300']} title="" width="40" height="36" />}
                  </figure>
                );
              })}
              {countMore !== 0 && isLess640 && <span className="more more-in">+{images.length - countMore}</span>}
            </div>
          </div>
          {countMore !== 0 && !isLess640 && <span className="more">+{images.length - countMore}</span>}
        </div>
      )}
      {isOpen && data && <OrderPartItem data={data!} onClose={() => setIsOpen(false)} />}
      <button onClick={repeatOrder} className="btn btn-repeat">
        {buildLocale(currentTranslate, 'btnRepeatOrder')}
      </button>
      {order.orderInvoice && (
        <button onClick={downloadInvoice} className="btn btn-repeat" style={{ marginTop: '1rem' }}>
          {buildLocale(currentTranslate, 'profileDownloadInvoice')}
        </button>
      )}
    </article>
  );
};

export default OrderItem;
