import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';
import CartCountItem from './CartCountItem';
import { useFormattingContext } from '../../context/FormattingContext';
import { ICartItem } from '../../typings/ICartItem';
import { useSpring, animated } from 'react-spring';
import { selectorsOrder } from '../../redux/order/orderReducer';
import { getIndexProductOption } from '../../utils/getIndexProductOption';
import ThumbProductImage from '../ImagesComponent/ThumbProductImage';
import ProductCard from '../Modals/ProductCard';
import { buildLocale } from '../../utils/buildLocale';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import { DL_helper } from '../../utils/DataLayer/DL_helper';
import { calculateWeightablePriceWithCount } from '../../utils/calculateUnits';
import getDiscountPercentage from '../../utils/getDiscountPercentage';

interface ICartItemProps {
  item: ICartItem;
  defaultSellPoint?: number | null;
  notAvailableType?: false | 'count' | 'noProduct';
  getTitleBlockSize: any;
}

const CartItem = React.memo(
  ({ item, defaultSellPoint = null, notAvailableType, getTitleBlockSize }: ICartItemProps) => {
    const [props, set] = useSpring(() => ({
      opacity: 1,
      config: {
        duration: 300,
      },
    }));
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<any>({});
    const [isFavorite, setIsFavorite] = useState(false);
    const dispatch = useDispatch<any>();
    const step = useSelector(selectorsOrder.getStep);
    const idSellPoint = useSelector(selectorsCart.getIdSellPoint);
    const currentTranslate = useSelector(selectorsLocale.getTranslate);
    const { formatPrice } = useFormattingContext();
    const { product } = item;
    const [productTitle, setProductTitle] = useState('');
    const cart = useSelector(selectorsCart.getCartProducts);
    const handleClickTitle = () => {
      openProductCard();
    };

    useEffect(() => {
      if (!isOpen) {
        blockBodyScroll(false);
      }
    }, [isOpen]);

    useEffect(() => {
      if (product) {
        setProductTitle(getTitleBlockSize(product.title));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    const removeItem = () => {
      set({
        opacity: 0,
        onRest: () => {
          dispatch(actionsCart.removeProduct(+product.id));
        },
      });
      DL_helper(cart, count, 'removeFromCart', product);
    };

    const count = item.alternativeCount ? item.alternativeCount : +item.count;
    const isWeightable = item?.product?.isWeightable;
    const index = getIndexProductOption(item.product, defaultSellPoint || idSellPoint);
    const price = +item.product.productOptions[index].price;
    const dataPrice = item.price ? +item.price : price;
    const salePrice = product.productOptions.length > 0 ? product.productOptions[index].salePrice : null;
    const quantityPrice = item.alternativeCount ? price * count * +item.product.avgWeight! : price * +count;
    const unitType = item?.product?.unit?.type;
    const quantityPerPack = item?.product?.quantityPerPack;
    const weightablePrice = calculateWeightablePriceWithCount(price, unitType, count.toString(), quantityPerPack);
    const totalWeightDeposit = item?.totalWeightDeposit ? +item.totalWeightDeposit : 0;
    const totalBottleDeposit = item?.totalBottleDeposit ? +item.totalBottleDeposit : 0;
    const totalRackDeposit = item?.totalRackDeposit ? +item.totalRackDeposit : 0;
    const isLoadingCartRequest = useSelector(selectorsCart.getIsLoading);

    const openProductCard = () => {
      setIsFavorite(!!product.isFavorite);
      setIsOpen(true);
      setSelectedProduct(product);
    };

    const closeProductCard = () => {
      setIsFavorite(false);
      setIsOpen(false);
      setSelectedProduct({});
    };

    return (
      <animated.div style={props} className="basket-block_item">
        {(step === 1 || step === 2) && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              removeItem();
            }}
            className="btn-remove"
            disabled={isLoadingCartRequest}
          />
        )}
        <div className="basket-block_item-product">
          <figure className="pic">
            {salePrice && (
              <span className="item-status item-status_sale">{getDiscountPercentage(salePrice, price)}</span>
            )}
            <ThumbProductImage product={item.product} />
          </figure>
          <div className="basket-block_item-right">
            <div className="info">
              <p className="info-name title-name-cart" onClick={handleClickTitle}>
                {productTitle}
              </p>
            </div>
            <div className="product-content_quantity">
              <div className="quantity-form">
                <CartCountItem item={item} />
                <div className="quantity-price">
                  {salePrice ? (
                    <>
                      <span className="price-old">
                        {isWeightable ? formatPrice(weightablePrice) : formatPrice(quantityPrice)}
                      </span>
                      <span className="price-sale">{formatPrice(dataPrice)}</span>
                    </>
                  ) : (
                    formatPrice(dataPrice)
                  )}
                </div>
              </div>
            </div>
            {totalWeightDeposit > 0 && (
              <div className="basket-block_item-bottle">
                <div className="caption">
                  {buildLocale(currentTranslate, 'cartWeightDeposit')}
                  <div className="info-block">
                    <span className="info">?</span>
                    <div className="info-hover">{buildLocale(currentTranslate, 'cartWeightDepositInfo')}</div>
                  </div>
                </div>
                <span>{formatPrice(totalWeightDeposit)}</span>
              </div>
            )}
            {totalBottleDeposit > 0 && (
              <div className="basket-block_item-bottle">
                <span>{buildLocale(currentTranslate, 'cartBottleDeposit')}</span>
                <span>{formatPrice(totalBottleDeposit)}</span>
              </div>
            )}
            {totalRackDeposit > 0 && (
              <div className="basket-block_item-bottle">
                <span>{buildLocale(currentTranslate, 'cartRackDeposit')}</span>
                <span>{formatPrice(totalRackDeposit)}</span>
              </div>
            )}
            {notAvailableType === 'noProduct' && (
              <span className="basket-block_item-error">{buildLocale(currentTranslate, 'productNotAvailable')}</span>
            )}
            {notAvailableType === 'count' && (
              <span className="basket-block_item-error">
                {buildLocale(currentTranslate, 'productNotAvailableCount')}
              </span>
            )}
          </div>
        </div>
        {/*<CommentBlock*/}
        {/*  value={item.comment}*/}
        {/*  onBlur={handleBlur}*/}
        {/*  services={item.product.services}*/}
        {/*  defaultServices={item.services || []}*/}
        {/*/>*/}
        {isOpen && (
          <ProductCard
            isOpen={isOpen}
            id={selectedProduct.id}
            onProductCardClose={closeProductCard}
            isFavorite={isFavorite}
            setIsFavorite={setIsFavorite}
          />
        )}
      </animated.div>
    );
  },
);

export default CartItem;
