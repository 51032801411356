import React, { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import _range from 'lodash/range';
import { useScrollToTop } from '../../context/ScrollToTopContext';
import useDidUpdateEffect from '../../useHooks/useDidUpdateEffect';
import { Helmet } from 'react-helmet-async';
import config from '../../config';
import { useRouterStaticContext } from '../../context/RouterStaticContext';

interface IPaginationProps {
  onCurrentPages?: (page: number, length: number, loadMore: boolean) => any;
  count: number;
  perPage: number;
  range?: number;
  availableCount?: number;
  loading: boolean;
}

const Pagination = ({
  onCurrentPages,
  range = 1,
  availableCount = 100,
  count = 0,
  perPage,
  loading,
}: IPaginationProps) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const staticContext = useRouterStaticContext();

  const { addToWhiteList } = useScrollToTop();
  const [firstLoad, setFirstLoad] = useState(true);

  let startPage = Number(searchParams.get('page')) || 1;
  let endPage = startPage;
  const countPages = Math.ceil(count / perPage);
  const leftRange = 1 + range * 2; // >
  const rightRange = countPages - leftRange; // <
  const isLeftRange = endPage > leftRange;
  const isRightRange = endPage <= rightRange;
  const isPoints = countPages > 5 + range * 2; //12345678910 1...456...10

  let pathPage = location.pathname;

  const getUrlPage = (start?: number, end?: number) => {
    if (start !== undefined && end !== undefined && start === end) {
      end = undefined;
    }
    if (start !== undefined && start <= 1 && end === undefined) {
      start = undefined;
    }

    if (start === undefined && end === undefined) {
      return pathPage;
    }

    return `${pathPage}?page=${start}`;
  };

  useDidUpdateEffect(() => {
    if (onCurrentPages) {
      onCurrentPages(endPage, 1, endPage !== startPage);
    }
  }, [endPage, startPage]);

  const checkPages = () => {
    if (!firstLoad) {
      return null;
    }
    let start = startPage;
    let end = endPage;

    if (start > end) {
      const n = end;
      end = start;
      start = n;
    }
    if (start < 1) {
      start = 1;
      if (end < 1) {
        end = 1;
      }
    }
    if (countPages !== 0 && end > countPages) {
      end = countPages;
    }
    const rangePages = Math.floor(availableCount / perPage);
    const isOutRange = rangePages < end - start + 1;
    if (isOutRange) {
      start = end - rangePages + 1;
    }
    setFirstLoad(false);

    const newUrl = getUrlPage(start, end);
    const oldUrl = getUrlPage(startPage, endPage);

    return newUrl !== oldUrl ? newUrl : null;
  };

  useEffect(() => {
    addToWhiteList(/(page=)(\d)+/);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let pages: any[] = [];
  if (isPoints) {
    let beginRange = endPage - range - 1;
    let endRange = endPage + range + 2;
    if (beginRange <= 1) {
      beginRange = 2;
      endRange = 5 + range * 2;
    }
    if (endRange >= countPages) {
      endRange = countPages;
      beginRange = countPages - (3 + range * 2);
    }
    pages = [1, ..._range(beginRange, endRange), countPages];
  } else {
    pages = _range(1, countPages + 1);
  }

  //TODO: If pages are wrong
  false && checkPages();

  if (searchParams.get('page') === '1' && staticContext) {
    staticContext.statusCode = 301;
    staticContext.redirect = location.pathname;
  }

  if (count === 0) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        {countPages > 1 && (
          <div className="paging">
            {endPage === 1 ? (
              <span className="page-link page-prev disabled">&nbsp;</span>
            ) : (
              <>
                <Link to={getUrlPage(endPage - 1)} className="page-link page-prev">
                  &nbsp;
                </Link>
                <Helmet>
                  <link rel="prev" href={`${config.canLink}${getUrlPage(endPage - 1)}`} />
                </Helmet>
              </>
            )}
            <ul className="pagination">
              {pages.map((page, index) => {
                const isActive = page >= startPage && page <= endPage;
                let namePage = `${page}`;
                if (index === 1 && isLeftRange && isPoints) {
                  namePage = '...';
                }
                if (index === pages.length - 2 && isRightRange && isPoints) {
                  namePage = '...';
                }

                const nextPage = getUrlPage(page);

                if (isActive) {
                  if (startPage === endPage) {
                    return (
                      <li className="page-item" key={page}>
                        <span className="page-link active">{namePage}</span>
                      </li>
                    );
                  }
                  return (
                    <li className="page-item" key={page}>
                      <Link to={nextPage} className={'page-link active'}>
                        {namePage}
                      </Link>
                    </li>
                  );
                }
                return (
                  <li className="page-item" key={page}>
                    <Link className="page-link" to={nextPage}>
                      {namePage}
                    </Link>
                  </li>
                );
              })}
            </ul>
            {endPage === countPages ? (
              <span className="page-link page-next disabled">&nbsp;</span>
            ) : (
              <>
                <Link to={getUrlPage(endPage + 1)} className="page-link page-next">
                  &nbsp;
                </Link>
                <Helmet>
                  <link rel="next" href={`${config.canLink}${getUrlPage(endPage + 1)}`} />
                </Helmet>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
