import instance from './instance';
import { IDefaultSetting, ISetup } from '../typings/ISetup';
import { IUpdateCart } from '../typings/ICart';
import { ICartItem } from '../typings/ICartItem';
import { IAddress } from '../typings/IAddress';
import { ISignUp } from '../typings/ISignUp';
import { ICard } from '../typings/ICard';
import { IUser } from '../typings/IUser';
import { ISellPoint } from '../typings/ISellPoint';
import queries from './queries';
import config from '../config';
import buildQuery from '../utils/buildQuery';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TypeDelivery } from '../constants/constantsId';
import { ICustomCategory, IFetchCategory } from '../typings/ICategory';
import { IReviewPost } from '../typings/IReview';
import { ICityFetch } from '../typings/ICity';
import { IOrderPost } from '../typings/CustomTypes/ServiceTypes';
import { IOrderFull } from '../typings/IOrderItem';
import parseCartItem from '../utils/parseCartItem';
import { ICouponDTO } from '../typings/IClient';
import { IFiltersDTO } from '../typings/IFilters';
import { filterTitle, setAttributeRequest } from '../utils/setAttributeRequest';
import { getWithoutCodePhone } from '../utils/normalizePhone';
import { getLocaleStorageItem } from '../utils/localStorageHelper';
import { draftId } from '../constants/payData';
import { isMetroShop } from '../constants/shops';
import configs from '../config';
import { v4 as uuidv4 } from 'uuid';

const isMetro = isMetroShop(config.shopId);

type TypeGetExcludeTime = {
  type: Omit<TypeDelivery, 'drive' | 'express'>;
  date: Date;
  placeId: number;
};

interface ICreateOrderProps {
  draftId: number | undefined | null;
  data: any;
  products: ICartItem[];
  idCity: any;
  preAuth?: any;
}

interface IValidateEmail {
  email: string;
}

const service = {
  getCities: async (locale?: any) => {
    return await customFetch(() =>
      instance.get<ICityFetch[]>('city', {
        headers: {
          'Accept-Language': locale,
        },
      }),
    );
  },
  getSellPoints: async (locale?: any) => {
    return await customFetch<ISellPoint[]>(() =>
      instance.get<ISellPoint[]>(queries.getRestaurants().url!, {
        headers: {
          'Accept-Language': locale,
        },
      }),
    );
  },
  getDeliveryTypes: async () => {
    return await customFetch(() => instance.get(queries.getDeliveryTypes().url!));
  },
  getCustomCategories: async (isInCourierArea: boolean | null, locale?: any) => {
    const filter: any = {
      isActive: true,
      and: [],
    };
    if (isInCourierArea === true) {
      filter.and.push({
        hasActiveProducts: { eq: true },
      });
    } else {
      filter.and.push({
        hasActiveProductsWithBoxDeliveryAllowed: { eq: true },
      });
    }
    const res = await customFetch(() =>
      instance.get(`${configs.baseURLV2}custom_categories${buildQuery({ filter })}&$orderby=ord`, {
        headers: {
          'Accept-Language': locale,
        },
      }),
    );
    if (res.success) {
      return res.data as IFetchCategory[];
    }
    return [];
  },
  getCustomNoParentsCategories: async (isInCourierArea: boolean | null, locale?: any, isParent?: boolean) => {
    const filter: any = isParent ? { parent: { eq: null }, isActive: true, and: [] } : { and: [] };
    if (isInCourierArea === true) {
      filter.and.push({
        hasActiveProducts: { eq: true },
      });
    } else {
      filter.and.push({
        hasActiveProductsWithBoxDeliveryAllowed: { eq: true },
      });
    }
    const res = await customFetch(() =>
      instance.get(`${configs.baseURLV2}custom_categories${isParent && buildQuery({ filter })}&$orderby=ord`, {
        headers: {
          'Accept-Language': locale,
        },
      }),
    );
    if (res.success) {
      return res.data as IFetchCategory[];
    }
    return [];
  },

  getCategories: async (locale?: any) => {
    const res = await customFetch(() =>
      instance.get('categories?$filter=isActive&$orderby=ord asc', {
        headers: {
          'Accept-Language': locale,
        },
      }),
    );
    if (res.success) {
      return res.data as IFetchCategory[];
    }
    return [];
  },
  getCustomCategory: async (id: number, isInCourierArea: boolean | null) => {
    const filter: any = { isActive: true, and: [] };
    if (isInCourierArea === true) {
      filter.and.push({
        hasActiveProducts: { eq: true },
      });
    } else {
      filter.and.push({
        hasActiveProductsWithBoxDeliveryAllowed: { eq: true },
      });
    }

    return await customFetch(() =>
      instance.get(`${configs.baseURLV2}custom_categories/` + id + buildQuery({ filter })),
    );
  },
  getChildrenCategories: async (id: number, isInCourierArea: boolean | null) => {
    let path = configs.baseURLV2;

    if (isInCourierArea === true) {
      path += 'custom_categories/children/active_products/';
    } else {
      path += 'custom_categories/children/box_delivery_allowed/';
    }
    try {
      const res = await instance.get(`${path}${id}?$orderby=ord`);

      const data: ICustomCategory[] = res.data;

      return {
        success: true,
        data,
      };
    } catch (e) {
      return {
        success: false,
        error: e,
      };
    }
  },
  getProducts: async (ids: number[], isInCourierArea: boolean | null) => {
    const filter: any = {
      or: ids.map((id) => ({ id: id })),
      and: [],
    };
    if (isInCourierArea === false) {
      filter.and.push({
        isBoxDeliveryAllowed: { eq: true },
      });
    }
    return await customFetch(() => instance.get('/products' + buildQuery({ filter })));
  },
  searchProductsAdvanced: async (
    signal: AbortSignal,
    text: string,
    top: number,
    skip: number,
    isInCourierArea: boolean | null,
  ) => {
    const filter: any = {
      and: [],
    };

    if (isInCourierArea === false) {
      filter.and.push({
        isBoxDeliveryAllowed: { eq: true },
      });
    }
    return await customFetch(() =>
      instance.get(
        config.baseURLV3 +
          `products/city/1/search/${encodeURIComponent(text.toLowerCase())}` +
          buildQuery({ filter, top, skip }) +
          `&tid=${uuidv4()}`,
        {
          withCredentials: true,
          signal,
        },
      ),
    );
  },
  getAllProducts: async (
    signal: AbortSignal,
    text: string,
    top: number,
    skip: number,
    brands: number[] | null,
    badges: number[] | null,
    isInCourierArea: boolean | null,
    selectedAllergich?: any,
    selectedNutrion?: any,
    hasPriceRanges?: boolean,
    hasBadges?: boolean,
    hasSalePrice?: boolean,
    searchFilter?: string | null,
    tid?: string,
  ) => {
    //TODO: make 1 endpoint
    const filter: any = {
      and: [],
    };
    if (brands) {
      filter.and.push({
        or: brands.map((brand) => {
          return {
            'brand/id': { eq: brand },
          };
        }),
      });
    }
    if (badges) {
      filter.and.push({
        or: badges.map((badge) => {
          return {
            'badges/id': { eq: badge },
          };
        }),
      });
    }
    if (hasPriceRanges) {
      filter.and.push({
        'productOptions/hasPriceRanges': { eq: hasPriceRanges },
      });
    }

    if (hasBadges) {
      filter.and.push({
        'badges/id': { ne: null },
      });
    }

    if (hasSalePrice) {
      filter.and.push({
        salePrice: { gt: 0 },
      });
    }

    if (searchFilter) {
      filter.and.push({
        'tolower(titleDe)': { contains: encodeURIComponent(searchFilter.toLowerCase()) },
      });
    }

    if (isInCourierArea === false) {
      filter.and.push({
        isBoxDeliveryAllowed: { eq: true },
      });
    }

    let urlParts: string[] = [];
    const mainUrlPart = `${config.baseURLV2}products/city/1/search/${encodeURIComponent(text.toLowerCase())}`;
    const filterUrlPart = buildQuery({ filter, top, skip });
    urlParts.push(`${mainUrlPart}${filterUrlPart}`);

    const attrs = setAttributeRequest(selectedAllergich, selectedNutrion);

    if (attrs?.length) {
      urlParts.push(`${filterTitle}${encodeURIComponent(attrs)}`);
    }

    if (tid?.length && (!text?.length || searchFilter?.length)) {
      urlParts.push(`&tid=${tid}`);
    }

    return await customFetch(() =>
      instance.get(urlParts.join(''), {
        withCredentials: true,
        signal,
      }),
    );
  },
  login: async (phone: string, password: string) => {
    return await customFetch(() =>
      instance.post<IUser>(
        config.baseURLV2 + 'auth/login',
        {
          phone,
          confirmCode: password,
        },
        {
          withCredentials: true,
        },
      ),
    );
  },
  loginEmail: async (email: string, password: string) => {
    return await customFetch(() =>
      instance.post<IUser>(
        config.baseURLV3 + 'auth/login',
        {
          email,
          password,
          draftOrderId: getLocaleStorageItem(draftId),
        },
        {
          withCredentials: true,
        },
      ),
    );
  },
  firebaseLogin: async (token: string) => {
    return customFetch(() =>
      instance.post(
        config.baseURLV3 + 'auth/firebase',
        { draftOrderId: getLocaleStorageItem(draftId) },
        {
          withCredentials: true,
          headers: {
            firebaseAuthorization: token,
          },
        },
      ),
    );
  },
  impersonate: async (clientId: string, tokenHash: string) => {
    return await customFetch(() =>
      instance.post<IUser>(
        config.baseURLV3 + 'auth/impersonate',
        {
          clientId,
          tokenHash,
        },
        {
          withCredentials: true,
        },
      ),
    );
  },
  registration: async (values: any, token?: string) => {
    return await customFetch(() =>
      instance.post(config.baseURLV3 + `auth/confirmation/register?token=${token}`, values),
    );
  },
  confirmRegistration: async (body: any) => {
    return await customFetch(() =>
      instance.post(config.baseURLV3 + `auth/register`, body, {
        withCredentials: true,
      }),
    );
  },
  resendConfirmRegistration: async (body: any) => {
    return await customFetch(() =>
      instance.post(config.baseURLV3 + `auth/register/resend`, body, {
        withCredentials: true,
      }),
    );
  },
  signup: async (data: ISignUp) => {
    return await customFetch(() =>
      instance.post<IUser>('auth/register', data, {
        withCredentials: true,
      }),
    );
  },
  logout: async () => {
    try {
      await instance.post(
        'auth/logout',
        {},
        {
          withCredentials: true,
        },
      );
      return true;
    } catch (e) {
      return false;
    }
  },
  refreshUser: async (headers?: any) => {
    try {
      const res = await instance.get('clients/profile', {
        withCredentials: true,
        headers,
      });
      return res.data;
    } catch (e) {
      return null;
    }
  },
  saveCart: async (products: ICartItem[], idDelivery: number, idCity: number | null, zipCode: string | null) => {
    const uniqueIds = new Set();

    // Filter the array to keep only objects with unique IDs
    const uniqueCartProducts = products.filter((obj) => {
      const productId = obj.product.id;
      if (!uniqueIds.has(productId)) {
        uniqueIds.add(productId);
        return true;
      }
      return false;
    });

    const data: any = {
      cartProducts: parseCartItem(uniqueCartProducts),
      sellPoint: {
        id: 1,
      },
      deliveryType: {
        id: idDelivery,
      },
      zipCode: {
        code: zipCode,
      },
    };
    if (idCity) {
      data['city'] = {
        id: idCity,
      };
    }
    const draft = getLocaleStorageItem(draftId);
    if (getLocaleStorageItem(draftId)) {
      data.orderId = draft;
    }
    return await customFetch(() =>
      instance.put<IUpdateCart>(config.baseURLV2 + 'clients/cart', data, {
        withCredentials: true,
      }),
    );
  },
  deleteCart: (postalCode: string | null) => {
    return customFetch(() =>
      instance.delete(config.baseURLV2 + `clients/cart?zipCode=${postalCode}`, {
        withCredentials: true,
      }),
    );
  },
  getCart: async (postalCode: string | null) => {
    const draft = getLocaleStorageItem(draftId);
    const addedUrl = draft ? `/${draft}` : '';
    try {
      if (!postalCode) {
        throw Error();
      }
      const res = await instance.get<any>(config.baseURLV2 + 'clients/cart' + addedUrl + `?zipCode=${postalCode}`, {
        withCredentials: true,
      });

      if (res.data !== null && res.data.cartProducts && res.data.cartProducts.length > 0) {
        res.data.cartProducts = res.data.cartProducts.map((p) => {
          p.alternativeCount = p.alternativeCount ? +p.alternativeCount : null;
          p.count = +p.count;
          return p;
        });

        return {
          sellPoint: res.data.sellPoint,
          items: res.data.cartProducts,
          deliveryType: res.data.deliveryType,
          total: res.data.total,
          bottleTotalDeposit: res.data.bottleTotalDeposit,
          deliveryCost: res.data.deliveryCost,
          rackTotalDeposit: res.data.rackTotalDeposit,
          notAvailableProducts: res.data.notAvailableProducts,
          productsPrice: res.data.productsPrice,
          totalWeightDeposit: res.data.totalWeightDeposit,
          totalWeight: res.data.totalWeight,
        };
      }
      return {
        sellPoint: null,
        items: [] as ICartItem[],
        deliveryType: null,
      };
    } catch (e) {
      return {
        sellPoint: null,
        items: [] as ICartItem[],
        deliveryType: null,
      };
    }
  },
  createOrder: async ({ data, draftId, idCity, products, preAuth }: ICreateOrderProps) => {
    const receiver = data.contact;
    let contact: any = {};
    const addressData: any = {};

    if (receiver) {
      contact = {
        contact: {
          id: receiver.id!,
        },
      };
    }
    addressData.orderAddress = null;
    addressData.address = null;

    let maxExecuteDate: Date | undefined = undefined;
    let minExecuteDate: Date | undefined = maxExecuteDate;

    if (data.date) {
      const [minTime, maxTime] = data.time.split('-');
      const [maxH, maxM] = maxTime.split(':').map(parseFloat);
      const [minH, minM] = minTime.split(':').map(parseFloat);

      maxExecuteDate = new Date(data.date);
      maxExecuteDate.setHours(maxH, maxM);

      minExecuteDate = new Date(data.date);
      minExecuteDate.setHours(minH, minM);
    }

    const fetchData: IOrderPost = {
      id: draftId,
      comment: data.commentOrder,
      deliveryStatus: {
        id: 1,
      },
      deliveryType: {
        id: data?.deliveryType?.id || 1,
      },
      deliveryPrice: null,
      paymentType: data.paymentType,
      ...contact,
      ...addressData,
      timeToPrepare: null,
      sellPoint: { id: 1 },
      maxExecuteDate,
      minExecuteDate,
      preAuth,
      orderSource: { id: 1 },
      cartProducts: parseCartItem(products),
    };

    if (data.contactId) {
      fetchData.contact = { id: data.contactId };
    }

    fetchData['city'] = {
      id: idCity,
    };

    try {
      const res = await instance.post('clients/order', fetchData, {
        withCredentials: true,
      });

      return {
        success: true,
        data: res.data,
      };
    } catch (e) {
      return {
        success: false,
        data: e,
      };
    }
  },
  updateProfile: async (data: any) => {
    return await customFetch(() =>
      instance.put('clients/profile', data, {
        withCredentials: true,
      }),
    );
  },
  getOrders: async ({ top, skip }: { top: number; skip: number }) => {
    const filter = {
      'order_status/code': {
        ne: 'draftWeb',
      },
    };
    return await customFetch(() =>
      instance.get('/clients/orders' + buildQuery({ filter, count: true, top, skip, orderBy: 'createdDate desc' }), {
        withCredentials: true,
      }),
    );
  },
  addAddress: async (data: Omit<IAddress, 'id'>) => {
    return await customFetch(() =>
      instance.post('clients/addresses', data, {
        withCredentials: true,
      }),
    );
  },
  addOrderAddress: async (data: Omit<IAddress, 'id'>) => {
    return await customFetch(() =>
      instance.post('order_addresses', data, {
        withCredentials: true,
      }),
    );
  },
  updateAddress: async (data: any) => {
    const id = data.id;
    delete data.id;
    return await customFetch(() =>
      instance.put(`clients/addresses/${id}`, data, {
        withCredentials: true,
      }),
    );
  },
  deleteAddress: async (id: number) => {
    return await customFetch(() =>
      instance.delete(`clients/addresses/${id}`, {
        withCredentials: true,
      }),
    );
  },
  addContact: async (data: any) => {
    const dataDTO = {
      email: data.email,
      lastName: data.lastName,
      firstName: data.name,
      phone: getWithoutCodePhone(data.phone),
    };
    if (isMetro) {
      dataDTO['company'] = data.company;
    }
    return await customFetch(() =>
      instance.post('clients/contacts', dataDTO, {
        withCredentials: true,
      }),
    );
  },
  updateContact: async (data: any) => {
    const id = data.id;
    delete data.id;
    return await customFetch(() =>
      instance.put(`clients/contacts/${id}`, data, {
        withCredentials: true,
      }),
    );
  },
  deleteContact: async (id: number) => {
    return await customFetch(() =>
      instance.delete(`clients/contacts/${id}`, {
        withCredentials: true,
      }),
    );
  },
  getAllSetups: async (locale?: any) => {
    try {
      const res = await instance.get<ISetup[]>('setups', {
        headers: {
          'Accept-Language': locale,
        },
        params: {
          skip: 0,
          top: 50,
        },
      });
      return res.data;
    } catch (e) {
      return [];
    }
  },
  getSettings: async (locale?: any) => {
    try {
      const res = await instance.get<IDefaultSetting[]>('setups/settings', {
        headers: {
          'Accept-Language': locale,
        },
        params: {
          skip: 0,
          top: 50,
        },
      });
      return res.data;
    } catch (e) {
      return [];
    }
  },
  getOrder: async (id: number) => {
    return await customFetch(() =>
      instance.get<IOrderFull>('clients/orders/' + id, {
        withCredentials: true,
      }),
    );
  },
  resetPassword: async ({ email, token }: { email: string; token: string }) => {
    return customFetch(() =>
      instance.post(
        config.baseURLV3 + `auth/confirmation/reset-password?token=${token}`,
        { email },
        {
          withCredentials: true,
        },
      ),
    );
  },
  restorePassword: async ({
    password,
    confirmedPassword,
    email,
    code,
  }: {
    password: string;
    confirmedPassword: string;
    email: string;
    code: string;
  }) => {
    return customFetch(() =>
      instance.post(
        config.baseURLV3 + 'auth/reset-password',
        {
          password,
          confirmedPassword,
          email,
          code,
        },
        {
          withCredentials: true,
        },
      ),
    );
  },
  changePassword: async (password, confirmedPassword) => {
    return customFetch(() =>
      instance.post(
        config.baseURLV3 + 'auth/change-password',
        {
          newPassword: password,
          confirmNewPassword: confirmedPassword,
        },
        {
          withCredentials: true,
        },
      ),
    );
  },
  getCards: async () => {
    return await customFetch(() =>
      instance.get<ICard[]>('clients/creditCards', {
        withCredentials: true,
      }),
    );
  },
  deleteCard: async (id: number) => {
    return await customFetch(() =>
      instance.delete('clients/creditCards/' + id, {
        withCredentials: true,
      }),
    );
  },
  createDraft: async (data?: any) => {
    return await customFetch(() =>
      instance.post('clients/order/draft', data, {
        withCredentials: true,
      }),
    );
  },
  getExcludeTime: async ({ date, placeId, type }: TypeGetExcludeTime) => {
    const y = date.getFullYear();
    const m = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const dateStr = `${y}-${m}-${day}`;
    return await customFetch(() => instance.get(`delivery/time/exclude/${type}/${dateStr}/${placeId}`));
  },
  getCurrentTime: async () => {
    const res = await customFetch(() => instance.get('clients/order/date'));
    if (res.success) {
      return new Date(res.data.date);
    }
    return new Date();
  },
  addReview: async ({ data, productId }: { productId: number; data: IReviewPost }) => {
    return await customFetch(() =>
      instance.post(`products/${productId}/reviews`, data, {
        withCredentials: true,
      }),
    );
  },
  getReviews: async (productId: number) => {
    return await customFetch(() => instance.get(`products/${productId}/reviews`, { withCredentials: true }));
  },
  productSubscription: async ({
    token,
    ...data
  }: {
    phone: string;
    product: number;
    sellPoint: number | null;
    token: string;
  }) => {
    return await customFetch(() => instance.post(`productSubscriptions?token=${token}`, data));
  },

  getWishlist: async (
    filters: IFiltersDTO,
    top: number,
    skip: number,
    isInCourierArea: boolean | null,
    order?: string,
  ) => {
    const {
      brands,
      badges,
      selectedAllergich,
      selectedNutrion,
      hasPriceRanges,
      hasBadges,
      hasSalePrice,
      searchFilter,
    } = filters;
    const filter: any = { and: [] };

    if (brands) {
      filter.and.push({
        or: brands.map((brand) => {
          return {
            'brand/id': { eq: brand },
          };
        }),
      });
    }
    if (badges) {
      filter.and.push({
        or: badges.map((badge) => {
          return {
            'badges/id': { eq: badge },
          };
        }),
      });
    }

    if (hasPriceRanges) {
      filter.and.push({
        'productOptions/hasPriceRanges': { eq: hasPriceRanges },
      });
    }

    if (hasSalePrice) {
      filter.and.push({
        'productOptions/salePrice': { ne: null },
      });
    }

    if (hasBadges) {
      filter.and.push({
        'badges/id': { ne: null },
      });
    }

    if (isInCourierArea === false) {
      filter.and.push({
        isBoxDeliveryAllowed: { eq: true },
      });
    }

    return await customFetch(() =>
      instance.get(
        config.baseURLV2 +
          `favorites/city/1` +
          buildQuery({
            filter,
            top,
            skip,
            search: encodeURIComponent(searchFilter?.toLowerCase() || ''),
            orderBy: order,
          }) +
          `${
            setAttributeRequest(selectedAllergich, selectedNutrion)
              ? filterTitle + encodeURIComponent(setAttributeRequest(selectedAllergich, selectedNutrion))
              : ''
          }`,
        {
          withCredentials: true,
        },
      ),
    );
  },
  addToWishlist: async (id: number) => {
    return await customFetch(() =>
      instance.post(`/favorites/${id}`, null, {
        withCredentials: true,
      }),
    );
  },
  removeFromWishlist: async (id: number) => {
    return await customFetch(() =>
      instance.delete(`/favorites/${id}`, {
        withCredentials: true,
      }),
    );
  },
  getTranslate: async () => {
    return await customFetch(() =>
      instance.get(`/translations/website`, {
        withCredentials: true,
      }),
    );
  },
  getHTMLTranslate: async () => {
    return await customFetch(() =>
      instance.get(`translations/website-html`, {
        withCredentials: true,
      }),
    );
  },
  getPictures: async () => {
    return await customFetch(() =>
      instance.get(`/pictures`, {
        withCredentials: true,
      }),
    );
  },
  getMessages: async () => {
    return await customFetch(() =>
      instance.get(`/public_messages?$filter=isActive`, {
        withCredentials: true,
      }),
    );
  },
  getNearbyDeliveryTime: async () => {
    return await customFetch(() => {
      const today = new Date().toISOString().substring(0, 10);
      return instance.get(`/delivery/time/include/courier/${today}/1`, {
        withCredentials: true,
      });
    });
  },
  getClientPaymentToken: async (data: any) => {
    const config = {
      withCredentials: true,
      contentType: 'application/json',
    };
    return await customFetch(() => instance.post('clients/stripe/payment', data, config));
  },
  validateClientKey: async (key: string | null) => {
    return await customFetch(() => instance.get(config.baseURLCallback + `stripe/validate/${key}`));
  },
  calculateDeliveryPrice: async (sellPoint: number, price: string) => {
    return await customFetch(() => instance.get(`delivery_prices/${sellPoint}/calculate/${price}`));
  },
  validateCoupon: async (body: ICouponDTO) => {
    const config = {
      withCredentials: true,
      contentType: 'application/json',
    };
    return await customFetch(() => instance.put(`clients/coupon/validate`, JSON.stringify(body), config));
  },
  unlockProducts: async (id: number) => {
    return await customFetch(() => instance.get(`orders/${id}/products/unlock`));
  },
  paypalCapture: async (paymentId: string) => {
    return await customFetch(() => instance.post(config.baseURLCallback + 'paypal/payment/capture', { paymentId }));
  },
  paypalPayment: async (price: number, orderId: number | null) => {
    return customFetch(() => instance.post(config.baseURLCallback + 'paypal/payment', { price, orderId }));
  },
  getBrandById: async (brandId: number) => {
    return customFetch(() => instance.get(config.baseURL + `clients/brands/${brandId}`));
  },
  getBioBadgeById: async (bioBadgeId: number) => {
    return customFetch(() => instance.get(config.baseURL + `clients/organic_badges/${bioBadgeId}`));
  },
  addProductsToWishlist: async (products: Array<number>) => {
    const config = {
      withCredentials: true,
      contentType: 'application/json',
    };
    return customFetch(() => instance.post('favorites', { products: products }, config));
  },
  validateEmail: async (body: IValidateEmail) => {
    return customFetch(() => instance.post('/clients/check/email', body));
  },
  getCreditsHistory: async ({ top, skip }) => {
    return await customFetch(() =>
      instance.get(`clients/credit_history?$count=true&$top=${top}&$skip=${skip}`, { withCredentials: true }),
    );
  },
  downloadInvoice: (orderId: number) => {
    const config = {
      'Content-Type': 'application/json',
      withCredentials: true,
      responseType: 'blob',
    } as AxiosRequestConfig;
    return instance.get(`/clients/orders/${orderId}/invoice`, config);
  },
  getZipCodeInfo: async (zipCode: string) => {
    const config = {
      withCredentials: true,
      contentType: 'application/json',
    };
    return await customFetch(() => instance.get(`zip_codes/${zipCode}`, config));
  },
  subscribeClientToProduct: async (productOptionId: number) => {
    return await customFetch(() =>
      instance.post(`/product_options_subscriptions`, { productOptionId }, { withCredentials: true }),
    );
  },
  unsubscribeClientFromProduct: async (subscriptionId: number) => {
    return await customFetch(() =>
      instance.delete(`/product_options_subscriptions/${subscriptionId}`, { withCredentials: true }),
    );
  },
};
const customFetch = async <T>(func: () => Promise<AxiosResponse<T>>) => {
  try {
    const res = await func();

    return {
      success: true,
      data: res.data as T,
      code: res.status,
    };
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return {
        success: false,
        data: e.response.data.message,
        code: e.response.status,
        codeInfo:
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.errorInfo &&
            e.response.data.error.errorInfo.code) ||
          '',
      };
    }
    return {
      success: false,
      data: 'error',
      code: 500,
    };
  }
};

export default service;
