export const cities = [
  {
    label: 'München',
    value: 1,
  },
  {
    label: 'Grünwald',
    value: 2,
  },
  {
    label: 'Pullach im Isartal',
    value: 3,
  },
  {
    label: 'Unterhaching',
    value: 4,
  },
  {
    label: 'Taufkirchen',
    value: 5,
  },
  {
    label: 'Oberhaching',
    value: 6,
  },
  {
    label: 'Neubiberg',
    value: 7,
  },
  {
    label: 'Ottobrunn',
    value: 8,
  },
  {
    label: 'Waldkolone',
    value: 9,
  },
  {
    label: 'Haar',
    value: 10,
  },
  {
    label: 'Feldkirchen',
    value: 11,
  },
  {
    label: 'Aschheim',
    value: 12,
  },
  {
    label: 'Kirchheim bei München',
    value: 13,
  },
  {
    label: 'Unterföhring',
    value: 14,
  },
  {
    label: 'Isamning',
    value: 15,
  },
  {
    label: 'Garching bei München',
    value: 16,
  },
  {
    label: 'Neuherberg',
    value: 17,
  },
  {
    label: 'Oberschleißheim',
    value: 18,
  },
  {
    label: 'Unterschleißheim',
    value: 19,
  },
  {
    label: 'Karlsfeld',
    value: 20,
  },
  {
    label: 'Dachau',
    value: 21,
  },
  {
    label: 'Gröbenzell',
    value: 22,
  },
  {
    label: 'Eschenried',
    value: 23,
  },
  {
    label: 'Gröbenried',
    value: 24,
  },
  {
    label: 'Olching',
    value: 25,
  },
  {
    label: 'Puchheim',
    value: 26,
  },
  {
    label: 'Eichenau',
    value: 27,
  },
  {
    label: 'Germering',
    value: 28,
  },
  {
    label: 'Gräfelfing',
    value: 29,
  },
  {
    label: 'Planegg',
    value: 30,
  },
  {
    label: 'Krailling',
    value: 31,
  },
  {
    label: 'Neuried',
    value: 32,
  },
  {
    label: 'Perlacher Forst',
    value: 33,
  },
  {
    label: 'Grünwalder Forst',
    value: 34,
  },
  {
    label: 'Deisenhofener Forst',
    value: 35,
  },
  {
    label: 'Gauting',
    value: 36,
  },
  {
    label: 'Putzbrunn',
    value: 37,
  },
  {
    label: 'Grasbrunn',
    value: 38,
  },
  {
    label: 'Hohenbrunn',
    value: 39,
  },
  {
    label: 'Vaterstetten',
    value: 40,
  },
  {
    label: 'Starnberg',
    value: 41,
  },
];
