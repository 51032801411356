import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import service from '../services/service';
import { useSearchParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../config';
import MiniLoader from '../components/Loaders/MiniLoader';
import { refreshUser } from '../redux/user/userReducer';
import { usePaths } from '../components/Routes/RouterList';
import { useNavigate } from 'react-router';
import { selectorsOther } from '../redux/other/otherReducer';
import { useLessThen991 } from '../utils/mediaQuery';

interface FinishRegistrationCodeWidgetProps {
  clientError?: string;
}

const FinishRegistrationCodeWidget = ({ clientError }: FinishRegistrationCodeWidgetProps) => {
  const [searchParams] = useSearchParams();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const path = usePaths();
  const navigate = useNavigate();
  const clientEmail = searchParams.get('email');
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [isCodeProcessingAvailable, setIsCodeProcessingAvailable] = useState(false);
  const [isAvailableResend, setIsAvailableResend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeApplyingBtnVisible, setIsCodeApplyingBtnVisible] = useState(false);
  const [confirmationMess, setConfirmationMess] = useState<{ css: string; value: string } | null>(null);
  const dispatch = useDispatch<any>();

  const recaptchaRef = useRef<ReCAPTCHA>();

  const refCodes = {
    code1: useRef(null as HTMLInputElement | null),
    code2: useRef(null as HTMLInputElement | null),
    code3: useRef(null as HTMLInputElement | null),
    code4: useRef(null as HTMLInputElement | null),
  };

  const runRecaptcha = async () => {
    await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (refCodes.code1?.current) {
        refCodes.code1.current.focus();
      }
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!clientError) {
      return;
    }
    if (
      ['abgelaufen', 'expired', 'ersuche ist begrenzt'].some((value: string) =>
        clientError.toLowerCase().includes(value),
      )
    ) {
      setIsAvailableResend(true);
      setConfirmationMess({ css: 'reg-error', value: clientError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientError]);

  const runRegistrationCodeConfirmation = () => {
    setIsCodeProcessingAvailable(false);
    setIsCodeApplyingBtnVisible(false);
    setConfirmationMess(null);
    setIsLoading(true);
    runRecaptcha();
    const code = Object.values(refCodes)
      .map((item) => item.current?.value)
      .join('');
    service
      .confirmRegistration({
        code,
        email: clientEmail,
      })
      .then((data) => {
        if ([423, 412].includes(data?.code)) {
          setIsLoading(false);
          setIsCodeApplyingBtnVisible(true);
          setIsAvailableResend(data?.code === 423);
          setConfirmationMess({ css: 'reg-error', value: data.data });
          return;
        }
        if ([500, 400].includes(data?.code)) {
          setIsLoading(false);
          setIsCodeApplyingBtnVisible(true);
          const clientErrorMess = data.code === 500 ? buildLocale(currentTranslate, 'errorClient') : data.data;
          setConfirmationMess({ css: 'reg-error', value: clientErrorMess });
          return;
        }
        dispatch(refreshUser);
        navigate(path.home);
      })
      .catch((e) => {
        setIsLoading(false);
        setIsCodeApplyingBtnVisible(true);
        setConfirmationMess({ css: 'reg-error', value: buildLocale(currentTranslate, 'errorClient') });
      });
  };

  useEffect(() => {
    if (isCodeProcessingAvailable) {
      runRegistrationCodeConfirmation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCodeProcessingAvailable]);

  const isCodeFilled = (): boolean => {
    return [
      refCodes.code1.current?.value?.length,
      refCodes.code2.current?.value?.length,
      refCodes.code3.current?.value?.length,
      refCodes.code4.current?.value?.length,
    ].every((value: number | undefined) => !!value);
  };

  const checkCodeProcessingVisibility = () => {
    const isFullCodeFilled = isCodeFilled();
    setIsCodeProcessingAvailable(isFullCodeFilled);
    setIsCodeApplyingBtnVisible(isFullCodeFilled);
    setConfirmationMess(null);
  };

  const spreedSymbolsIntoInputs = (value: string) => {
    const codeParts = String(value).split('');
    for (const refKeyIndex in Object.keys(refCodes)) {
      const currentRef = refCodes[`code${Number(refKeyIndex) + 1}`];
      if (!currentRef.current) {
        continue;
      }
      const codeItem = codeParts.at(Number(refKeyIndex));
      if (codeItem === undefined) {
        currentRef.current.value = null;
        continue;
      }
      currentRef.current.focus();
      currentRef.current.value = codeItem;
    }
    checkCodeProcessingVisibility();
  };

  const tryToHandleMobilePasteFromClipboard = async () => {
    if (navigator?.clipboard?.readText) {
      try {
        const value = await navigator.clipboard.readText();
        if (!value?.length || !/^\d{4}$/.test(value)) {
          return;
        }
        spreedSymbolsIntoInputs(value);
        navigator.clipboard.writeText('');
      } catch (e) {
        // avoid NotAllowedError for Safari browser or iOS due to clipboard read permission
        // there is no way to handle programmatically triggering the paste for now
      }
    }
  };

  const handleChange = (e: any, refKeyIndex: number) => {
    const value = e.target.value;
    if (value?.length && refKeyIndex < 4) {
      refCodes[`code${refKeyIndex + 1}`].current.focus();
    }
    checkCodeProcessingVisibility();
  };

  const handleFocus = (refKeyIndex: number) => {
    if (refKeyIndex === 1) {
      tryToHandleMobilePasteFromClipboard();
    }
  };

  const keyDown = (e: React.KeyboardEvent<HTMLInputElement>, refKeyIndex: number) => {
    const allowedKeys: string[] = ['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace', 'Control', 'v'];
    const currentKey = e.key;

    if (allowedKeys.includes(currentKey)) {
      if (currentKey === 'Backspace' && !refCodes[`code${refKeyIndex}`]?.current?.value?.length) {
        const prevRef = refCodes[`code${Number(refKeyIndex) - 1}`];
        if (!prevRef?.current) {
          return;
        }
        prevRef.current.value = null;
        prevRef.current.focus();
      }

      return;
    }
    if (!/^\d+$/.test(currentKey)) {
      e.preventDefault();
    }
    if (String(currentKey).length === 1) {
      return;
    }
    if (!isLessThen991 && refKeyIndex !== 1 && currentKey !== 'unidentified') {
      return;
    }
    tryToHandleMobilePasteFromClipboard();
  };

  const handlePaste = (e: any, refKeyIndex: number) => {
    e.preventDefault();
    if (refKeyIndex !== 1) {
      return;
    }
    const value = e.clipboardData.getData('text');
    if (!/^\d+$/.test(value)) {
      return;
    }
    spreedSymbolsIntoInputs(value);
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText('');
    }
  };

  const resendRegisterConfirmation = () => {
    setIsAvailableResend(false);
    setIsCodeApplyingBtnVisible(false);
    setConfirmationMess(null);
    setIsLoading(true);

    service
      .resendConfirmRegistration({ email: clientEmail })
      .then((data) => {
        for (const refKeyIndex in Object.keys(refCodes)) {
          const currentRef = refCodes[`code${Number(refKeyIndex) + 1}`];
          if (!currentRef.current) {
            continue;
          }
          currentRef.current.value = '';
        }
        setIsLoading(false);
        setIsCodeApplyingBtnVisible(true);
        if ([500, 400].includes(data?.code)) {
          setConfirmationMess({
            css: 'reg-error',
            value: data?.data || buildLocale(currentTranslate, 'confirmationLetterResendingError'),
          });
          return;
        }
        setConfirmationMess({
          css: 'reg-success',
          value: buildLocale(currentTranslate, 'confirmationLetterResentSuccessfully'),
        });
      })
      .catch((e) => {
        setIsLoading(false);
        setIsAvailableResend(true);
        setIsCodeApplyingBtnVisible(true);
        setConfirmationMess({
          css: 'reg-error',
          value: buildLocale(currentTranslate, 'confirmationLetterResendingError'),
        });
      });
  };

  return (
    <section className="code-confirmation">
      <p className="subtitle">{buildLocale(currentTranslate, 'or')}</p>
      <p className="note">{buildLocale(currentTranslate, 'insertConfirmationCodeFromEmail')}</p>
      <div className="code-confirmation_box">
        <form>
          <ul className="code-confirmation_items">
            {Object.entries(refCodes).map(([key, value], index) => (
              <li key={key}>
                <input
                  type="number"
                  name={key}
                  maxLength={1}
                  ref={value}
                  className="form-control"
                  onChange={(e: any) => handleChange(e, index + 1)}
                  onKeyDown={(e: any) => keyDown(e, index + 1)}
                  onPaste={(e: any) => handlePaste(e, index + 1)}
                  onFocus={() => handleFocus(index + 1)}
                />
              </li>
            ))}
          </ul>
          {!!confirmationMess && (
            <p className={confirmationMess.css}>
              <>
                {confirmationMess.value}
                {isAvailableResend && (
                  <button className="btn-resend" onClick={resendRegisterConfirmation}>
                    {buildLocale(currentTranslate, 'btnResend')}
                  </button>
                )}
              </>
            </p>
          )}
          {isLoading && (
            <p>
              <MiniLoader style={{ width: '50%', height: '2.5rem', margin: '0 0 1rem 0', display: 'inline-block' }} />
            </p>
          )}
        </form>
        <button
          type="submit"
          className="btn code-confirmation_submit"
          onClick={runRegistrationCodeConfirmation}
          disabled={!isCodeApplyingBtnVisible}>
          {buildLocale(currentTranslate, 'btnContinue')}
        </button>
      </div>
      <ReCAPTCHA sitekey={config.recaptchaPublicSiteKey} size="invisible" ref={recaptchaRef} />
    </section>
  );
};

export default FinishRegistrationCodeWidget;
