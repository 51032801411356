import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import searchIcon from '../../assets/images/ic-close_m.svg';
import BreadcrumbsStaticPage from '../../views/common/BreadcrumbsStaticPage';

interface IProps {
  onSearch: (text: string) => void;
}

const BrandsHeader = (props: IProps) => {
  const { onSearch } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [searchField, setSearchField] = useState('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchField);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  const clearSearch = () => {
    setSearchField('');
    onSearch('');
  };

  return (
    <div className="brands-page_top">
      <BreadcrumbsStaticPage lastItemName={buildLocale(currentTranslate, 'brandsBreadcrumps')} />
      <section className="brands-top">
        <div className="container">
          <h1>{buildLocale(currentTranslate, 'brandsTitle')}</h1>
          <div className="brands-search">
            <input
              placeholder="Suche by name Marken"
              onChange={(e) => setSearchField(e.target.value)}
              value={searchField}
            />
            {searchField.length > 0 && (
              <span className="search-close" onClick={() => clearSearch()}>
                <img src={searchIcon} alt="" width="18" height="18" />
              </span>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BrandsHeader;
