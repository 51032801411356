import React, { useState } from 'react';
import star from '../../../assets/images/star.svg';
import fullStar from '../../../assets/images/star-full.svg';
import useDidUpdateEffect from '../../../useHooks/useDidUpdateEffect';

interface IReviewStarsProps {
  isEditable: boolean;
  additionalData?: React.ReactNode;
  onSubmit?: (n: number) => any;
  processStarsClick?: () => any;
  ratingValue?: number;
  itemsCount?: number;
}

const ReviewStars = ({
  isEditable,
  additionalData,
  ratingValue = 0,
  itemsCount = 0,
  onSubmit,
  processStarsClick,
}: IReviewStarsProps) => {
  const [rating, setRating] = useState(ratingValue);

  useDidUpdateEffect(() => {
    setRating(ratingValue);
  }, [ratingValue]);

  const handleStarsClick = () => {
    if (processStarsClick) {
      processStarsClick();
    }
  };

  return (
    <>
      <ul
        className="product-reviews_rating"
        onClick={handleStarsClick}
        onMouseLeave={() => {
          setRating(ratingValue);
        }}>
        {[1, 2, 3, 4, 5].map((item) => {
          const isFull = item <= rating;
          return (
            <Star
              key={item}
              setRating={setRating}
              isEditable={isEditable}
              isFull={isFull}
              onSubmit={onSubmit}
              processStarsClick={processStarsClick}
              value={item}
            />
          );
        })}
        {additionalData && <li>{additionalData}</li>}
      </ul>
    </>
  );
};

interface IStarProps {
  value: number;
  isEditable: boolean;
  onSubmit?: (n: number) => any;
  processStarsClick?: () => any;
  isFull: boolean;
  setRating: (n: number) => any;
}

const Star = ({ isEditable, setRating, isFull, value, onSubmit, processStarsClick }: IStarProps) => {
  const onClick = () => {
    if (!isEditable) {
      return;
    }
    onSubmit && onSubmit(value);
  };

  const handleMouseEnter = () => {
    if (!isEditable) {
      return;
    }
    setRating(value);
  };

  return (
    <li onClick={onClick} onMouseEnter={handleMouseEnter}>
      <span className="star">
        <img
          style={{
            cursor: isEditable || processStarsClick ? 'pointer' : 'default',
          }}
          src={isFull ? fullStar : star}
          alt=""
          width="14"
          height="14"
        />
      </span>
    </li>
  );
};

export default ReviewStars;
