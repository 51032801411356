import React from 'react';
import CategoryItem from './CategoryItem';
import { useSelector } from 'react-redux';
import { selectorsCategory } from '../../redux/category/categoryReducer';

interface IProps {}

const OwnCategoriesList = (props: IProps) => {
  const categories = useSelector(selectorsCategory.getRootCategories);

  return (
    <div className="header-subcategory">
      <ul>
        {categories?.length > 0 && categories.map((category) => <CategoryItem category={category} key={category.id} />)}
      </ul>
    </div>
  );
};

export default OwnCategoriesList;
