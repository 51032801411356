import config from '../../config';
import { Locale } from '../../context/FormattingContext';

const config18n = {
  locales: ['de'],
  defaultLocale: 'de',
};

const extraLocaleData = {
  de: {
    label: 'De',
    fullLocale: 'de_DE',
    hreflang: 'de',
  },
  // en: {
  //   label: 'Eng',
  //   fullLocale: 'en_US',
  //   hreflang: 'en',
  // },
};

const getLabelLocalePair = () => {
  return Object.keys(extraLocaleData).map((key) => {
    return {
      locale: key,
      label: extraLocaleData[key].label,
    };
  }) as { locale: Locale; label: string }[];
};

const getFullLocaleByLocale = (locale: Locale) => {
  return extraLocaleData[locale].fullLocale;
};

const getHreflangByLocale = (locale: Locale) => {
  return extraLocaleData[locale].hreflang;
};

const generateHreflang = (currentLocale: Locale, location: any) => {
  const origin = config.canLink;

  const pathname = currentLocale === config18n.defaultLocale ? location.pathname : location.pathname.substr(3);

  const paths = config18n.locales.map((l) => {
    if (l === config18n.defaultLocale) {
      return {
        locale: l,
        path: origin + pathname,
      };
    }

    return {
      locale: l,
      path: origin + `/${l}` + pathname,
    };
  });

  const res = paths.map((item) => {
    return {
      hreflang: extraLocaleData[item.locale].hreflang,
      url: item.path,
    };
  });

  res.push({
    hreflang: 'x-default',
    url: origin + pathname,
  });

  return res;
};

export { getLabelLocalePair, getFullLocaleByLocale, generateHreflang, getHreflangByLocale };
export default config18n;
