export function getDiffInSeconds(endDate: string | null | undefined, startDate?: string | null | undefined): number {
  if (!endDate) {
    return 0;
  }
  const dateFrom = startDate ? new Date(startDate) : new Date();
  const dateTo = new Date(endDate);
  const diff = dateTo.getTime() - dateFrom.getTime();

  return Math.round(diff / 1000);
}
