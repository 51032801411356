import {
  addressId,
  buildNumber,
  city,
  comment,
  contactId,
  coupon,
  credits,
  dateDraft,
  draftId,
  email,
  entrance,
  firstName,
  flatNumber,
  floor,
  freeBag,
  lastName,
  method,
  phone,
  street,
  timeDraft,
  // zipCode,
} from '../constants/payData';
import { isBrowser } from './isBrowser';

export const setLocaleStorageItem = (key: string, value: string) => {
  if (!isBrowser) {
    return;
  }
  window.localStorage.setItem(key, value);
};

export const getLocaleStorageItem = (key: string) => {
  if (!isBrowser) {
    return;
  }
  return window.localStorage.getItem(key);
};

export const removeFromLocaleStorage = (key: string) => {
  if (!isBrowser) {
    return;
  }
  localStorage.removeItem(key);
};

export const resetPayData = () => {
  if (!isBrowser) {
    return;
  }
  [
    firstName,
    lastName,
    phone,
    email,
    method,
    freeBag,
    city,
    street,
    buildNumber,
    flatNumber,
    entrance,
    floor,
    dateDraft,
    timeDraft,
    comment,
    contactId,
    coupon,
    addressId,
    credits,
  ].forEach((item) => removeFromLocaleStorage(item));
};

export const resetDraftId = () => {
  if (!isBrowser) {
    return;
  }
  removeFromLocaleStorage(draftId);
};
