import React from 'react';
import { Link } from 'react-router-dom';
import { usePaths } from '../../components/Routes/RouterList';
import OtherImage from '../../components/ImagesComponent/OtherImage';
import picError from '../../assets/images/pic-error-blue.svg';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';

const ErrorPage: React.FC = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const paths = usePaths();

  return (
    <section className="section-404">
      <figure className="section-404_pic">
        <OtherImage src={picError} title="" width="408" height="244" />
      </figure>
      <div className="section-404_text">
        <p>{buildLocale(currentTranslate, 'errorClient')}</p>
        {buildLocale(currentTranslate, 'commonReturnMain')}
      </div>
      <Link to={paths.main} className="btn btn-link btn-blue">
        {buildLocale(currentTranslate, 'commonToMain')}
      </Link>
    </section>
  );
};

export default ErrorPage;
