import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import searchIcon from '../../assets/images/ic-close_m.svg';
import BreadcrumbsStaticPage from '../../views/common/BreadcrumbsStaticPage';

interface IProps {
  onSearch: (text: string) => void;
}

const BioBadgesHeader = ({ onSearch }: IProps) => {
  const [inputValue, setInputValue] = useState('');
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    onSearch(value);
  };

  const handleReset = () => {
    setInputValue('');
    onSearch('');
  };

  return (
    <div className="brands-page_top">
      <BreadcrumbsStaticPage lastItemName={buildLocale(currentTranslate, 'bioBadgesBreadcrumbs')} />
      <section className="brands-top">
        <div className="container">
          <h1>{buildLocale(currentTranslate, 'bioBadgesTitle')}</h1>
          <div className="brands-search">
            <input placeholder="Suche by name Siegel" value={inputValue} onChange={handleChange} />
            <span className="search-close" onClick={handleReset}>
              <img src={searchIcon} alt="" width="18" height="18" />
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BioBadgesHeader;
