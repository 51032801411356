import React from 'react';
import { Link } from 'react-router-dom';
import { usePaths } from '../Routes/RouterList';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogout } from '../../redux/user/userReducer';
import { isBrowser } from '../../utils/isBrowser';
import useProfileOptions from '../../useHooks/useProfileOptions';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { actionsCart } from '../../redux/cart/cartReducer';
import { actionsOther } from '../../redux/other/otherReducer';
import FormSettings from '../Forms/FormSettings';

const broadcast = isBrowser && require('../../context/BroadcastContext').default;

const SettingsOption = React.memo(() => {
  const ProfileOptions = useProfileOptions();
  const paths = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const dispatch = useDispatch<any>();
  const handleLogout = () => {
    broadcast.broadcastLogout();
    dispatch(fetchLogout);
    handleClearCart();
  };
  const handleClearCart = () => {
    dispatch(actionsCart.clear());

    dispatch(
      actionsOther.setData({
        isOpenDeliveryType: true,
      }),
    );
  };

  return (
    <div className="tab-pane settings">
      <Link to={paths.profile()} className="back-link">
        {buildLocale(currentTranslate, 'headerProfile')}
      </Link>
      <p className="profile-block_title">{ProfileOptions['settings'].name}</p>
      <div className="profile-block">
        <FormSettings />
      </div>
      <div className="profile-logout hide-sm">
        <button onClick={handleLogout} className="btn-logout">
          {buildLocale(currentTranslate, 'btnExit')}
        </button>
      </div>
    </div>
  );
});

export default SettingsOption;
