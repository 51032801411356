import { AuthorizationStrategy, FirebaseError } from './types';
import { auth } from './core';
import { resetPayData } from '../utils/localStorageHelper';

/**
 * The Context defines the interface of interest to clients.
 */
export class FirebaseService {
  private strategy: AuthorizationStrategy;

  constructor(strategy: AuthorizationStrategy) {
    this.strategy = strategy;
  }

  public setAuthorizationStrategy(strategy: AuthorizationStrategy) {
    this.strategy = strategy;
  }

  public async signin(): Promise<any> {
    try {
      const result = await this.strategy.authenticate(auth);
      const { user } = result;
      resetPayData();
      return user.getIdToken();
    } catch (error) {
      console.error(error);
      this.strategy.credentialFromError(error as FirebaseError);
      return Promise.resolve(error as Error);
    }
  }
}
