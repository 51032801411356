import React from 'react';
import ShowError from './ShowError';
import { generateClassName } from '../../../utils/generateClassName';

interface ISimpleInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  errors: any;
  label?: string;
  name: string;
  value?: string | any;
}

const SimpleInput = React.forwardRef(
  ({ label, name, type, errors, placeholder, ...rest }: ISimpleInputProps, ref: any) => {
    const isError = !!errors[name];

    return (
      <div>
        {label && <label className="profile-info_label">{label}</label>}
        <div>
          <input
            type={type || 'text'}
            className={generateClassName('form-control', {
              'error-form-border': isError,
            })}
            name={name}
            ref={ref}
            {...rest}
            placeholder={label || placeholder}
          />
        </div>
        {isError && <ShowError errors={errors} name={name} />}
      </div>
    );
  },
);

export default SimpleInput;
