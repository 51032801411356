import React from 'react';
import { Brand, WithContext } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';
import { IBrandItem } from '../../typings/IBrands';
import config from '../../config';
import { usePaths } from '../Routes/RouterList';
import { clearHtmlTags } from '../../utils/clearHtmlTags';

interface BrandJsonLdProps {
  brand?: IBrandItem;
}

const BrandJsonLd = ({ brand }: BrandJsonLdProps) => {
  const paths = usePaths();
  if (!brand) {
    return null;
  }

  const generateSchemaOrgJson = (brand: IBrandItem): WithContext<Brand> => {
    const schema: WithContext<Brand> = {
      '@context': 'https://schema.org',
      '@type': 'Brand',
      name: brand.nameDe,
      url: config.canLink + paths.brand(brand.id),
      logo: brand?.logo?.variants?.origin || '',
      description: clearHtmlTags(brand.descriptionDe),
    };

    if (brand.link) {
      schema.sameAs = brand.link;
    }

    return schema;
  };

  return <JsonLd item={generateSchemaOrgJson(brand)} />;
};

export default BrandJsonLd;
