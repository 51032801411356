import React from 'react';
import { IAddress } from '../../../typings/IAddress';
import { useAxios } from '../../../useHooks/useAxios';
import service from '../../../services/service';
import { useDispatch, useSelector } from 'react-redux';
import { actionsUser } from '../../../redux/user/userReducer';
import { generateClassName } from '../../../utils/generateClassName';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';

interface IAddressCardProps {
  address: IAddress;
  onClick: () => any;
}

const AddressCard = ({ address, onClick }: IAddressCardProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const { isLoading, request } = useAxios(service.deleteAddress);
  const dispatch = useDispatch<any>();

  const handleClick = async (e) => {
    e.stopPropagation();
    const res = await request(address.id);
    if (res.success) {
      dispatch(actionsUser.deleteAddress(address.id));
    }
  };

  return (
    <div
      onClick={onClick}
      className={generateClassName('address-card', {
        'address-card-disabled': isLoading,
      })}>
      <button className="btn-delete" onClick={handleClick} />
      <div className="block-address">
        <p>
          <strong>{buildLocale(currentTranslate, 'commonStreet')}: </strong>
          {address.street}
        </p>
        <p>
          <strong>{buildLocale(currentTranslate, 'tiBuildPlaceholder')}: </strong>
          {address.buildNumber}
        </p>
        {address.corps && (
          <p>
            <strong>{buildLocale(currentTranslate, 'tiCorpsPlaceholder')}: </strong>
            {address.corps}
          </p>
        )}
        {address.entrance && (
          <p>
            <strong>{buildLocale(currentTranslate, 'tiEntrancePlaceholder')}: </strong>
            {address.entrance}
          </p>
        )}
        {address.floor && (
          <p>
            <strong>{buildLocale(currentTranslate, 'tiFloorPlaceholder')}: </strong>
            {address.floor}
          </p>
        )}
        {address.flatNumber && (
          <p>
            <strong>{buildLocale(currentTranslate, 'tiFlatPlaceholder')}: </strong>
            {address.flatNumber}
          </p>
        )}
        {address.zipCode && (
          <p>
            <strong>{buildLocale(currentTranslate, 'zipPlaceholder')}: </strong>
            {address.zipCode}
          </p>
        )}
        <p>
          <strong>{buildLocale(currentTranslate, 'personalInfoCity')}: </strong>
          {address.city || ''}
        </p>
      </div>
    </div>
  );
};

export default AddressCard;
