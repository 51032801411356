import React from 'react';
import { IAddressRedux } from '../../typings/CustomTypes/IAddressRedux';
import FormDictionaryAddress from './FormDictionaryAddress';

interface IFormAddressProps {
  idForm: string;
  defaultAddress?: any;
  onSubmit: (d: IAddressRedux) => any;
  error?: boolean;
}

const FormAddress = ({ defaultAddress, idForm, onSubmit, error }: IFormAddressProps) => {
  return (
    <div className="form-address-con">
      <FormDictionaryAddress idForm={idForm} onSubmit={onSubmit} defaultAddress={defaultAddress} error={error} />
    </div>
  );
};

export default FormAddress;
