import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import FinishRegistrationCodeWidget from './FinishRegistrationCodeWidget';
import { selectorsUser } from '../redux/user/userReducer';
import InfoModal from '../components/Modals/InfoModal';

const FinishRegistrationPage = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const clientError = useSelector(selectorsUser.getError);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (clientError?.length) {
      setIsOpenModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientError]);

  return (
    <section className="section-register">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <article className="register-after">
              <h1>{buildLocale(currentTranslate, 'finishRegistationTitle')}</h1>
              <p className="subtitle">{buildLocale(currentTranslate, 'finishRegistationMainDesc')}</p>
              {buildLocale(currentTranslate, 'finishRegistationDesc')}
              <FinishRegistrationCodeWidget clientError={clientError} />
              <InfoModal
                title={buildLocale(currentTranslate, 'errorClient')}
                onSubmit={() => setIsOpenModal(false)}
                submitTitle={buildLocale(currentTranslate, 'btnClose')}
                isOpen={isOpenModal}
                toggle={() => setIsOpenModal(false)}>
                <p className="low-price-error">{clientError}</p>
              </InfoModal>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinishRegistrationPage;
