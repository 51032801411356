import React from 'react';
import ContentLoader from 'react-content-loader';
import { useLessThen991 } from '../../utils/mediaQuery';
import { useSelector } from 'react-redux';
import { selectorsOther } from '../../redux/other/otherReducer';

const ProductLoader = () => {
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);

  const offset = isLess991 ? 5.7 : 0;
  return (
    <ContentLoader
      speed={2}
      style={{ width: '100%', height: '58rem' }}
      foregroundColor="#aaa"
      className="preloader-item"
      foregroundOpacity={0.06}>
      <rect x="0" y="1" rx="3" ry="3" width="100%" height={`${20.7 - offset}rem`} />
      <rect x="10" y={`${22 - offset}rem`} rx="3" ry="3" width="90%" height="25" />
      <rect x="10" y={`${25.5 - offset}rem`} rx="3" ry="3" width="90%" height="25" />
      <rect x="10" y={`${29 - offset}rem`} rx="3" ry="3" width="67" height="19" />
      <rect x="10" y={`${33 - offset}rem`} rx="3" ry="3" width="90%" height="10" />
      <rect x="10" y={`${36 - offset}rem`} rx="3" ry="3" width="90%" height="10" />
      <rect x="10" y={`${39 - offset}rem`} rx="3" ry="3" width="90%" height="10" />
      <rect x="10" y="51rem" rx="3" ry="3" width="90%" height="44" />
    </ContentLoader>
  );
};

export default ProductLoader;
