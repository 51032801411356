import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_NAME_SETTING } from '../../../constants/constantsId';
import { comment, dateDraft, timeDraft } from '../../../constants/payData';
import { useFormattingContext } from '../../../context/FormattingContext';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { actionsOrder } from '../../../redux/order/orderReducer';
import service from '../../../services/service';
import useAvailableDate from '../../../useHooks/useAvailableDate';
import { buildLocale } from '../../../utils/buildLocale';
import { setLocaleStorageItem, getLocaleStorageItem, removeFromLocaleStorage } from '../../../utils/localStorageHelper';
import DropdownInput, { IOption } from '../../BasketPage/Components/DropdownInput';
import ShowError from '../../Forms/Components/ShowError';

const strToTime = (str: string) => {
  const [h, m] = str.split(':').map(parseFloat);
  return h * 60 + m;
};

const consistInRange = (from: string, to: string, value: string, isFirst?: boolean) => {
  const fromTime = strToTime(from);
  const toTime = strToTime(to);
  const valueTime = strToTime(value);

  if (isFirst === undefined) {
    return valueTime >= fromTime && valueTime <= toTime;
  }
  if (isFirst) {
    return valueTime > fromTime && valueTime <= toTime;
  } else {
    return valueTime >= fromTime && valueTime < toTime;
  }
};

interface IProps {
  error: string;
  setError: (error: string) => void;
  debCreateDraft: () => void;
}

const DeliveryDataComponent = ({ error, setError, debCreateDraft }: IProps) => {
  const dispatch = useDispatch();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [currentDate, setCurrentDate] = useState(null as Date | null);
  const [excludeTime, setExcludeTime] = useState([]);
  const [time, setTime] = useState<any>('');
  const [date, setDate] = useState<any>(new Date());
  const [commentValue, setCommentValue] = useState('');
  let { getTimeRanges, optionsDate } = useAvailableDate(DEFAULT_NAME_SETTING, currentDate || new Date(), null);
  const { formatDate } = useFormattingContext();
  const optionsTime = getTimeRanges(date);

  useEffect(() => {
    setLocaleStorageItem(dateDraft, new Date().toString());
  }, []);

  useEffect(() => {
    const localeComment = getLocaleStorageItem(comment);
    setCommentValue(localeComment || '');
  }, []);

  const filterTime = useMemo(() => {
    return optionsTime.filter((t) => {
      return !excludeTime.some((e: any) => {
        const [from, to] = t.value.split(' - ');
        return consistInRange(e.timeFrom, e.timeTo, from, false) || consistInRange(e.timeFrom, e.timeTo, to, true);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeTime]);

  useEffect(() => {
    service.getCurrentTime().then((res) => {
      setCurrentDate(res);
    });
    try {
      dispatch(
        actionsOrder.setData({
          date: optionsDate[0].value,
          time: getTimeRanges(optionsDate[0].value)[0].value,
        }),
      );
    } catch (e) {
      dispatch(
        actionsOrder.setData({
          date: optionsDate[0].value,
          time: '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentTime = getLocaleStorageItem(timeDraft);
    if (currentTime && filterTime && filterTime.find((t) => t.value === currentTime)) {
      setTime(currentTime);
    } else {
      removeFromLocaleStorage(timeDraft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filterTime?.find(({ value }) => value === time)) {
      setTime('');
      removeFromLocaleStorage(timeDraft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTime]);

  useEffect(() => {
    if (date === null) {
      return;
    }
    service
      .getExcludeTime({
        date: new Date(date),
        placeId: 1,
        type: 'courier',
      })
      .then((res) => {
        if (res.success) {
          setExcludeTime(res.data);
        }
      });
  }, [date]);

  useEffect(() => {
    setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const changeDate = (value: IOption<Date>) => {
    setDate(value.value);
    setLocaleStorageItem(dateDraft, value.value.toString());
    debCreateDraft();
  };

  const changeTime = (value: IOption<string>) => {
    setTime(value.value);
    setLocaleStorageItem(timeDraft, value.value);
    debCreateDraft();
  };

  const changeComment = (value: string) => {
    setCommentValue(value);
    setLocaleStorageItem(comment, value);
  };

  return (
    <div>
      <div className="pay-block_title">
        <div className="title">
          <span className="number">3</span> {buildLocale(currentTranslate, 'payPageDelivery')}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-item">
            <label>{buildLocale(currentTranslate, 'payPageDeliveryDate')}</label>
            <DropdownInput
              value={formatDate(date)}
              options={optionsDate}
              onSelect={changeDate}
              isInput={false}
              readonly
            />
            <p className="form-note">{buildLocale(currentTranslate, 'deliveryLimits')}</p>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <label>{buildLocale(currentTranslate, 'payPageTime')}</label>
            <DropdownInput
              placeholder={filterTime.length === 0 ? `${buildLocale(currentTranslate, 'noPossibility')}` : ''}
              value={time}
              options={filterTime}
              onSelect={changeTime}
              isInput={false}
              readonly
            />
            {error && !time && <ShowError errors={{ time: 'Pflichtfeld' }} name={'time'} />}
          </div>
        </div>
        <div className="col-12">
          <div className="form-item">
            <label>{buildLocale(currentTranslate, 'cartComment')}</label>
            <textarea
              className="form-control"
              value={commentValue}
              onChange={(e) => {
                changeComment(e.target.value);
              }}
              onBlur={debCreateDraft}
              placeholder={buildLocale(currentTranslate, 'comment')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDataComponent;
