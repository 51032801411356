import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import { useNavigate, useNavigationType } from 'react-router';
import { useLocation } from 'react-router-dom';
import GoBackBtn from '../components/CategoriesMobile/GoBackBtn';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';
import CommonHelmet from '../components/common/CommonHelmet';
import BreadcrumbsStaticPage from './common/BreadcrumbsStaticPage';
import { selectorsOther } from '../redux/other/otherReducer';
import { useLessThen991 } from '../utils/mediaQuery';

const ProduktrueckrufePage = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const currentHTMLTranslate = useSelector(selectorsLocale.getHTMLTranslate);
  const navigationType = useNavigationType();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);

  const location = useLocation();
  const navigate = useNavigate();

  const backBtn = useMemo(() => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return '';
    }
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={currentTranslate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <section className="section-history">
      <CommonHelmet title={`${buildLocale(currentTranslate, 'footerProduktrueckrufe')} | MyBio.de`}></CommonHelmet>
      {backBtn}
      <div className="breadcrumbs-box">
        <BreadcrumbsStaticPage lastItemName={buildLocale(currentTranslate, 'footerProduktrueckrufe')} />
      </div>
      <div className="container">
        <div
          dangerouslySetInnerHTML={{
            __html: buildLocale(currentHTMLTranslate, 'produktrueckrufe.text'),
          }}></div>
      </div>
    </section>
  );
};

export default ProduktrueckrufePage;
