import { actionsCart } from '../redux/cart/cartReducer';
import { actionsUser } from '../redux/user/userReducer';
import { IUserActions } from '../redux/user/userTypes';
import { ICartActions } from '../redux/cart/cartTypes';

interface IDataBroadcast {
  actions: string;
  action: string;
  payload: any;
}

const actions = {
  actionsCart: actionsCart,
  actionsUser: actionsUser,
};
const nameofFactory = function <T>(name: keyof T) {
  return name;
};

class Broadcast {
  private dispatch: any;
  private bc!: BroadcastChannel;

  init = (dispatch: any) => {
    if (!this.bc) this.bc = new BroadcastChannel('test_channel');
    this.dispatch = dispatch;
    this.bc.onmessage = (ev) => {
      const data: IDataBroadcast = JSON.parse(ev.data);
      this.dispatch(actions[data.actions][data.action](data.payload));
    };
  };

  broadcastLogout = () => {
    const data = {
      actions: 'actionsUser',
      action: nameofFactory<IUserActions>('logout'),
    };
    this.bc.postMessage(JSON.stringify(data));
  };
  broadcastLogin = (user) => {
    const data = {
      actions: 'actionsUser',
      action: nameofFactory<IUserActions>('setData'),
      payload: user,
    };
    this.bc.postMessage(JSON.stringify(data));
  };
  broadcastCart = (products) => {
    const data = {
      actions: 'actionsCart',
      action: nameofFactory<ICartActions>('setData'),
      payload: products,
    };
    this.bc.postMessage(JSON.stringify(data));
  };
}
const broadcast = new Broadcast();
export default broadcast;
