import { IImage } from './CustomTypes/IImage';
import ICDNImage from './ICDNImage';

export interface IBaseCategory {
  hasChildren: boolean;
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  ord: number;
  cdnImage: ICDNImage | null;
  children?: IFetchCategory[];
}

export interface IFetchCategory extends IBaseCategory {
  seoText: string | null;
  seoTexts?: {
    description?: string | null;
    header?: string | null;
    title?: string | null;
    id?: number | null;
  } | null;
  parent: IBaseCategory | null;
  categoryImages: IImage | null;
  children: IFetchCategory[];
  visibility?: boolean;
  categoryBanner?: any;
}

export interface ITreeCategory {
  [id: string]: IFetchCategory;
}

export interface ICustomCategory {
  id: number;
  name: string;
  description: string;
  ord: number;
  parent?: IBaseCategory | null;
}

export interface IRootCategory extends ICustomCategory {
  children: IChildCategory[];
}

export interface IChildCategory extends ICustomCategory {
  parentId: number | null;
  img: string | null;
}

//TODO: remove
export const PageType = {
  Category: 'category',
  OwnCategory: 'own_category',
  Main: 'main',
  Company: 'company',
};

export enum CategoryMobilePages {
  own_categories = 'own_categories',
  categories = 'categories',
  companies = 'companies',
}

export interface CategoryRoute {
  id: number | null;
  title: string;
  type: string;
}

export type ICategoriesRoute = Array<CategoryRoute>;
