import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

interface IScrollToTopContext {
  addToWhiteList: (regx: RegExp) => void;
}

const ScrollToTopContext = React.createContext({} as IScrollToTopContext);

const useScrollToTop = () => {
  const context = useContext(ScrollToTopContext);
  return context;
};

const ProviderScrollToTop = ({ children }: any) => {
  const location = useLocation();
  const whiteList: RegExp[] = useRef([]).current;

  const addToWhiteList = useCallback((regx: RegExp) => {
    if (!whiteList.find((el) => el === regx)) whiteList.push(regx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!window) return;

    if (whiteList.some((reg) => reg.test(location.pathname))) return;

    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <ScrollToTopContext.Provider value={{ addToWhiteList }}>{children}</ScrollToTopContext.Provider>;
};

export { useScrollToTop };
export default ProviderScrollToTop;
