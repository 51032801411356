import React from 'react';
// import {useSelector} from 'react-redux';
// import {selectorsCategory} from '../../redux/category/categoryReducer';
// import {selectorsLocale} from '../../redux/locale/localeReducer';
import { ICDNVariantKey } from '../../typings/ICDNImage';
import { IProduct } from '../../typings/IProduct';

// import {buildLocale} from '../../utils/buildLocale';
import getUrlImg from '../../utils/getUrlImg';
import { isBrowser } from '../../utils/isBrowser';
import OtherImage from './OtherImage';
import { productDefaultImage } from '../../constants/productDefaultImage';

interface IImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  product: IProduct;
  isDefaultImg?: boolean;
  imageIndex?: number;
  size?: string;
}

const pixelRatio = isBrowser ? Math.min(window.devicePixelRatio, 2) : 1;

const getPropsProductImage = (product: IProduct, isDefaultImg?: boolean, imageIndex?: number) => {
  if (isDefaultImg) {
    return {
      src: productDefaultImage.variants[ICDNVariantKey.public],
      srcSet: `
        ${productDefaultImage.variants[ICDNVariantKey['250x150']]} 350w, 
        ${productDefaultImage.variants[ICDNVariantKey['550x300']]} 550w
      `,
      sizes: `
      (max-width: 767px) calc((100vw / 2 - 30px) /  ${pixelRatio}),
      calc(300px / ${pixelRatio})`,
    };
  }
  if (product.cdnImages !== null && product.cdnImages !== undefined && product.cdnImages.length > 0) {
    const cdn = product.cdnImages.sort((a: any, b: any) => {
      return a.id - b.id;
    })[imageIndex || 0];
    return {
      src: cdn.variants[ICDNVariantKey.public],
      sizes: `
      (max-width: 767px) calc((100vw / 2 - 30px) /  ${pixelRatio}),
      calc(300px / ${pixelRatio})`,
      srcSet: `
        ${cdn.variants[ICDNVariantKey['250x150']]} 350w, 
        ${cdn.variants[ICDNVariantKey['550x300']]} 550w
    `,
      srcSetLoading: cdn.variants[ICDNVariantKey.mock],
    };
  } else {
    return {
      src: getUrlImg(product.productImages),
    };
  }
};

const ProductImage = ({ product, ...props }: IImageProps) => {
  // const isRest = useSelector(selectorsCategory.isRestCategory(product.customCategory.id));
  // const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const imageProps = getPropsProductImage(product, props.isDefaultImg, props.imageIndex);
  const otherImageProps = { ...props };
  delete otherImageProps.isDefaultImg;
  delete otherImageProps.imageIndex;

  return (
    <OtherImage
      width={props.width || '335'}
      height={props.height || '171'}
      src={imageProps.src}
      srcSet={imageProps.srcSet}
      srcSetLoading={imageProps.srcSetLoading}
      sizes={props.size || imageProps.sizes}
      title={product.title}
      alt={product.title}
      {...otherImageProps}
    />
  );
};

export default ProductImage;
