import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import { CarouselConfig } from '../../constants/configCarousel';
import { ButtonGroup } from './SliderProducts';
import Product from './Product';
import { isBrowser } from '../../utils/isBrowser';
import { IProduct } from '../../typings/IProduct';
import { selectorsOther } from '../../redux/other/otherReducer';
import { buildLocale } from '../../utils/buildLocale';
import { selectorsLocale } from '../../redux/locale/localeReducer';

interface RelatedProductsWidgetProps {
  productRecommendations: IProduct[];
  productVariants: IProduct[];
  productId: number;
  responsiveConfig: CarouselConfig;
  carouselItemClass?: string;
}

const RelatedProductsWidget = (props: RelatedProductsWidgetProps) => {
  const { productRecommendations, productVariants, productId, responsiveConfig, carouselItemClass } = props;
  const [top, setTop] = useState(6);
  const isBot = useSelector(selectorsOther.getIsBot);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const setSliderProducts = () => {
    const screenWidth = window.screen.width;

    if (screenWidth < 575) return 2;
    if (screenWidth < 849) return 3;
    if (screenWidth < 1199) return 4;
    return 6;
  };

  useEffect(() => {
    if (isBrowser) {
      setTop(setSliderProducts());
    }
  }, []);

  return (
    <>
      {(!!productVariants?.length || !!productRecommendations?.length) && (
        <div className="col-12">
          <hr />
        </div>
      )}
      {!!productVariants?.length && (
        <div className="col-12">
          <h3 className="product-carousel-title">{buildLocale(currentTranslate, 'productVariants')}</h3>
          <Carousel
            key="variants"
            autoPlay={false}
            ssr={true}
            responsive={responsiveConfig}
            itemClass={carouselItemClass || 'carousel-item-li'}
            containerClass="carousel-con"
            focusOnSelect={false}
            shouldResetAutoplay={false}
            swipeable={true}
            draggable={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside
            showDots={false}
            afterChange={(previousSlide, { currentSlide }) => {
              setTop(currentSlide + setSliderProducts());
            }}
            renderDotsOutside
            dotListClass="owl-dots"
            deviceType="desktop">
            {productVariants.map((productVariant: IProduct, index) => {
              return (
                <div key={`${productVariant.id}${productId}${index}`} className="catalog-item">
                  <Product product={productVariant} isDefaultImg={isBot ? false : index > top - 1} />
                </div>
              );
            })}
          </Carousel>
        </div>
      )}

      {!!productRecommendations?.length && (
        <div className="col-12">
          <h3 className="product-carousel-title">{buildLocale(currentTranslate, 'productRecommendations')}</h3>
          <Carousel
            key="recommendations"
            autoPlay={false}
            ssr={true}
            responsive={responsiveConfig}
            itemClass={carouselItemClass || 'carousel-item-li'}
            containerClass="carousel-con"
            focusOnSelect={false}
            shouldResetAutoplay={false}
            swipeable={true}
            draggable={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside
            showDots={false}
            afterChange={(previousSlide, { currentSlide }) => {
              setTop(currentSlide + setSliderProducts());
            }}
            renderDotsOutside
            dotListClass="owl-dots"
            deviceType="desktop">
            {productRecommendations.map((recommendedProduct: IProduct, index) => {
              return (
                <div key={`${recommendedProduct.id}${productId}${index}`} className="catalog-item">
                  <Product product={recommendedProduct} isDefaultImg={isBot ? false : index > top - 1} />
                </div>
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default RelatedProductsWidget;
