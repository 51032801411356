import React from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { Link } from 'react-router-dom';
import { usePaths } from '../../components/Routes/RouterList';

interface BreadcrumbsStaticPageProps {
  lastItemName: string;
}

const BreadcrumbsStaticPage = (props: BreadcrumbsStaticPageProps) => {
  const { lastItemName } = props;
  const path = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={path.home}>{buildLocale(currentTranslate, 'commonMain')}</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {lastItemName}
        </li>
      </ol>
    </nav>
  );
};

export default BreadcrumbsStaticPage;
