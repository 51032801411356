import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { firebaseConfig } from './config';

// Initialize Firebase Application
const app = initializeApp(firebaseConfig);

// Export Firebase Service
export const auth = getAuth(app);
