export const productDefaultImage = {
  filename: 'PRODUCT_NO_IMAGE.png',
  id: 0,
  isSigned: false,
  metadata: null,
  uid: '28d791ba-04b9-45d7-453c-d968518dcf00',
  uploaded: '2022-11-26 14:36:00.532000 +00:00',
  variants: {
    '1200x900':
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/1200x900',
    '150x100':
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/150x100',
    '250x150':
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/250x150',
    '400x300':
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/400x300',
    '550x300':
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/550x300',
    '600x450':
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/600x450',
    '75x50':
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/75x50',
    '800x600':
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/800x600',
    '900x600':
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/900x600',
    mock: 'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/mock',
    origin:
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/origin',
    public:
      'https://images.mybio.de/cdn-cgi/imagedelivery/lDDUVuQ6RdtCIXW-nfkKQw/28d791ba-04b9-45d7-453c-d968518dcf00/public',
  },
};
