import React from 'react';
import { useSelector } from 'react-redux';
import { selectorsUser } from '../redux/user/userReducer';
import { Navigate } from 'react-router';

//only page component
const withProtected = (Component: any) => {
  return (props: any) => {
    const user = useSelector(selectorsUser.getUser);
    if (!user) {
      return <Navigate to="/login" />;
    }

    return <Component {...props} />;
  };
};

export default withProtected;
