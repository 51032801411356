import { useSelector } from 'react-redux';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import PersonalDataOption from '../components/ProfilePage/PersonalDataOption';
import HistoryOption from '../components/ProfilePage/HistoryOption';
import SettingsOption from '../components/ProfilePage/SettingsOption';
import CreditsOption from '../components/ProfilePage/CreditsOption';

const useProfileOptions = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const ProfileOptions = {
    data: {
      name: buildLocale(currentTranslate, 'profileLocaleData'),
      component: PersonalDataOption,
    },
    history: {
      name: buildLocale(currentTranslate, 'profileMyOrders'),
      component: HistoryOption,
    },
    settings: {
      name: buildLocale(currentTranslate, 'profileSettings'),
      component: SettingsOption,
    },
    credits: {
      name: buildLocale(currentTranslate, 'profileHistoryCredits'),
      component: CreditsOption,
    },
  };

  return ProfileOptions;
};

export default useProfileOptions;
