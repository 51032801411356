import React from 'react';
import { useSelector } from 'react-redux';
import { selectorsOther } from '../../redux/other/otherReducer';

interface IImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src: string | undefined;
  title?: string;
  srcSetLoading?: string;
  loading?: 'lazy' | 'eager';
}

const OtherImage = ({ src, title, alt, srcSet, srcSetLoading, sizes, loading = 'lazy', ...props }: IImageProps) => {
  const isBot = useSelector(selectorsOther.getIsBot);

  return (
    <img
      onLoad={() => {
        if (isBot) {
          return;
        }
        // setIsLoad(true);
      }}
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      loading={loading}
      alt={alt || title || ''}
      title={title}
      {...props}
    />
  );
};

export default OtherImage;
