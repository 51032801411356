import React, { useEffect, useState } from 'react';
import SortComponentMobile from './components/mobile/SortComponentMobile';
import FiltersComponentMobile from './components/mobile/FiltersComponentMobile';
import { useDispatch, useSelector } from 'react-redux';
import { actionsFilters, selectorsFilters } from '../../../redux/filters/filtersReducer';
import { blockBodyScroll } from '../../../utils/blockBodyScroll';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import { IUseApiQuery } from '../../../typings/IUseApiQuery';

interface IProps {
  applyFilters: () => void;
  hideSort?: boolean;
  brandsQuery: IUseApiQuery;
  badgesQuery: IUseApiQuery;
  attributesQuery: IUseApiQuery;
  disabledFilters?: string[];
}

const FiltersMobile = (props: IProps) => {
  const { applyFilters, hideSort, brandsQuery, badgesQuery, attributesQuery, disabledFilters } = props;
  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const dispatch: any = useDispatch();
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [currentSearchFilter, setCurrentSearchFilter] = useState<string | null>(null);
  const searchFilterLength = searchFilter ? searchFilter.length : 0;
  const filtersCount = totalCount - searchFilterLength;

  useEffect(() => {
    if (searchFilter !== currentSearchFilter) {
      setCurrentSearchFilter(searchFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  useEffect(() => {
    blockBodyScroll(isOpenFilter);
  }, [isOpenFilter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isFirstOpen) {
        setIsFirstOpen(false);
        return;
      }
      dispatch(actionsFilters.setSearchFilter(currentSearchFilter));
      applyFilters();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchFilter]);

  const handleSearchFilter = (value: string | null) => {
    setCurrentSearchFilter(value);
  };

  return (
    <>
      <div className={`mobile-filter_row ${hideSort ? 'mobile-filter-only' : ''}`}>
        <FiltersComponentMobile
          isOpen={isOpenFilter}
          setIsOpen={setIsOpenFilter}
          applyFilters={applyFilters}
          brandsQuery={brandsQuery}
          badgesQuery={badgesQuery}
          attributesQuery={attributesQuery}
          disabledFilters={disabledFilters}
          filtersCount={filtersCount}
        />
        {!hideSort && (
          <div className="mobile-filter_one sort" onClick={() => setIsOpenSort(true)}>
            <span className="caption">{buildLocale(currentTranslate, 'filtersSortTitle')}</span>
          </div>
        )}
        {isOpenSort && !hideSort && <SortComponentMobile isOpen={isOpenSort} setIsOpen={setIsOpenSort} />}
      </div>
      <div className="mobile-filter_search">
        <input
          type="text"
          className="form-control"
          placeholder={buildLocale(currentTranslate, 'searchFilterPlaceholder')}
          onChange={(e) => handleSearchFilter(e.target.value)}
          value={currentSearchFilter || ''}
        />
        {currentSearchFilter && (
          <span className="clear" style={{ display: 'flex' }} onClick={() => handleSearchFilter(null)}>
            ✕
          </span>
        )}
      </div>
    </>
  );
};

export default FiltersMobile;
