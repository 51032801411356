import axios from 'axios';
import config18n from '../assets/i18n/config';
import config from '../config';
import { isBrowser } from '../utils/isBrowser';

const Cookies = isBrowser && require('cookies-js');

const createInstance = () => {
  const instance = axios.create({
    baseURL: config.baseURL,
    //withCredentials: true
  });

  instance.interceptors.request.use(
    (value: any) => {
      if (isBrowser && value.headers) {
        const { firebaseAuthorization = null } = value.headers;

        if (firebaseAuthorization) {
          value.headers = {
            'Accept-Language': Cookies.get('locale') || config18n.defaultLocale,
            Authorization: firebaseAuthorization,
          };
        } else {
          value.headers = {
            'Accept-Language': Cookies.get('locale') || config18n.defaultLocale,
          };
        }
      }
      if (value.contentType) {
        value.headers = {
          ...value.headers,
          'content-type': 'application/json',
        };
      }

      value.headers = {
        ...value.headers,
        'Platform-OS': 'Web',
        'x-client-version': config.appVersion,
        'x-shop-id': config.shopId,
      };

      return value;
    },
    (error) => {
      throw error;
    },
  );

  instance.interceptors.response.use(
    (value) => {
      return value;
    },
    (error) => {
      if (!(error.response && error.response.data && error.response.data.message)) {
        error.response = {
          data: {
            message: error.message,
          },
        };
      }
      throw error;
    },
  );

  return instance;
};

const instance = createInstance();
export { createInstance };
export default instance;
