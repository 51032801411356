export const buildLocale = (currentLocale: any, title: string, params?: { [key: string]: string | number }) => {
  if (!currentLocale) {
    return title;
  }
  let value = currentLocale[title];
  if (!value) {
    return title;
  }
  if (!params) {
    return value;
  }
  for (const [key, replacement] of Object.entries(params)) {
    value = value.replaceAll(key, replacement);
  }
  return value;
};

export const isHaveLocale = (currentLocale: any, title: string) => {
  return currentLocale && currentLocale[title];
};
