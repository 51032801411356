import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchImpersonate, fetchLogout } from '../redux/user/userReducer';
import { usePaths } from '../components/Routes/RouterList';
import service from '../services/service';

import { actionsCart } from '../redux/cart/cartReducer';
import { actionsFilters } from '../redux/filters/filtersReducer';
import { actionsOrder } from '../redux/order/orderReducer';
import { TypeDelivery } from '../constants/constantsId';
import { useLessThen991 } from '../utils/mediaQuery';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import { useNavigate } from 'react-router';
import { selectorsOther } from '../redux/other/otherReducer';

const ImpersonatePage = (props: any) => {
  const navigate = useNavigate();
  const path = usePaths();
  const dispatch: any = useDispatch();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const zipCode = useSelector(selectorsDelivery.getZipCode);

  useEffect(() => {
    dispatch(actionsFilters.clearAllSelected());
    const queryParams = window && new URLSearchParams(window.location.search);
    if (queryParams.get('client_id') && queryParams.get('token_hash')) {
      const dispatchLogout = async () => dispatch(fetchLogout);
      dispatchLogout()
        .then(() => {
          const res: any = dispatch(fetchImpersonate(queryParams.get('client_id')!, queryParams.get('token_hash')!));
          const dispatchImpersonate = async () => await res;
          dispatchImpersonate()
            .then(() => {
              service.getCart(zipCode).then((value) => {
                navigate(path.home);
                const { items } = value;
                if (items && items.length) {
                  const {
                    sellPoint,
                    deliveryType,
                    total,
                    bottleTotalDeposit,
                    deliveryCost,
                    rackTotalDeposit,
                    totalWeightDeposit,
                    notAvailableProducts,
                    productsPrice,
                  } = value;
                  dispatch(actionsCart.updateCartProducts(items));
                  dispatch(actionsCart.setData(items));
                  dispatch(
                    actionsCart.setPrices({
                      total,
                      bottleTotalDeposit,
                      deliveryCost,
                      rackTotalDeposit,
                      totalWeightDeposit,
                      productsPrice,
                    }),
                  );
                  if (notAvailableProducts) {
                    dispatch(actionsCart.setNotAvailableProducts(notAvailableProducts));
                  }

                  dispatch(actionsCart.updateCart(sellPoint.id));
                  dispatch(
                    actionsOrder.setData({
                      deliveryType,
                      sellPoint: deliveryType.code === TypeDelivery.courier ? null : +sellPoint.id,
                    }),
                  );
                  if (!isLess991) {
                    navigate(path['cart-list']);
                    return;
                  }
                  dispatch(actionsCart.toggleCart(true));
                } else {
                  navigate(path.home);
                }
              });
            })
            .catch(() => navigate(path.home));
        })
        .catch(() => navigate(path.home));
    } else {
      navigate(path['login']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <section></section>;
};

export default ImpersonatePage;
