import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import CommonHelmet from '../components/common/CommonHelmet';
import BreadcrumbsStaticPage from './common/BreadcrumbsStaticPage';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';
import { useLocation } from 'react-router-dom';
import { useNavigate, useNavigationType } from 'react-router';
import GoBackBtn from '../components/CategoriesMobile/GoBackBtn';
import { selectorsOther } from '../redux/other/otherReducer';
import { useLessThen991 } from '../utils/mediaQuery';

const FAQPage = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const currentHTMLTranslate = useSelector(selectorsLocale.getHTMLTranslate);
  const navigationType = useNavigationType();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);

  const location = useLocation();
  const navigate = useNavigate();

  const backBtn = useMemo(() => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return '';
    }
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={currentTranslate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <section>
      <CommonHelmet title={`${buildLocale(currentTranslate, 'footerFaq')} | MyBio.de`}></CommonHelmet>
      {backBtn}
      <div>
        <BreadcrumbsStaticPage lastItemName="FAQ" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <section className="faq-content">
              <h1>
                FAQ <span className="number"></span>
              </h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: buildLocale(currentHTMLTranslate, 'faq.text'),
                }}></div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQPage;
