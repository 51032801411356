export const searchingTags = [
  'eier',
  'tofu',
  'brot',
  'milch',
  'wurst',
  'käse',
  'butter',
  'pizza',
  'tomaten',
  'chips',
  'salat',
  'sahne',
];
