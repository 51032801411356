import config18n from '../assets/i18n/config';
import { isDev } from './isEnv';

const getLocale = (state: any) => {
  let locale = 'de';

  if (isDev) {
    let path = window.location.href.replace(window.location.origin, '');
    if (path.startsWith('/')) {
      path = path.replace('/', '');
    }
    locale = path.substr(0, 2) || 'de';
    if (!config18n.locales.some((l) => l === locale)) {
      locale = 'de';
    }
  } else {
    locale = state.other.locale! || 'de';
  }

  return locale;
};

export default getLocale;
