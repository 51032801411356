import React from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';

interface IShipmentModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const ShipmentModal: React.FC<IShipmentModalProps> = React.memo(({ isOpen, closeModal }) => {
  const currentHTMLTranslate = useSelector(selectorsLocale.getHTMLTranslate);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Example Modal"
      className={`edit_modal info_modal`}
      overlayClassName="edit_overlay payment_overlay">
      <div className="modal-dialog modal-shipment">
        <div className="modal-content">
          <span className="modal-close" onClick={() => closeModal()}>
            &nbsp;
          </span>
          <div className="modal-body">
            <div
              className="faq-item active"
              dangerouslySetInnerHTML={{
                __html: buildLocale(currentHTMLTranslate, 'shipment.modal.text'),
              }}></div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ShipmentModal;
