import { IPaymentType } from '../../typings/IPaymentType';
import { IDeliveryType } from '../../typings/IDeliveryType';
import { IAddressRedux } from '../../typings/CustomTypes/IAddressRedux';

export interface IOrderState {
  deliveryType: IDeliveryType | null;
  paymentType: IPaymentType | null;
  step: number;
  contact: IReceiver | null;
  sellPoint: number | null;
  isCallBack: boolean;
  date: Date | null;
  time: string;
  anonymousMessage: string | null;
  addressId: number | null;
  commentOrder: string | null;
  address: IAddressRedux | null;
  numberOrder: number | null;
  idDeliveryPrice: number | null;
  statusPayment: StatusPayment;
  draftId: number | null;
  expiredTime: number | null;
  deliveryCity: {
    label: string;
    subtitle: string;
    value: number;
    disable: boolean;
  } | null;
  fromPrevStep?: boolean;
  deliveryPrice?: number | null;
  deliveryPriceOrderId: number | null;
  couponId: null;
  couponInfo: any;
  contactId?: number;
  addressDraft: any;
  freeBag: boolean;
}

export enum StatusPayment {
  defult,
  online,
  payment,
  error,
}
export interface IOrderActions {
  setData: (o: Partial<IOrderState>) => any;
  addContact: (data: IReceiver) => any;
  removeContact: () => any;
  updateContact: (data: IReceiver) => any;
  addPaymentCard: (data: Omit<IPaymentCard, 'id'>) => any;
  addPaymentCards: (data: IPaymentCard[]) => any;
  setExpiredTime: (date: Date) => any;
  checkLast: () => any;
  clear: () => any;
  setDeliveryPrice: (price: number) => any;
  setDeliveryPriceId: (id: number) => any;
  setCouponId: (id: number | null) => any;
  setCoupon: (coupon: any) => any;
  changeAddressDraft: (address: any) => void;
  setFreeBag: (value: boolean) => any;
}

export interface IReceiver {
  id: number | null;
  name: string;
  phone: string;
  isPhoneCustom: boolean;
  email?: string;
  lastName?: string;
}
export interface IPaymentCard {
  id: number;
  number: string;
  month: string;
  year: string;
  cvv: string;
}
