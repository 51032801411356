import { CreatorReducer } from '../base/base';
import { RootState } from '../reducer';
import { IHeaderActions, IHeaderState } from './headerTypes';

const init: IHeaderState = {
  ownCategories: [],
};

const creator = new CreatorReducer<IHeaderActions, IHeaderState>('header');
creator.addAction('addCategory', (state, action) => {
  return { ...state, ownCategories: [...state.ownCategories, action.payload] };
});

const actionsHeader = creator.createActions();
const selectorsHeader = {
  getOwnCategories: (state: RootState) => state.header.ownCategories,
};

export { actionsHeader, selectorsHeader };
export default creator.createReducer(init);
