import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import service from '../../services/service';
import { IProduct } from '../../typings/IProduct';
import { ITag } from '../../typings/ITag';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';
import { usePaths } from '../../components/Routes/RouterList';
import ProductLoader from '../../components/Loaders/ProductLoader';
import Product from '../../components/Products/Product';
import ProductCard from '../../components/Modals/ProductCard';
import { selectorsCategory } from '../../redux/category/categoryReducer';
import { ICustomCategory } from '../../typings/ICategory';
import { CustomCategorySearch } from '../../typings/CustomCategorySearch';

type SearchingResult = {
  customCategories: CustomCategorySearch[];
  items: IProduct[];
  productGroups: { id: number; name: string }[];
  count: number;
};

interface AdvancedSearchMobileModalProps {
  trimmedSearchField: string;
}

const AdvancedSearchMobileModal = (props: AdvancedSearchMobileModalProps) => {
  const { trimmedSearchField } = props;
  const paths = usePaths();
  const TOP_VALUE_FOR_SEARCH = 10;
  const ADVANCED_SEARCH_MIN_LENGTH = 2;

  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const availableTreeCategories = useSelector(selectorsCategory.getCategories);

  const [products, setProducts] = useState<IProduct[]>([]);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [tags, setTags] = useState<ITag[]>([]);
  const [categories, setCategories] = useState<CustomCategorySearch[]>([]);
  const [isHasMore, setIsHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [productCardPopupData, setProductCardPopupData] = useState<{
    isActive: boolean;
    item: IProduct | null;
  }>({ isActive: false, item: null });

  const getParentCategory = (childCategoryId?: number) => {
    if (!childCategoryId) {
      return null;
    }
    if (!availableTreeCategories[childCategoryId]) {
      return null;
    }
    let treeForChildCategory = [availableTreeCategories[childCategoryId]];
    let parent = treeForChildCategory[0].parent;

    while (parent !== null && parent !== undefined) {
      const category = availableTreeCategories[parent.id];
      if (!category) {
        return null;
      }
      treeForChildCategory.unshift(category);
      parent = category.parent;
    }

    if (treeForChildCategory?.length > 1) {
      treeForChildCategory = treeForChildCategory.filter(
        (category: ICustomCategory) => category.id !== childCategoryId,
      );
    }
    return treeForChildCategory.pop();
  };

  const extendSameNamesWithParentCategory = (categories: CustomCategorySearch[], duplicates: string[]) => {
    for (const category of categories) {
      if (!duplicates.includes(category.name)) {
        continue;
      }
      const parentCategory = getParentCategory(category.id);
      if (!parentCategory) {
        continue;
      }
      category.parentName = parentCategory.name;
    }
  };

  const tryToExtendSameNamesWithParentCategory = (categories: CustomCategorySearch[]) => {
    const currentCategoryNames = categories.map((category: CustomCategorySearch) => category.name);
    const duplicates = currentCategoryNames.filter(
      (itemName, index) => currentCategoryNames.indexOf(itemName) !== index,
    );
    if (duplicates?.length) {
      extendSameNamesWithParentCategory(categories, duplicates);
    }

    setCategories((prevCategories) => [...prevCategories, ...categories]);
  };

  const searchProducts = (signal: AbortSignal, text: string) => {
    if (text?.length < ADVANCED_SEARCH_MIN_LENGTH) {
      setProducts([]);
      setTags([]);
      setCategories([]);
      return;
    }
    setIsLoading(true);
    service
      .searchProductsAdvanced(signal, text, TOP_VALUE_FOR_SEARCH, 0, isInCourierArea)
      .then((res: any) => {
        if (signal.aborted) {
          return;
        }

        const data = res?.data || ({} as SearchingResult);
        const tags = data?.productGroups?.length ? data.productGroups : [];
        const products = data?.items?.length ? data.items : [];
        const categories = data?.customCategories?.length ? data.customCategories : [];
        const productsCount = data?.count || 0;

        if (Number(data?.count) < TOP_VALUE_FOR_SEARCH) {
          setIsHasMore(false);
        }
        setProductsCount(productsCount);
        setTags((prevTags) => [...prevTags, ...tags]);
        setProducts((prevProducts) => [...prevProducts, ...products]);
        tryToExtendSameNamesWithParentCategory(categories);
        setIsLoading(false);
      })
      .catch((e: any) => setIsLoading(false));
  };

  useEffect(() => {
    setProducts([]);
    setTags([]);
    setCategories([]);

    const abortController = new AbortController();
    const signal = abortController.signal;
    const delayDebounceFn = setTimeout(() => {
      searchProducts(signal, trimmedSearchField);
    }, 500);

    return () => {
      abortController.abort();
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedSearchField]);

  return (
    <div className="search-hint" id="scrollableDiv">
      {isLoading && <ProductLoader />}
      {!isLoading && (
        <>
          {!!tags?.length && (
            <>
              <div className="search-panel">{buildLocale(currentTranslate, 'productsSearchProposedResults')}</div>
              <ul className="search-tag-mobile_list">
                {tags.map((tag: ITag) => (
                  <li key={tag.id}>
                    <a
                      onClick={() => setIsLoading(true)}
                      className="search-tag-mobile"
                      href={`${paths.searching}?search=${tag.name}`}>
                      {tag.name}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
          {!!products?.length && (
            <>
              <div className="search-panel">{buildLocale(currentTranslate, 'productsSearchProductsFound')}</div>
              <div className="infinite-scroll-component">
                {products.map((productItem: any) => (
                  <div key={productItem.id} className="col-6 col-md-4 col-lg-3 col-xl-2 catalog-item">
                    <Product
                      product={productItem}
                      onProductImageClick={(product) =>
                        setProductCardPopupData({
                          isActive: true,
                          item: product,
                        })
                      }
                    />
                  </div>
                ))}
              </div>
              {isHasMore && (
                <div className="search-panel-show-all">
                  <a
                    onClick={() => setIsLoading(true)}
                    className="btn btn-buy show-all"
                    href={`${paths.searching}?search=${trimmedSearchField}`}>
                    {buildLocale(currentTranslate, 'productsSearchShowAllProducts')} ({productsCount})
                  </a>
                </div>
              )}
            </>
          )}
          {!!categories?.length && (
            <>
              <div className="search-panel">{buildLocale(currentTranslate, 'productsSearchCategoriesFound')}</div>
              <ul>
                {categories.map((categoryItem: CustomCategorySearch) => (
                  <li key={categoryItem.id} className="search-tag-mobile_categories">
                    <a
                      onClick={() => setIsLoading(true)}
                      className="search-hint_description product-title-item"
                      href={`${paths.products(categoryItem.id)}?searchFilter=${encodeURIComponent(trimmedSearchField)}`}>
                      {!!categoryItem?.parentName?.length && (
                        <span className="parent-category">
                          {categoryItem.parentName}
                          {' > '}
                        </span>
                      )}
                      {categoryItem.name} <span className="weight-unit">{categoryItem.count}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
          {productCardPopupData.isActive && (
            <ProductCard
              isOpen={productCardPopupData.isActive}
              id={productCardPopupData?.item?.id || 0}
              onProductCardClose={() => setProductCardPopupData({ isActive: true, item: null })}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AdvancedSearchMobileModal;
