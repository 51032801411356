import React, { useEffect, useRef, useState } from 'react';
import { IBaseCategory } from '../../typings/ICategory';
import service from '../../services/service';
import { useLocation, useParams } from 'react-router-dom';
import CategoryImage from '../ImagesComponent/CategoryImage';
import CategoryChildren from './CategoryChildren';
import { usePaths } from '../Routes/RouterList';
import { useDispatch, useSelector } from 'react-redux';
import { actionsOwnCategories } from '../../redux/ownCategories/ownCategoriesReducer';
import { actionsHeader, selectorsHeader } from '../../redux/header/headerReducer';
import { actionsFilters } from '../../redux/filters/filtersReducer';
import { selectorsCategory } from '../../redux/category/categoryReducer';
import { isIdPresent } from '../../utils/isIdPresent';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';
import { useNavigate } from 'react-router';

interface IProps {
  category: IBaseCategory;
}

const CategoryItem = (props: IProps) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [categories, setCategories] = useState<IBaseCategory[]>([]);
  const ownCategories = useSelector(selectorsHeader.getOwnCategories);
  const { category } = props;
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const paths = usePaths();
  const params: any = useParams();
  const location = useLocation();
  const locationId = location.pathname.substring(location.pathname.includes('own_categories') ? 16 : 10);
  const id = params.id || params['category'] || locationId;
  const chainsCategory = useSelector(selectorsCategory.getChainsCategories(category.id));
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const MENU_DROPDOWN_DELAY_FOCUS = 400;
  const MENU_DROPDOWN_DELAY_LOST_FOCUS = 550;

  const timeoutFocusRef = useRef<any>(null);
  const timeoutUnfocusRef = useRef<any>(null);
  const itemRef = useRef<any>(null);

  const getChildren = (id: number) => {
    const currentCategory = ownCategories.find((c) => c.id === id);
    if (currentCategory) {
      setCategories(currentCategory.category);
      return;
    }
    service.getChildrenCategories(id, isInCourierArea).then((value: any) => {
      dispatch(actionsHeader.addCategory({ id, category: value.data }));
      setCategories(value.data);
    });
  };

  const resetLimit = () => {
    dispatch(actionsOwnCategories.resetLimit());
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutUnfocusRef.current);
    timeoutFocusRef.current = setTimeout(() => {
      setIsShowPopup(true);
      itemRef.current.className = itemRef.current.className + ' active';
      getChildren(category.id);
    }, MENU_DROPDOWN_DELAY_FOCUS);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutFocusRef.current);
    timeoutUnfocusRef.current = setTimeout(() => {
      setIsShowPopup(false);
      itemRef.current.className = itemRef.current.className.replace(' active', '');
      setCategories([]);
    }, MENU_DROPDOWN_DELAY_LOST_FOCUS);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutFocusRef.current);
      clearTimeout(timeoutUnfocusRef.current);
    };
  }, []);

  return (
    <li onMouseLeave={handleMouseLeave}>
      <div onMouseEnter={handleMouseEnter} className="catalog-popup-item-li" ref={itemRef}>
        <a
          href={category.hasChildren ? paths.own_categories(category.id) : `/products/${category.id}`}
          className="category-main_title"
          onClick={(e) => {
            e.preventDefault();
            if (!isIdPresent(chainsCategory[0], +id)) {
              dispatch(actionsFilters.clearAllSelected());
            }
            handleMouseLeave();
            resetLimit();
            navigate(category.hasChildren ? paths.own_categories(category.id) : `/products/${category.id}`);
          }}>
          {category.name}
        </a>
        {isShowPopup && categories && categories.length > 0 && (
          <div className="category-popup">
            {categories.map((c: any) => (
              <div className="category-popup_category" key={c.id}>
                <figure className="category-popup_pic">
                  <a
                    href={`/products/${c.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isIdPresent(chainsCategory[0], +id)) {
                        dispatch(actionsFilters.clearAllSelected());
                      }
                      handleMouseLeave();
                      setIsShowPopup(false);
                      navigate(`/products/${c.id}`);
                    }}>
                    <CategoryImage category={c} />
                  </a>
                </figure>
                <div className="category-popup_content">
                  <p className="category-popup_title">
                    <a
                      href={`/products/${c.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isIdPresent(chainsCategory[0], +id)) {
                          dispatch(actionsFilters.clearAllSelected());
                        }
                        handleMouseLeave();
                        setIsShowPopup(false);
                        navigate(`/products/${c.id}`);
                      }}>
                      {c.name}
                    </a>
                  </p>
                  {c.children && c.children.length > 0 && (
                    <CategoryChildren
                      handleMouseLeave={handleMouseLeave}
                      closePopup={() => setIsShowPopup(false)}
                      children={c.children.filter((child: any) => child.isActive)}
                      category={c}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </li>
  );
};

export default CategoryItem;
