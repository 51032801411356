import React from 'react';
import Modal from 'react-modal';

interface IInfoModalProps {
  title: string;
  onSubmit: any;
  submitTitle: string;
  className?: string;
  isOpen: boolean;
  toggle: () => any;
  children: any;
}

const InfoModal: React.FC<IInfoModalProps> = React.memo(
  ({ children, title, className, onSubmit, submitTitle, isOpen, toggle }) => {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={toggle}
        contentLabel="Example Modal"
        className={`edit_modal info_modal`}
        overlayClassName="edit_overlay payment_overlay">
        <div className="modal-dialog modal_add-card">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{title}</h4>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
              <button onClick={onSubmit} className="btn">
                {submitTitle}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

export default InfoModal;
