export enum SmallUnits {
  g = 'g',
  ml = 'ml',
}

export enum Units {
  kg = 'kg',
  l = 'l',
}

const smallUnitsArray = [SmallUnits.g, SmallUnits.ml];

export const getUnitType = (type?: string) => {
  switch (type) {
    case SmallUnits.g: {
      return Units.kg;
    }
    case SmallUnits.ml: {
      return Units.l;
    }
    default:
      return type || '';
  }
};

export const isSmallUnit = (type: string | undefined) => {
  return smallUnitsArray.includes(type as SmallUnits);
};

export const calculateWeight = (type: string | undefined, weight?: string) => {
  if (!weight) {
    return 1;
  }
  if (!type) {
    return +weight;
  }
  return isSmallUnit(type) ? +weight / 1000 : +weight;
};

export const calculateWeightWithCount = (type: string | undefined, count: string, weight?: string) => {
  return (count ? +count : 1) * calculateWeight(type, weight);
};

export const calculateWeightablePriceWithCount = (
  price: number,
  type: string | undefined,
  count: string,
  weight?: string,
) => {
  return price * calculateWeightWithCount(type, count, weight);
};

export const calculatePrice = (type: string | undefined, quantityPerPack: string) => {
  if (!type) {
    return +quantityPerPack;
  }
  return isSmallUnit(type) ? +quantityPerPack / 1000 : +quantityPerPack;
};

export const calculatePriceWithCount = (type: string | undefined, count: string, quantityPerPack: string) => {
  return (count ? +count : 1) * calculatePrice(type, quantityPerPack);
};

export const formatWeightWithType = (type: string | undefined, count: string, weight?: string) => {
  return `${calculateWeightWithCount(type, count, weight)} ${getUnitType(type)}`;
};

export const calculateFullPrice = (price: number, quantityPerPack: string, type: string | undefined) => {
  return (price / +quantityPerPack) * (isSmallUnit(type) ? 1000 : 1);
};

export const weightableUnit = (isWeightable: boolean | undefined, type: string | undefined) => {
  return isWeightable ? `/${getUnitType(type)}` : '';
};

export const getCapacity = (quantity: string | undefined, unit: string | undefined) => {
  if (!quantity || !unit) {
    return null;
  }

  return `${quantity} ${unit}`;
};
