import { buildLocale } from './buildLocale';

export function getDateTimeDuration(diffInSeconds: number = 0, currentTranslate: any): string {
  const diffInMinutes = diffInSeconds / 60;
  if (Math.floor(diffInMinutes) < 60) {
    return `${buildLocale(currentTranslate, 'saleEndDateStillMinutes', { '{{MINUTES}}': Math.floor(diffInMinutes) })}`;
  }

  const minutes = Math.floor(diffInMinutes % 60);
  const hours = Math.floor((diffInMinutes / 60) % 24);
  const days = Math.floor(diffInMinutes / 1440);

  if (days) {
    const i18nKey = days > 1 ? 'saleEndDateStillDays' : 'saleEndDateStillDay';
    return `${buildLocale(currentTranslate, i18nKey, { '{{DAYS}}': days })}`;
  }
  if (hours) {
    return `${buildLocale(currentTranslate, 'saleEndDateStillHours', { '{{HOURS}}': hours })}`;
  }
  return `${buildLocale(currentTranslate, 'saleEndDateStillMinutes', { '{{MINUTES}}': minutes })}`;
}
