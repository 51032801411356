import React from 'react';
import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';
import { useDispatch, useSelector } from 'react-redux';
import CountInput from '../Forms/Components/CountInput';
import { ICartItem } from '../../typings/ICartItem';
import { ID_UNIT_WEIGHT } from '../../constants/constantsId';
import { DL_helper } from '../../utils/DataLayer/DL_helper';

interface ICartCountItemProps {
  item: ICartItem;
}
const CartCountItem = React.memo(({ item }: ICartCountItemProps) => {
  const dispatch = useDispatch<any>();
  const { product } = item;
  const isWeight = product.units === ID_UNIT_WEIGHT;
  const cart = useSelector(selectorsCart.getCartProducts);

  const onChange = (count) => {
    if (count === 0) {
      dispatch(actionsCart.removeProduct(product.id));
    }
    if (!item.alternativeCount) {
      dispatch(
        actionsCart.changeCount({
          count,
          id: product.id,
        }),
      );
    } else {
      dispatch(
        actionsCart.changeCount({
          count: count * +item.product.avgWeight!,
          id: product.id,
        }),
      );
      dispatch(
        actionsCart.changeAlternativeCount({
          count,
          id: product.id,
        }),
      );
    }
    if (count === 0) {
      DL_helper(cart, 0, 'removeFromCart', product);
    } else {
      DL_helper(cart, count, 'productChangeCount', product);
    }
  };

  const count = item.alternativeCount ? +item.alternativeCount : +item.count;

  return (
    <CountInput
      isEditable={true}
      isWeightUnit={item.alternativeCount !== null ? false : isWeight}
      onChange={onChange}
      value={count}
      isRemove={true}
    />
  );
});

export default CartCountItem;
