import React from 'react';
import Carousel from 'react-multi-carousel';
import { carouselBanner } from '../../constants/configCarousel';
import { IProduct } from '../../typings/IProduct';
import { isBrowser } from '../../utils/isBrowser';
import ProductImage from '../ImagesComponent/ProductImage';
import { CustomDot } from './SliderProducts';

interface IProps {
  product: IProduct;
}

const ProductImagesSlider = (props: IProps) => {
  const { product } = props;

  if (!product.cdnImages || product.cdnImages.length === 0) {
    return <ProductImage product={product} isDefaultImg={true} />;
  }

  return (
    <div className="product-pic_slider">
      <Carousel
        autoPlay={false}
        ssr={!isBrowser}
        responsive={carouselBanner}
        focusOnSelect={false}
        customDot={<CustomDot />}
        shouldResetAutoplay={false}
        swipeable={true}
        draggable={true}
        arrows={false}
        showDots={true}
        deviceType="desktop">
        {product.cdnImages.map((img, index) => (
          <ProductImage product={product} imageIndex={index} key={img.id} size={'300'} />
        ))}
      </Carousel>
    </div>
  );
};

export default ProductImagesSlider;
