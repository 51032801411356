import React from 'react';
import { useSelector } from 'react-redux';
import { selectorsFilters } from '../../../../../../redux/filters/filtersReducer';
import { FILTERS_TYPE, IMainFilters } from '../../../../../../typings/IFilters';
//NOTE: mock type
interface IProps {
  filter: IMainFilters;
  selectFilter: (filter: IMainFilters) => void;
}
const FiltersMainItem = (props: IProps) => {
  const { filter, selectFilter } = props;
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const setItemsByType = () => {
    switch (filter.type) {
      case FILTERS_TYPE.BRANDS: {
        return selectedBrands.map((s) => s.nameDe);
      }
      case FILTERS_TYPE.BADGES: {
        return selectedBadges.map((s) => s.nameDe);
      }
      case FILTERS_TYPE.ALLERGICH: {
        return selectedAllergich.map((s) => s.nameDe);
      }
      case FILTERS_TYPE.NUTRION: {
        return selectedNutrion.map((s) => s.nameDe);
      }
      default:
        return [];
    }
  };
  return (
    <div className="mobile-filter_item" onClick={() => selectFilter(filter)}>
      <span className="filter-name">{filter.title}</span>
      {setItemsByType().length > 0 && <span className="filter-selected">{setItemsByType().join(', ')}</span>}
    </div>
  );
};
export default FiltersMainItem;
