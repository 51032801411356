import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { card, draftId, method, paypal, DATA_STEP, credits } from '../../../constants/payData';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import service from '../../../services/service';
import { buildLocale } from '../../../utils/buildLocale';
import { getLocaleStorageItem } from '../../../utils/localStorageHelper';
import { CheckoutPage } from '../../BasketPage/Components/PaymentComponent';
import PaypalComponent from '../../BasketPage/Components/PayPalComponent';
import { useFormattingContext } from '../../../context/FormattingContext';
import HeaderLogo from '../../common/HeaderLogo';
import PayPalProvider from '../../common/PayPalProvider';

interface IProps {
  createOrder: () => void;
  draftData: any;
  setPayStep: (value: string) => void;
  resetChecked: () => void;
}

const PaymentStep = (props: IProps) => {
  const { createOrder, draftData, setPayStep, resetChecked } = props;
  const location = useLocation();
  const payType = getLocaleStorageItem(method);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [clientToken, setClientToken] = useState('');
  const { formatPrice } = useFormattingContext();

  useEffect(() => {
    if (payType === card) {
      getClientToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClientToken = () => {
    const queryParams = location && new URLSearchParams(location.search);
    if (
      !queryParams.get('payment_intent') &&
      !queryParams.get('payment_intent_client_secret') &&
      !queryParams.get('redirect_status')
    ) {
      const data = {
        orderId: getLocaleStorageItem(draftId),
      };

      service
        .getClientPaymentToken(data)
        .then((value: any) => setClientToken(value.data.data))
        .catch((e: any) => console.log('ERROR'));
    }
  };

  if (payType === paypal) {
    return (
      <PayPalProvider>
        <PaypalComponent
          price={draftData.totalPrice}
          createOrder={createOrder}
          resetChecked={resetChecked}
          setPayStep={setPayStep}
        />
      </PayPalProvider>
    );
  }
  return (
    <section className="pay-page">
      <header className="pay-page_header">
        <HeaderLogo />
        <span
          className="pay-page_close"
          onClick={() => {
            resetChecked();
            setPayStep(DATA_STEP);
          }}></span>
      </header>
      <div className="container">
        <div className="pay-page_top">
          <h1>{buildLocale(currentTranslate, 'payPagePayment')}</h1>
          <div className="pay-page_top-total">
            <span>{buildLocale(currentTranslate, 'mobileCouponAccepted')}</span>{' '}
            {(draftData.coupon || getLocaleStorageItem(credits)) && (
              <span className="price price-prev">
                {formatPrice(
                  +draftData.productsPrice +
                    (draftData.rackTotalDeposit ? +draftData.rackTotalDeposit : 0) +
                    (draftData.deliveryCost ? draftData.deliveryCost : 0) +
                    (draftData.bottleTotalDeposit ? +draftData.bottleTotalDeposit : 0) +
                    (draftData.totalWeightDeposit ? +draftData.totalWeightDeposit : 0) +
                    (draftData.totalSecurityDeposit ? +draftData.totalSecurityDeposit : 0),
                )}
              </span>
            )}
            <span className="price">{formatPrice(draftData.totalPrice)}</span>
          </div>
        </div>
        <div className="pay-block">
          <div className="pay-block_one pay-block_card">
            {clientToken && <CheckoutPage clientSecret={clientToken} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentStep;
