import React from 'react';
import SortComponentDesktop from './components/desktop/SortComponentDesktop';
import FiltersComponentDesktop from './components/desktop/FiltersComponentDesktop';
import SelectedDesktopFilters from './components/SelectedDesktopFilters';
import { IUseApiQuery } from '../../../typings/IUseApiQuery';

interface IProps {
  isLoading: boolean;
  sortProducts?: (sort: boolean | undefined) => void;
  hideSort?: boolean;
  brandsQuery: IUseApiQuery;
  badgesQuery: IUseApiQuery;
  attributesQuery: IUseApiQuery;
}

const FiltersDesktop = (props: IProps) => {
  const { isLoading, sortProducts, hideSort, brandsQuery, badgesQuery, attributesQuery } = props;

  return (
    <div className="catalog-filter-sort">
      <div className="container">
        <FiltersComponentDesktop
          isLoading={isLoading}
          brandsQuery={brandsQuery}
          badgesQuery={badgesQuery}
          attributesQuery={attributesQuery}
        />
        {!hideSort && <SortComponentDesktop sortProducts={sortProducts} />}
      </div>
      <SelectedDesktopFilters />
    </div>
  );
};

export default FiltersDesktop;
