import { buildLocale } from './buildLocale';

export function getDateTimeDurationWithCountdown(diffInSeconds: number = 0, currentTranslate: any): string {
  const seconds = Math.floor(diffInSeconds % 60);
  const minutes = Math.floor((diffInSeconds / 60) % 60);
  const hours = Math.floor((diffInSeconds / 3600) % 24);

  const hoursValue = String(hours).length > 1 ? hours : `0${hours}`;
  const minutesValue = String(minutes).length > 1 ? minutes : `0${minutes}`;
  const secondsValue = String(seconds).length > 1 ? seconds : `0${seconds}`;

  if (hours) {
    return `${buildLocale(currentTranslate, 'saleEndDateStillHours', { '{{HOURS}}': `${hoursValue}:${minutesValue}:${secondsValue}` })}`;
  }
  return `${buildLocale(currentTranslate, 'saleEndDateStillMinutes', { '{{MINUTES}}': `${hoursValue}:${minutesValue}:${secondsValue}` })}`;
}
