import React from 'react';
import priceRangeBadgeBg from '../../assets/images/mengenrabatt-badge-bg.svg';
import priceRangeBadgeSm from '../../assets/images/mengenrabatt-badge-sm.svg';
import ProductImage from '../ImagesComponent/ProductImage';
import { IProduct } from '../../typings/IProduct';
import { IBioBadgeItem } from '../../typings/IBioBadges';

interface IProps {
  product: IProduct;
  isBigBadge?: boolean;
}

const BadgesList = (props: IProps) => {
  const { product, isBigBadge } = props;
  const hasPriceRanges = product?.productOptions[0]?.hasPriceRanges;
  const badges: IBioBadgeItem[] = product?.organicBadges || [];
  const hasBadges = hasPriceRanges || !!badges.length;
  const isProductAvailable = !!product?.productOptions[0].availableAmount;

  if (!hasBadges) {
    return null;
  }

  return (
    <ul className="organic-items">
      {hasPriceRanges && isProductAvailable && (
        <>
          {!!isBigBadge && (
            <li key="priceRangeBadgeBg">
              <img src={priceRangeBadgeBg} alt="" width="50" height="50" />
            </li>
          )}
          {!isBigBadge && (
            <li key="priceRangeBadgeSm">
              <img src={priceRangeBadgeSm} alt="" width="38" height="38" />
            </li>
          )}
        </>
      )}
      {!!badges.length && (
        <>
          {badges.map((org: any) => (
            <li key={org.id}>
              <ProductImage key={org.id} product={{ ...org, shortDescription: '', cdnImages: [{ ...org.logo }] }} />
            </li>
          ))}
        </>
      )}
    </ul>
  );
};

export default BadgesList;
