import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducer';
import { thunk } from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { eventCatch } from '../context/EventReduxContext';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Sentry from '@sentry/react';
import config from '../config';

export default function configureStore(cookieStorage: any, ssrState: any = {}) {
  const middlewares = [thunk];

  if (typeof window !== 'undefined') {
    middlewares.push(eventCatch as any);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  if (config.isErrorReportingEnabled === 'true') {
    enhancers.push(
      Sentry.createReduxEnhancer({
        stateTransformer: (state) => {
          return {
            ...state,
            category: null,
            locale: null,
          };
        },
      }),
    );
  }

  const someCompose: any = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

  const store: any = createStore(reducers(cookieStorage), ssrState, someCompose(...enhancers));

  store.asyncReducers = {}; // Async reducer registry

  const persistor = persistStore(store);

  return { store, persistor };
}

export async function configureSsrStore(cookieStorage: any) {
  const middlewares = [thunk];

  if (typeof window !== 'undefined') {
    middlewares.push(eventCatch as any);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  const someCompose: any = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

  const store: any = createStore(reducers(cookieStorage), {}, someCompose(...enhancers));

  store.asyncReducers = {}; // Async reducer registry

  const persistor = persistStore(store);

  return { store, persistor };
}
