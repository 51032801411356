import React from 'react';
import { IProduct } from '../../typings/IProduct';
import { useFormattingContext } from '../../context/FormattingContext';
import getPriceWithoutVAT from '../../utils/getPriceWithoutVAT';
import { calculateFullPrice, getUnitType } from '../../utils/calculateUnits';

interface IProps {
  product: IProduct;
}

const ProductPriceBlockMetro = (props: IProps) => {
  const { product } = props;
  const { formatPrice } = useFormattingContext();
  const price = product.productOptions.length > 0 ? +product.productOptions[0].price : 0;
  const salePrice = product.productOptions.length > 0 ? product.productOptions[0].salePrice : null;
  const unit = product.unit;
  const quantityPerPack = product.quantityPerPack;
  const isWeightable = product.isWeightable;
  const formattedUnitType = getUnitType(unit?.type);
  const taxPercent = product?.taxCategory?.percent || 0;
  const priceWithoutVAT = getPriceWithoutVAT(price, taxPercent);
  const salePriceWithoutVAT = getPriceWithoutVAT(+salePrice!, taxPercent);

  return (
    <>
      {salePrice ? (
        <>
          <div className="price-block">
            <p className="price-sale">
              <span className="number">
                {formatPrice(priceWithoutVAT, false)} €{isWeightable ? `/${formattedUnitType}` : ''}
              </span>
              <span className="number-sale">
                {formatPrice(salePriceWithoutVAT, false)} €{isWeightable ? `/${formattedUnitType}` : ''}
              </span>
            </p>
          </div>
          <div className="item-food_price">
            <div className="packaging">
              <span className="number-unit">
                {isWeightable
                  ? `${formatPrice(+salePriceWithoutVAT, false)} €/${formattedUnitType}`
                  : `${formatPrice(
                      calculateFullPrice(+salePriceWithoutVAT, quantityPerPack, unit.type),
                      false,
                    )} €/${formattedUnitType}`}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="price-block">
            <span className="number">
              {formatPrice(priceWithoutVAT, false)} €{isWeightable ? `/${formattedUnitType}` : ''}
            </span>
          </div>
          <div className="item-food_price">
            <div className="packaging">
              <span className="number-unit">
                {isWeightable
                  ? `${formatPrice(+priceWithoutVAT, false)} €/${formattedUnitType}`
                  : `${formatPrice(
                      calculateFullPrice(priceWithoutVAT, quantityPerPack, unit.type),
                      false,
                    )} €/${formattedUnitType}`}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductPriceBlockMetro;
