import { Link, useLocation } from 'react-router-dom';
import { fetchLogout, selectorsUser } from '../../redux/user/userReducer';
import { useDropDown } from '../../useHooks/useDropDown';
import useProfileOptions from '../../useHooks/useProfileOptions';
import { IUser } from '../../typings/IUser';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePaths } from '../Routes/RouterList';
import LoginPopup from '../Modals/LoginPopup';
import { isBrowser } from '../../utils/isBrowser';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { actionsOther, selectorsOther } from '../../redux/other/otherReducer';
import useDidUpdateEffect from '../../useHooks/useDidUpdateEffect';
import { buildLocale } from '../../utils/buildLocale';
import { generateClassName } from '../../utils/generateClassName';
import { useFormattingContext } from '../../context/FormattingContext';

const broadcast = isBrowser && require('../../context/BroadcastContext').default;

interface IProps {
  user: IUser | null;
  onCloseMenu: any;
  handleClearCart: any;
}

const LoginButton = React.memo(({ user, onCloseMenu, handleClearCart }: IProps) => {
  const dispatch = useDispatch<any>();
  const paths = usePaths();
  const ProfileOptions = useProfileOptions();
  const location = useLocation();
  const { isShow, setIsShow } = useDropDown('dropdown-login-btn');
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const { isOpenLogin } = useSelector(selectorsOther.getOpenLoginData);
  const { formatPrice } = useFormattingContext();
  const currentUser = useSelector(selectorsUser.getDataUser);
  const creditsBalance = currentUser?.credits?.balance ? +currentUser?.credits?.balance : 0;

  const handleLogout = async () => {
    await broadcast.broadcastLogout();
    await dispatch(fetchLogout);
    handleClearCart();
  };
  useDidUpdateEffect(() => {
    onCloseMenu();
  }, [location]);

  return user === null ? (
    <div className="header-login">
      <span
        className="btn-login"
        onClick={() => dispatch(actionsOther.setOpenLoginData({ isOpenLogin: true, redirectLink: location.pathname }))}>
        <span>{buildLocale(currentTranslate, 'profileLogin')}</span>
      </span>
      {isOpenLogin && <LoginPopup linkToNavigateAfterLogin={location.pathname} />}
    </div>
  ) : (
    <div
      className={generateClassName('dropdown dropdown-login-btn authUserContainer', {
        'dropdown-open': isShow,
      })}
      onClick={() => setIsShow((s) => !s)}>
      <span className="dropdown-toggle header-user authUserBtn" data-toggle="dropdown">
        {!!user.firstName ? user.firstName : user.phone}
      </span>
      {isShow && (
        <div className="dropdown-menu user-popup">
          <div className="mobile-user_top">
            <span className="mobile-user_close"></span>

            <p className="user-popup_name">{user.firstName}</p>
            <p className="user-popup_name mobile">{buildLocale(currentTranslate, 'headerProfile')}</p>
            <div className="profile-aside_credits">
              {buildLocale(currentTranslate, 'creditsProfile')} :
              <span className="number">{formatPrice(creditsBalance)}</span>
              <div className="info-block">
                <span className="info">i</span>
                <div className="info-hover">{buildLocale(currentTranslate, 'creditsProfileInfo')}</div>
              </div>
            </div>
            {user.phone && <p className="user-popup_phone">{user.phone.replace(/^(\+)?/, '+')}</p>}
            <ul className="user-popup_menu">
              {Object.keys(ProfileOptions).map((key) => {
                if (ProfileOptions[key].isDisabled) {
                  return (
                    <li key={key}>
                      <span className="todo-disabled">{ProfileOptions[key].name}</span>
                    </li>
                  );
                }
                return (
                  <li key={key}>
                    <Link to={paths.profile(key)} className={`dropdown-item point-${key}`}>
                      {ProfileOptions[key].name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <ul className="mobile-user_static">
            <li>
              <Link to={paths.about_us}>{buildLocale(currentTranslate, 'footerAboutUs')}</Link>
            </li>
            <li>
              <Link to={paths.regional_products}>{buildLocale(currentTranslate, 'footerRegionalProducts')}</Link>
            </li>
            <li>
              <Link to={paths.regional_partners}>{buildLocale(currentTranslate, 'footerRegionalPartners')}</Link>
            </li>
            <li>
              <Link to={paths.brands}>{buildLocale(currentTranslate, 'brandsFooter')}</Link>
            </li>
            <li>
              <Link to={paths.bio_badges}>{buildLocale(currentTranslate, 'footerBioBadges')}</Link>
            </li>
            <li>
              <Link to={paths.delivery}>{buildLocale(currentTranslate, 'footerDelivery')}</Link>
            </li>
            <li>
              <Link to={paths.produktrueckrufe}>{buildLocale(currentTranslate, 'footerProduktrueckrufe')}</Link>
            </li>
            <li>
              <Link to={paths.presse}>{buildLocale(currentTranslate, 'footerPresse')}</Link>
            </li>
            <li>
              <Link to={paths.faq}>{buildLocale(currentTranslate, 'footerFaq')}</Link>
            </li>
            <li>
              <Link to={paths.agb}>{buildLocale(currentTranslate, 'footerAgb')}</Link>
            </li>
            <li>
              <Link to={paths.widerrufsrecht}>{buildLocale(currentTranslate, 'footerWiderrufsrecht')}</Link>
            </li>
            <li>
              <Link to={paths.confidentiality}>{buildLocale(currentTranslate, 'footerConfid')}</Link>
            </li>
            <li>
              <Link to={paths.impressum}>{buildLocale(currentTranslate, 'footerImpressum')}</Link>
            </li>
          </ul>

          <p className="user-popup_logout">
            <Link to={paths.main} type="button" onClick={handleLogout}>
              <span>{buildLocale(currentTranslate, 'btnExit')}</span>
            </Link>
          </p>
        </div>
      )}
    </div>
  );
});

export default LoginButton;
