import getDataLayer from './getDataLayer';
import { IProduct } from '../../typings/IProduct';
import useGetDataByProduct from './useGetDataByProduct';
import { useSelector } from 'react-redux';
import { selectorsOther } from '../../redux/other/otherReducer';
import { CONSTANTS_UNIT } from '../../context/FormattingContext';

type Items = {
  item_name: string;
  item_id: number;
  price: number;
  item_brand: string;
  category: string;
  quantity: number; //number of times product was viewing
}[];
const items: Items = [];

const useDataLayerViewItem = () => {
  const dataLayer = getDataLayer();
  const getDataByProduct = useGetDataByProduct();
  const currentLocale = useSelector(selectorsOther.getLocale)!;

  return (product: IProduct) => {
    const { brand, category, price } = getDataByProduct(product);
    const indexOfViewedProduct = items.findIndex(({ item_id }) => item_id === product.id);
    const productWasViewed = indexOfViewedProduct >= 0;

    if (productWasViewed) {
      items[indexOfViewedProduct].quantity += 1;
    } else {
      items.push({
        item_name: product.title,
        item_id: product.id,
        price,
        item_brand: brand,
        category,
        quantity: 1,
      });
    }

    dataLayer.push({
      event: 'view_item',
      ecommerce: {
        currency: CONSTANTS_UNIT[currentLocale].currency,
        items: [...items],
      },
    });
  };
};

export default useDataLayerViewItem;
