export const filterTitle = '&$attributeFilter=';

export const setAttributeRequest = (selectedAllergich: any, selectedNutrion: any) => {
  const allergich =
    selectedAllergich &&
    selectedAllergich.length > 0 &&
    selectedAllergich.map((a) => a.attributeValues.find((at: any) => at.nameEn === 'No').id);
  const nutrion =
    selectedNutrion &&
    selectedNutrion.length > 0 &&
    selectedNutrion.map((a) => a.attributeValues.find((at: any) => at.nameEn === 'Yes').id);
  const allergichRequest = allergich && `attribute_values @> '{${allergich.join(',')}}'`;
  const nutrionRequest = nutrion && `attribute_values && '{${nutrion.join(',')}}'`;
  if (allergich && nutrion) {
    return `${allergichRequest} and ${nutrionRequest}`;
  }
  if (allergich) {
    return `${allergichRequest}`;
  }
  if (nutrion) {
    return `${nutrionRequest}`;
  }
  return '';
};
