import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductLoader from '../components/Loaders/ProductLoader';
import Breadcrumbs from '../components/common/Breadcrumbs';
import GoBackBtn from '../components/CategoriesMobile/GoBackBtn';
import ProductCard from '../components/Modals/ProductCard';
import { blockBodyScroll } from '../utils/blockBodyScroll';
import Product from '../components/Products/Product';
import {
  getBannerBullets,
  getBannerHeight,
  getBannerImage,
  getBannerTitle,
  isHasBanner,
} from '../utils/bannerPrictures';
import FilterComponent from '../components/common/Filters/FilterComponent';
import { FILTER_TYPE } from '../typings/IFilters';
import { selectorsFilters } from '../redux/filters/filtersReducer';
import { useLessThen991 } from '../utils/mediaQuery';
import queries from '../services/queries';
import useApi from 'react-use-api';
import { handleData } from '../utils/handleData';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import CommonHelmet from '../components/common/CommonHelmet';
import config from '../config';
import { usePaths } from '../components/Routes/RouterList';
import { SeoTexts } from '../constants/seoTexts';
import useFilterDataFromSearchParams from '../useHooks/useFilterDataFromSearchParams';
import useFilterQueryParams from '../useHooks/useFilterQueryParams';
import getSortDataForFilter from '../utils/getSortDataForFilter';
import { useNavigate, useNavigationType } from 'react-router';
import { v5 as uuidv5 } from 'uuid';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';
import { useRouterStaticContext } from '../context/RouterStaticContext';
import NotFoundComponent from './common/NotFoundPage';
import { selectorsOther } from '../redux/other/otherReducer';

const CategoriesPage = () => {
  const { id } = useParams();
  const categoryId = Number(id);
  const isCategoryIdNan = isNaN(categoryId);
  const paths = usePaths();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const navigationType = useNavigationType();
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({
    type: FILTER_TYPE.CATEGORY,
    id: categoryId,
    skip: true,
  });
  const { searchQueryData } = useFilterDataFromSearchParams(brandsQuery, badgesQuery, attributesQuery);

  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const selectedSort = useSelector(selectorsFilters.getSortDirection);
  const staticContext = useRouterStaticContext();

  const totalCount = useSelector(selectorsFilters.getTotalSelectedFiltersCount);
  const [currentCategory, { error }] = useApi(queries.getCategory(categoryId), { skip: isCategoryIdNan });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const location = useLocation();
  const navigate = useNavigate();

  const backBtn = useMemo(() => {
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={currentTranslate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (staticContext && (error?.status === 404 || isCategoryIdNan)) {
    staticContext.statusCode = 404;
  }

  const applyFilters = () => {
    setOffset(0);
  };

  useEffect(() => {
    if (!isLessThen991) {
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, categoryId]);

  const getActualSearchData = () => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return searchQueryData.isEmpty ? searchFilter || '' : searchQueryData.searchFilter || '';
    }
    return searchFilter || '';
  };

  const liveSearchData = getActualSearchData();
  const tidNameSpace = uuidv5(location.pathname, uuidv5.URL);
  const tid = liveSearchData?.length ? uuidv5(liveSearchData, tidNameSpace) : '';

  const makeFiltersFromSearchParams = (filters: any) => {
    const brandsFilter = searchQueryData.isEmpty ? selectedBrands : searchQueryData.selectedBrands;
    const badgesFilter = searchQueryData.isEmpty ? selectedBadges : searchQueryData.selectedBadges;

    return {
      ...filters,
      brands: brandsFilter?.length ? brandsFilter.slice(0).map((brand) => brand.id) : null,
      badges: badgesFilter?.length ? badgesFilter.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: searchQueryData.isEmpty ? selectedAllergich : searchQueryData.selectedAllergich,
      selectedNutrion: searchQueryData.isEmpty ? selectedNutrion : searchQueryData.selectedNutrion,
      hasPriceRanges: searchQueryData.isEmpty ? hasPriceRanges : searchQueryData.hasPriceRanges,
      hasBadges: searchQueryData.isEmpty ? hasBadges : searchQueryData.hasBadges,
      hasSalePrice: searchQueryData.isEmpty ? hasSalePrice : searchQueryData.hasSalePrice,
      searchFilter: liveSearchData,
    };
  };

  const getProducts = (offset?: number, limit?: number) => {
    let filters = {
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      orderProducts: getSortDataForFilter(searchQueryData, selectedSort),
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
    };
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      filters = makeFiltersFromSearchParams(filters);
    }

    return queries.getProductsByCategory(
      categoryId,
      filters.orderProducts,
      filters,
      isInCourierArea,
      limit,
      offset,
      tid,
    );
  };

  const options = useMemo(
    () => ({
      handleData,
      dependencies: {
        perPage: limit,
      },
      skip: isCategoryIdNan,
    }),
    [isCategoryIdNan, limit],
  );

  const [products, { loading, hasMore = true }, request] = useApi(getProducts(), options);
  const loadMore = useCallback(() => {
    const newOffset = offset + limit;
    request(getProducts(newOffset, limit), true).then(() => setOffset(newOffset));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    offset,
    selectedBrands,
    selectedBadges,
    selectedAllergich,
    selectedNutrion,
    hasPriceRanges,
    hasBadges,
    hasSalePrice,
    searchFilter,
    selectedSort,
    searchQueryData,
  ]);

  useEffect(() => {
    if (!isOpen) {
      blockBodyScroll(false);
    }
  }, [isOpen]);

  const metaData = useMemo(() => {
    const title = currentCategory?.seoTexts?.title || SeoTexts.title;
    const description = currentCategory?.seoTexts?.description || SeoTexts.description;

    return {
      title,
      description,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  const bullets = getBannerBullets(currentCategory);

  const openProductCardPopup = (product) => {
    if (!isOpen) {
      setIsOpen(true);
      setSelectedProduct(product);
    }
  };

  const closeProductCardPopup = () => {
    if (isOpen) {
      setIsOpen(false);
      setSelectedProduct({});
    }
  };

  if (error?.status === 404 || isCategoryIdNan) {
    return <NotFoundComponent />;
  }

  return (
    <section className="category-page">
      <CommonHelmet title={metaData.title} description={metaData.description}>
        <link rel="canonical" href={config.canLink + paths.categories(categoryId)} />
      </CommonHelmet>
      {backBtn}
      <section className={`section-main ${isHasBanner(currentCategory) ? '' : 'no-banner-crumbs'}`}>
        <Breadcrumbs idCategory={categoryId} />
        {isHasBanner(currentCategory) && (
          <div className="search-main" style={{ height: getBannerHeight(currentCategory) }}>
            <figure className="search-main_pic">
              <img src={getBannerImage(currentCategory)} alt="" />
            </figure>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <h1 style={{ color: '#fff' }}>
                    {getBannerTitle(currentCategory, buildLocale(currentTranslate, 'ownCategoryBannerTitle'))}
                  </h1>
                  <div className="row">
                    {bullets &&
                      bullets.map((item, index) => (
                        <div key={index} className="col-md-4">
                          <article className="category-page_art" style={{ color: '#fff' }}>
                            {item}
                          </article>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isHasBanner(currentCategory) && <h1 className="category-title">{currentCategory?.name || ''}</h1>}
      </section>
      <FilterComponent id={categoryId} type={FILTER_TYPE.CATEGORY} applyFilters={applyFilters} isLoading={loading} />
      <div className="search-result">
        <div className="container">
          <InfiniteScroll
            next={loadMore}
            hasMore={hasMore}
            loader={<ProductLoader />}
            dataLength={products?.length || 0}
            scrollThreshold={0.8}
            style={{ overflowX: 'hidden' }}>
            {!!products?.length &&
              products.map((product: any) => (
                <div key={product.id} className="col-6 col-md-4 col-lg-3 col-xl-2 catalog-item">
                  <Product product={product} onProductImageClick={openProductCardPopup} />
                </div>
              ))}
          </InfiniteScroll>
          {isOpen && <ProductCard isOpen={isOpen} id={selectedProduct.id} onProductCardClose={closeProductCardPopup} />}
        </div>
      </div>
    </section>
  );
};

export default CategoriesPage;
