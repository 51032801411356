import React from 'react';
import { IContact } from '../../../typings/IContact';
import { useDispatch, useSelector } from 'react-redux';
import { actionsUser } from '../../../redux/user/userReducer';
import { useAxios } from '../../../useHooks/useAxios';
import service from '../../../services/service';
import { generateClassName } from '../../../utils/generateClassName';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import { isMetroShop } from '../../../constants/shops';
import config from '../../../config';

interface IContactCardProps {
  contact: IContact;
  onClick: () => any;
  readonly?: boolean;
  isActive?: boolean;
}

const ContactCard = ({ contact, onClick, isActive = false, readonly = false }: IContactCardProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const { isLoading, request } = useAxios(service.deleteContact);
  const dispatch: any = useDispatch();
  const isMetro = isMetroShop(config.shopId);

  const handleClick = async (e) => {
    e.stopPropagation();
    const res = await request(contact.id);
    if (res.success) {
      dispatch(actionsUser.deleteContact(contact.id));
    }
  };

  const className = generateClassName('address-card', {
    'address-card-disabled': isLoading,
    'address-card-active': isActive,
  });

  return (
    <div onClick={onClick} className={className}>
      {!readonly && <button className="btn-delete" onClick={handleClick} />}
      <p className="all">
        <strong>{buildLocale(currentTranslate, 'tINameLabel')}: </strong>
        {contact.firstName} {contact.lastName}
      </p>
      {isMetro && contact.company && (
        <p className="all">
          <strong>{buildLocale(currentTranslate, 'tICompanyLabel')}: </strong>
          {contact.company}
        </p>
      )}
      <p className="all">
        <strong>{buildLocale(currentTranslate, 'tIPhonePlaceholder')}: </strong>
        {contact.phone}
      </p>
      <p className="all">
        <strong>{buildLocale(currentTranslate, 'tIEmailPlaceholderData')}: </strong>
        {contact.email}
      </p>
    </div>
  );
};

export default ContactCard;
