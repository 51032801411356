import { useEffect } from 'react';
import { IProduct } from '../../typings/IProduct';
import getDataLayer from './getDataLayer';
import useGetDataByProduct from './useGetDataByProduct';

interface IDLViewProductDetailsProps {
  product: IProduct;
}

const DLViewProductDetails = ({ product }: IDLViewProductDetailsProps) => {
  const getDataByProduct = useGetDataByProduct();

  useEffect(() => {
    const { brand, category, price } = getDataByProduct(product);
    getDataLayer().push({
      event: 'detail',
      ecommerce: {
        detail: {
          products: [
            {
              name: product.title,
              id: product.id,
              price: price,
              brand: brand,
              category: category,
            },
          ],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default DLViewProductDetails;
