export const encodeUrlTailParamsForFilters = (value: string) => {
  if (!value?.length) {
    return value;
  }
  return value.replace(/ /g, '+').replace(/&/g, 'and');
};

export const decodeUrlTailParamsForFilters = (value: string) => {
  if (!value?.length) {
    return value;
  }
  return value.replace(/[\s+]and[\s+]/g, '+&+').replace(/\+/g, ' ');
};
