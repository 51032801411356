import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePaths } from '../../Routes/RouterList';
import { buildLocale } from '../../../utils/buildLocale';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { useFormattingContext } from '../../../context/FormattingContext';

interface IProps {
  amount: string;
  createDate: string;
  note: string;
  publicNote: string | null;
  operationId: number;
}

const CreditsHistoryItem = (props: IProps) => {
  const { amount, createDate, note, publicNote, operationId } = props;
  const paths = usePaths();
  const { formatPrice, longFormatDate } = useFormattingContext();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const creditsIn = operationId === 1;
  const date = new Date(createDate);

  return (
    <div className="profile-credits_one">
      <div className="profile-credits_info">
        <p className="profile-credits_date">{longFormatDate(date)}</p>
        {creditsIn ? (
          publicNote
        ) : (
          <>
            {buildLocale(currentTranslate, 'profileDebitingCredits')} <Link to={paths.profile('history')}>{note}</Link>
          </>
        )}
      </div>
      <div className={`profile-credits_number ${creditsIn ? 'plus' : 'minus'}`}>
        {creditsIn ? '+' : '-'}
        {formatPrice(+amount)}
      </div>
    </div>
  );
};

export default CreditsHistoryItem;
