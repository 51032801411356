//TODO : enum
export const firstName = 'firstName';
export const lastName = 'lastName';
export const phone = 'phone';
export const email = 'email';
export const card = 'card';
export const paypal = 'paypal';
export const method = 'metod';
export const freeBag = 'freeBag';
export const city = 'city';
export const street = 'street';
export const buildNumber = 'buildNumber';
export const flatNumber = 'flatNumber';
export const entrance = 'entrance';
export const floor = 'floor';
export const dateDraft = 'dateDraft';
export const timeDraft = 'timeDraft';
export const comment = 'comment';
export const draftId = 'draftId';
export const contactId = 'contactId';
export const coupon = 'coupon';
export const addressId = 'addressId';
export const credits = 'credits';
export const company = 'company';
export const invoice = 'invoice';

export const DATA_STEP = 'DATA_STEP';
export const PAY_STEP = 'PAY_STEP';
export const FINAL_STEP = 'FINAL_STEP';
