import { isBrowser } from '../isBrowser';
import getDataLayer from './getDataLayer';
import { getOwnCategory } from '../getOwnCategory';

export const DL_helper = (cart: Array<any>, count: number, name: string, product: any) => {
  if (isBrowser) {
    const customCategory = getOwnCategory(product?.customCategories);
    getDataLayer().push({
      event: name,
      ecommerce: {
        currency: 'EUR',
        items: [
          ...cart.map((p) => {
            const cartCustomCategory = getOwnCategory(p?.product?.customCategories);
            return {
              item_name: p.product?.titleDe || p.product?.title,
              item_id: p.product?.id,
              price: p.product?.productOptions[0]?.salePrice || p.price || p.product?.productOptions[0]?.price,
              category: cartCustomCategory?.name,
              quantity: p.count,
              brand: p.product?.brand?.nameDe,
            };
          }),
        ],
        newCount: count,
        product: {
          item_name: product.title,
          item_id: product?.id,
          category: customCategory?.name,
          brand: product?.brand?.nameDe,
          price: product?.productOptions[0]?.salePrice || product?.price || product?.productOptions[0]?.price,
        },
      },
    });
  }
};
