import React from 'react';
import CategoryImage from '../ImagesComponent/CategoryImage';
import categoryMobileImg from '../../assets/images/category-mobile.svg';
import { usePaths } from '../Routes/RouterList';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  category?: any;
  isCategories?: boolean;
  isCompanies?: boolean;
  location: any;
}

const CategoriesItemMobile = (props: IProps) => {
  const { category, isCategories, isCompanies } = props;
  const haveChildren = category && (category.hasChildren || (category.children && category.children.length > 0));
  const isCategory = category && !category.children && category.hasChildren === undefined;
  const navigate = useNavigate();
  const location = useLocation();
  const paths = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const itemNavigate = () => {
    const actualSearchData = location.search;
    const historyItem = `${location.pathname}${actualSearchData}`;
    let historyData = [historyItem];
    if (location?.state?.from?.length) {
      const stateFromData = [...location.state.from];
      const existingHistoryItemKey = stateFromData.findIndex((item: string) => item.includes(location.pathname));
      if (existingHistoryItemKey > -1) {
        stateFromData[existingHistoryItemKey] = historyItem;
      } else {
        stateFromData.push(historyItem);
      }
      historyData = stateFromData;
    }

    const historyFiltersData = location?.state?.filters;
    if (isCompanies) {
      navigate(`${paths.categories_page('companies')}${actualSearchData}`, {
        state: {
          from: historyData,
          filters: historyFiltersData,
        },
      });
      return;
    }
    if (isCategories) {
      navigate(`${paths.categories_page('categories')}${actualSearchData}`, {
        state: {
          from: historyData,
          filters: historyFiltersData,
        },
      });
      return;
    }
    if (haveChildren) {
      navigate(`${paths.categories_page('own_categories', category.id)}${actualSearchData}`, {
        state: { from: historyData, filters: historyFiltersData },
      });
      return;
    }
    if (isCategory) {
      navigate(`/categories/${category.id}${actualSearchData}`, {
        state: {
          from: historyData,
          filters: historyFiltersData,
        },
      });
      return;
    }
    navigate(`/products/${category.id}${actualSearchData}`, {
      state: {
        from: historyData,
        filters: historyFiltersData,
      },
    });
  };

  if (isCompanies) {
    return (
      <div className="mobile-categories_one" onClick={() => itemNavigate()}>
        <div className="mobile-categories_one-in" key={uuidv4()}>
          <span className="name">{buildLocale(currentTranslate, 'companyTitle')}</span>
        </div>
      </div>
    );
  }

  if (isCategories) {
    return (
      <div className="mobile-categories_one">
        <div onClick={() => itemNavigate()} className="mobile-categories_one-in" key={category?.id || uuidv4()}>
          <figure className="pic">
            <img src={categoryMobileImg} alt="" />
          </figure>
          <span className="name">{buildLocale(currentTranslate, 'categoriesTitle')}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="mobile-categories_one">
      <div className="mobile-categories_one-in" onClick={() => itemNavigate()} key={category.id}>
        <figure className="pic">
          <CategoryImage category={category} />
        </figure>
        <span className="name">{category.name}</span>
      </div>
    </div>
  );
};

export default CategoriesItemMobile;
