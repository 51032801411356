import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormattingContext } from '../../context/FormattingContext';
import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import CartCountItem from '../Cart/CartCountItem';
import ProductImage from '../ImagesComponent/ProductImage';
import { calculateWeightablePriceWithCount } from '../../utils/calculateUnits';
import getDiscountPercentage from '../../utils/getDiscountPercentage';

interface IProps {
  item: any;
  notAvailableType: false | 'count' | 'noProduct';
}

const CartItemComponent = (props: IProps) => {
  const { item, notAvailableType } = props;
  const dispatch = useDispatch<any>();
  const { formatPrice } = useFormattingContext();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const salePrice = item?.product?.productOptions[0]?.salePrice;
  const price = item?.product?.productOptions[0]?.price;
  const dataPrice = item?.price;
  const isWeightable = item?.product?.isWeightable;
  const count = item.alternativeCount ? item.alternativeCount : +item.count;
  const quantityPrice = item.alternativeCount ? price * count * +item.product.avgWeight! : price * +count;
  const unitType = item?.product?.unit?.type;
  const quantityPerPack = item?.product?.quantityPerPack;
  const weightablePrice = calculateWeightablePriceWithCount(price, unitType, count, quantityPerPack);
  const totalWeightDeposit = item?.totalWeightDeposit ? +item?.totalWeightDeposit : 0;
  const totalBottleDeposit = item?.totalBottleDeposit ? +item?.totalBottleDeposit : 0;
  const totalRackDeposit = item?.totalRackDeposit ? +item.totalRackDeposit : 0;
  const isLoadingCartRequest = useSelector(selectorsCart.getIsLoading);

  const removeItem = () => {
    dispatch(actionsCart.removeProduct(+item.product.id));
  };

  return (
    <div className="basket-page_item">
      <figure className="pic">
        {salePrice && <span className="item-status item-status_sale">{getDiscountPercentage(salePrice, price)}</span>}
        <ProductImage product={item.product} />
      </figure>
      <div className="main-info">
        <div className="main-info_main">
          <span className="title">{item.product.titleDe || item.product.title}</span>
          <CartCountItem item={item} />
          <div className="basket-page_price">
            {salePrice && (
              <span className="price price-prev">
                {isWeightable ? formatPrice(weightablePrice) : formatPrice(quantityPrice)}
              </span>
            )}
            <span className="price-sale">{formatPrice(dataPrice)}</span>
          </div>

          <div className="main-info_error">
            {notAvailableType === 'noProduct' && (
              <span className="basket-block_item-error">{buildLocale(currentTranslate, 'productNotAvailable')}</span>
            )}
            {notAvailableType === 'count' && (
              <span className="basket-block_item-error">
                {buildLocale(currentTranslate, 'productNotAvailableCount')}
              </span>
            )}
          </div>
        </div>
        {totalWeightDeposit > 0 && (
          <div className="basket-block_item-bottle">
            <div className="caption">
              {buildLocale(currentTranslate, 'cartWeightDeposit')}
              <div className="info-block">
                <span className="info">?</span>
                <div className="info-hover">{buildLocale(currentTranslate, 'cartWeightDepositInfo')}</div>
              </div>
            </div>
            <span>{formatPrice(totalWeightDeposit)}</span>
          </div>
        )}
        {totalBottleDeposit > 0 && (
          <div className="basket-block_item-bottle">
            <span>{buildLocale(currentTranslate, 'cartBottleDeposit')}</span>
            <span>{formatPrice(totalBottleDeposit)}</span>
          </div>
        )}
        {totalRackDeposit > 0 && (
          <div className="basket-block_item-bottle">
            <span>{buildLocale(currentTranslate, 'cartRackDeposit')}</span>
            <span>{formatPrice(totalRackDeposit)}</span>
          </div>
        )}
      </div>
      <span
        className="basket-page_delete"
        onClick={() => {
          if (!isLoadingCartRequest) {
            removeItem();
          }
        }}>
        &nbsp;
      </span>
    </div>
  );
};

export default CartItemComponent;
