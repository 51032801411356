import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OwnCategoriesComponent from '../components/common/CustomSlider';
import { selectorsCategory } from '../redux/category/categoryReducer';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductLoader from '../components/Loaders/ProductLoader';
import HomePageBanner from '../components/HomePage/HomePageBanner';
import { actionsFilters } from '../redux/filters/filtersReducer';
import { useLessThen991 } from '../utils/mediaQuery';
import { selectorsOther } from '../redux/other/otherReducer';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import GoogleWebSiteData from './GoogleWebSiteData';

const HomePage = () => {
  const categories = useSelector(selectorsCategory.getVisibleRootCategories);
  const dispatch: any = useDispatch();
  const isBot = useSelector(selectorsOther.getIsBot);
  const [limit, setLimit] = useState(4);
  const [isHasMore, setIsHasMore] = useState(categories?.length > limit);
  const [shownCategories, setShownCategories] = useState<Array<any>>(
    categories?.length > 0 ? (isBot ? categories : categories.slice(0, limit)) : [],
  );
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const zipCode = useSelector(selectorsDelivery.getZipCode);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);

  useEffect(() => {
    if (!zipCode || isInCourierArea === null) {
      return;
    }
    dispatch(actionsFilters.clearAllSelected());
    categories && categories.length > 0 && setShownCategories(categories.slice(0, limit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode, isInCourierArea]);

  const changeLimit = () => {
    const newLimit = limit + 1;
    setLimit(newLimit);
    setShownCategories(categories ? categories.slice(0, newLimit) : []);
    if (shownCategories.length > 0 && shownCategories.length >= categories.length) {
      setIsHasMore(false);
    }
  };

  return (
    <section className="home-page home-page_mobile">
      <section className="section-main main-page-banner">
        {/* NOTE: change video true/false for main banner for home page */}
        <HomePageBanner video={true} />
      </section>
      {shownCategories && shownCategories.length > 0 && (
        <InfiniteScroll
          dataLength={shownCategories.length}
          style={{ overflowX: 'hidden' }}
          next={changeLimit}
          hasMore={isHasMore}
          loader={<ProductLoader />}>
          {shownCategories.map((category: any) => {
            return (
              <OwnCategoriesComponent
                key={category.id}
                name={category.name}
                id={category.id}
                setLimit={changeLimit}
                route={
                  category.hasChildren ? (isLess991 ? 'categories_page/own_categories' : 'own_categories') : 'products'
                }
              />
            );
          })}
        </InfiniteScroll>
      )}
      <GoogleWebSiteData />
    </section>
  );
};

export default HomePage;
