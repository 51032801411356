import { CreatorReducer } from '../base/base';
import { ILocaleActions, ILocaleState } from './localeTypes';
import { RootState } from '../reducer';
import service from '../../services/service';

const init: ILocaleState = {
  locale: null,
  localeHTML: null,
};

const creator = new CreatorReducer<ILocaleActions, ILocaleState>('locale');
creator.addAction('changeTranslate', (state, action) => {
  return { ...state, locale: action.payload };
});
creator.addAction('changeHTMLTranslate', (state, action) => {
  return { ...state, localeHTML: action.payload };
});
creator.addAction('clearTranslations', (state, action) => {
  return { ...state, locale: [], localeHTML: [] };
});

const actionsLocale = creator.createActions();

const thunkGetTranslations = () => async (dispatch: any) => {
  const res = await service.getTranslate();
  return dispatch(actionsLocale.changeTranslate(res.data.data));
};

const thunkGetHTMLTranslations = () => async (dispatch: any) => {
  const res = await service.getHTMLTranslate();
  return dispatch(actionsLocale.changeHTMLTranslate(res.data.data));
};

const selectorsLocale = {
  getTranslate: (state: RootState) => state.locale.locale,
  getHTMLTranslate: (state: RootState) => state.locale.localeHTML,
};

export { actionsLocale, selectorsLocale, thunkGetTranslations, thunkGetHTMLTranslations };
export default creator.createReducer(init);
