import React, { useCallback, useContext, useEffect } from 'react';
import { actionsCart, fetchSaveCart } from '../redux/cart/cartReducer';
import { RootState } from '../redux/reducer';
import { actionsOrder } from '../redux/order/orderReducer';
import { isBrowser } from '../utils/isBrowser';
import { actionsCity } from '../redux/city/cityReducer';
import { useDispatch } from 'react-redux';
// import useDL_AddProduct from '../utils/DataLayer/useDL_AddProduct';
// import useDL_RemoveProduct from '../utils/DataLayer/useDL_RemoveProduct';
import debounce from '../utils/debounce';
const Cookies = isBrowser && require('cookies-js');

interface IEventReduxContext {}

const EventReduxContext = React.createContext({} as IEventReduxContext);

const useEventRedux = () => {
  const context = useContext(EventReduxContext);
  return context;
};

enum TypeEvent {
  addProduct = actionsCart.addProduct(null as any).type,
  removeProduct = actionsCart.removeProduct(null as any).type,
  changeAlternativeCount = actionsCart.changeAlternativeCount(null as any).type,
  changeCount = actionsCart.changeCount(null as any).type,
  incrProduct = actionsCart.incrProduct(null as any).type,
  decrProduct = actionsCart.decrProduct(null as any).type,
  setComment = actionsCart.setComment(null as any).type,
  clear = actionsCart.clear().type,
  actionsOrderSetData = actionsOrder.setData(null as any).type,
  actionsCitySetData = actionsCity.setDataItem(null as any).type,
}

const cartEvents = [
  TypeEvent.addProduct,
  TypeEvent.removeProduct,
  TypeEvent.changeAlternativeCount,
  TypeEvent.changeCount,
  TypeEvent.incrProduct,
  TypeEvent.decrProduct,
  TypeEvent.setComment,
  TypeEvent.clear,
];
let event: any = {};

const eventCatch = (store) => (next) => (action) => {
  const state: RootState = store.getState();
  if (action.type === TypeEvent.removeProduct) {
    // eslint-disable-next-line eqeqeq
    const payload = state.cart.data.find((item) => item.product.id == action.payload);
    event.action = {
      type: TypeEvent.removeProduct,
      payload,
    };
  } else {
    event.action = action;
  }
  return next(action);
};

const ProviderEventRedux = ({ children }: any) => {
  const dispatch = useDispatch<any>();
  // const addProduct = useDL_AddProduct();
  // const removeProduct = useDL_RemoveProduct();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSaveCart = useCallback(
    debounce(() => {
      dispatch(fetchSaveCart);
    }, 0),
    [],
  );

  useEffect(() => {
    event = new Proxy(event, {
      set(target, prop, val) {
        const { payload } = val;

        if (cartEvents.some((type) => type === val.type)) {
          dispatch(actionsCart.setIsLoadingData(true));
          dispatch(actionsCart.setLoading(true));
          handleSaveCart();
        }
        if (val.type === TypeEvent.addProduct) {
          // addProduct(payload);
        } else if (val.type === TypeEvent.removeProduct) {
          // removeProduct(payload);
        } else if (val.type === TypeEvent.actionsOrderSetData) {
          Object.keys(payload).forEach((key) => {
            const value = payload[key] || null;
            if (key === 'deliveryType' || key === 'sellPoint') {
              Cookies.set(key, JSON.stringify(value));
            }
          });
        } else if (val.type === TypeEvent.actionsCitySetData) {
          Object.keys(payload).forEach((key) => {
            if (key === 'selectedCity') {
              const value = JSON.stringify(payload[key] || null);
              Cookies.set(key, value);
            }
          });
        }
        return true;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <EventReduxContext.Provider value={{}}>{children}</EventReduxContext.Provider>;
};

export { useEventRedux, eventCatch };
export default ProviderEventRedux;
