import { useSelector } from 'react-redux';
import { selectorsCart } from '../../redux/cart/cartReducer';
import { IProduct } from '../../typings/IProduct';
import { getIndexProductOption } from '../getIndexProductOption';
import { getOwnCategory } from '../getOwnCategory';

const useGetDataByProduct = () => {
  const ID_SELL_POINT = useSelector(selectorsCart.getIdSellPoint);

  return (product: IProduct | any, idSellPoint?: any) => {
    const customCategory = getOwnCategory(product?.customCategories);
    const index = getIndexProductOption(product, idSellPoint || ID_SELL_POINT);
    const price = product.productOptions.length > 0 ? +product.productOptions[index].price : 0;
    const salePrice = product.productOptions.length > 0 ? +product.productOptions[index].salePrice : 0;

    const currentPrice = salePrice === 0 ? price : salePrice;

    const category = customCategory?.name || '';
    const brand = product?.brand?.nameDe;
    return { price: currentPrice, category, brand: brand };
  };
};

export default useGetDataByProduct;
