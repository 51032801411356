import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectorsUser } from '../../redux/user/userReducer';
import { usePaths } from '../Routes/RouterList';
import { IRootCategory } from '../../typings/ICategory';
import wishlistIcon from '../../assets/images/ic-heart.svg';
import dropArrowIcon from '../../assets/images/ic-arrow-drop.svg';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { generateClassName } from '../../utils/generateClassName';
import { actionsOwnCategories } from '../../redux/ownCategories/ownCategoriesReducer';
import { actionsFilters } from '../../redux/filters/filtersReducer';
import useApi from 'react-use-api';
import queries from '../../services/queries';
import { useNavigate } from 'react-router';

const CategoriesList = () => {
  const [menuItems] = useState<Array<IRootCategory>>([]);
  const [show, setShow] = useState(false);
  const containerRef = useRef<any>({});
  const listRef = useRef<any>({});
  const isAuth = useSelector(selectorsUser.isAuth);
  const paths = usePaths();
  const { pathname } = useLocation();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const currentIdCategory = pathname.split('/').pop();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const [categories] = useApi(queries.getCategories());

  const resetLimit = () => {
    dispatch(actionsOwnCategories.resetLimit());
  };

  /**
   * Navigates to a specific page based on user data
   */
  const wishlistNavigation = () => {
    if (isAuth) {
      navigate(paths.wishlist);
    } else {
      navigate(paths.login);
    }
  };

  return (
    <div className="header-category" ref={containerRef}>
      <ul ref={listRef} style={{ width: 'fit-content', minWidth: '100%' }}>
        <li key={0} onClick={() => wishlistNavigation()} className={'menu-list-item'}>
          <span
            className={generateClassName('wishlist-icon', {
              active: location.pathname === '/wishlist',
            })}>
            <img src={wishlistIcon} alt="" width="20" height="19" />
            <a href={isAuth ? paths.wishlist : paths.login} onClick={(e) => e.preventDefault()}>
              {buildLocale(currentTranslate, 'wishListTitle')}
            </a>
          </span>
        </li>
        {categories?.length > 0 &&
          categories.map((category) => (
            <li
              key={category.id}
              className={`menu-list-item${
                currentIdCategory && category.id === +currentIdCategory ? ' menu-list-item-active' : ''
              }`}>
              <a
                href={`/categories/${category.id}`}
                className="custom-drop_link"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(actionsFilters.clearAllSelected());
                  resetLimit();
                  navigate(`/categories/${category.id}`, { replace: true });
                }}>
                {category.name}
              </a>
            </li>
          ))}
        {menuItems.length > 0 && (
          <li style={{ position: 'relative' }}>
            <span onClick={() => setShow(!show)} className="custom-drop-down_more">
              {buildLocale(currentTranslate, 'headerTopListMore')}
              <img src={dropArrowIcon} style={{ marginLeft: '0.5rem' }} width={12} alt="" />
            </span>
            {show && (
              <div className="custom-drop-down" onMouseLeave={() => setShow(false)}>
                {menuItems.length > 0 &&
                  menuItems.map((category) => (
                    <div key={category.id} className="custom-drop-down_item">
                      <a
                        href={`/categories/${category.id}`}
                        className="custom-drop-down_link"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(actionsFilters.clearAllSelected());
                          resetLimit();
                          navigate(`/categories/${category.id}`, { replace: true });
                        }}>
                        {category.name}
                      </a>
                    </div>
                  ))}
              </div>
            )}
          </li>
        )}
      </ul>
    </div>
  );
};

export default CategoriesList;
