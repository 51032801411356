import React from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';

interface IAssortmentNotice {
  isOpen: boolean;
  onClose: any;
  onConfirm: any;
}

const AssortmentNoticeModal = ({ isOpen, onClose, onConfirm }: IAssortmentNotice) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const handleClear = () => {
    onConfirm();
  };

  return (
    <Modal
      className={`edit_modal info_modal`}
      overlayClassName="edit_overlay payment_overlay"
      isOpen={isOpen}
      onRequestClose={onClose}>
      <div className="modal-dialog modal-change">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{buildLocale(currentTranslate, 'assortmentChangeTitle')}</h4>
          </div>
          <div className="modal-body">
            {buildLocale(currentTranslate, 'assortmentWarningTitle')}{' '}
            <span className="font-bold">{buildLocale(currentTranslate, 'assortmentDeletedTitle')} </span>,
            {buildLocale(currentTranslate, 'assortmentDeletedMsg')}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={onClose} className="btn btn-blue-border text-uppercase" data-dismiss="modal">
              {buildLocale(currentTranslate, 'assortmentCancelBtn')}
            </button>
            <button onClick={handleClear} type="button" className="btn btn-blue text-uppercase" data-dismiss="modal">
              {buildLocale(currentTranslate, 'assortmentChangeBtn')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssortmentNoticeModal;
