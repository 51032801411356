import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { draftId } from '../../../constants/payData';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { usePaths } from '../../Routes/RouterList';
import service from '../../../services/service';
import { buildLocale } from '../../../utils/buildLocale';
import { getLocaleStorageItem, setLocaleStorageItem } from '../../../utils/localStorageHelper';
import { useNavigate } from 'react-router';

const Timer = () => {
  const navigate = useNavigate();
  const paths = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [timer, setTimer] = useState(1200);

  useEffect(() => {
    const currentTime = getLocaleStorageItem('timer');
    if (!currentTime) {
      setLocaleStorageItem('timer', new Date().getTime().toString());
    } else {
      const now = new Date();
      if ((+currentTime - +now.getTime()) / 1000 < -600) {
        const draft = getLocaleStorageItem(draftId);
        if (draft) {
          service.unlockProducts(+draft).finally(() => navigate(paths.home));
        }
      }
    }
    const timeout = setTimeout(() => setTimer(timer - 1), 1000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return <p className="pay-page_timer">{buildLocale(currentTranslate, 'tenMinMessage')}</p>;
};

export default Timer;
