import React from 'react';
import Carousel from 'react-multi-carousel';
import { useSelector } from 'react-redux';
import config from '../../config';
import { carouselBanner } from '../../constants/configCarousel';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { ISlider } from '../../typings/IPictures';
import { buildLocale } from '../../utils/buildLocale';
import { isBrowser } from '../../utils/isBrowser';
import { sortWithNullsAtEnd } from '../../utils/sortWithNullsAtEnd';

interface IProps {
  slider: ISlider;
}

const BannersSlider = (props: IProps) => {
  const { slider } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  return (
    <Carousel
      autoPlay={slider && slider.length > 1}
      ssr={!isBrowser}
      responsive={carouselBanner}
      focusOnSelect={false}
      shouldResetAutoplay={false}
      swipeable={true}
      autoPlaySpeed={5000}
      infinite={slider && slider.length > 1}
      draggable={true}
      arrows={false}
      showDots={slider && slider.length > 1}
      deviceType="desktop">
      {slider?.length > 0 &&
        sortWithNullsAtEnd(slider).map((s) => (
          <div key={s.id}>
            {s.video ? (
              <div className={`banner-mobile`}>
                <figure className="banner-pic">
                  <video style={{ width: '100%', height: '100%' }} autoPlay loop muted>
                    <source src={config.videoURL + s.video.path} type="video/mp4" />
                  </video>
                  {s.link && (
                    <a href={s.link} className="btn">
                      {buildLocale(currentTranslate, 'commonBannerLink')}
                    </a>
                  )}
                </figure>
              </div>
            ) : (
              <div className={`banner-mobile`}>
                <figure className="banner-pic">
                  <img src={s?.media?.variants?.origin} alt="" />
                </figure>
                {s.link && (
                  <a href={s.link} className="btn">
                    {buildLocale(currentTranslate, 'commonBannerLink')}
                  </a>
                )}
              </div>
            )}
          </div>
        ))}
    </Carousel>
  );
};

export default BannersSlider;
