import { DataLayerEnum } from './constant';
import getDataLayer from './getDataLayer';

const DL_PageView = () => {
  const reffer = sessionStorage.getItem(DataLayerEnum.NAME_REFFER);
  const currentLocation = window.location.href;
  if (reffer) {
    if (currentLocation !== reffer) {
      getDataLayer().push({
        event: 'custom_page_view',
        custom_title: window.document.title,
        custom_url: currentLocation,
        custom_referrer: reffer,
      });
    }
  }
  sessionStorage.setItem(DataLayerEnum.NAME_REFFER, currentLocation);
};

export default DL_PageView;
