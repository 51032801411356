import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsOther, selectorsOther } from '../../redux/other/otherReducer';
import HeaderBlockContent from './HeaderBlockContent';
import { HiddenPublicMessage, PublicMessage } from '../../redux/other/otherTypes';
import useApi from 'react-use-api';
import queries from '../../services/queries';

const HeaderBlockInfo = () => {
  const limit = 2;
  const dispatch = useDispatch<any>();
  const CLOSED_INFO_BLOCK_HEIGHT = 145; // in px
  const hiddenPublicMessages: HiddenPublicMessage[] = useSelector(selectorsOther.getHiddenPublicMessages);
  const [refreshedHiddenMessages, setRefreshedHiddenMessages] = useState([] as HiddenPublicMessage[]);

  const refreshHiddenMessages = () => {
    if (!hiddenPublicMessages?.length) {
      return [];
    }
    const date = new Date();
    return hiddenPublicMessages.filter((item: HiddenPublicMessage) => {
      return new Date(item.startDate) && date <= new Date(item.endDate);
    });
  };

  const isItemAlreadyHidden = (hiddenMessages: HiddenPublicMessage[], item: PublicMessage) => {
    return hiddenMessages.find((hiddenItem: HiddenPublicMessage) => hiddenItem.id === item.id);
  };

  const handleData = (items: PublicMessage[], state: any) => {
    const { error } = state;
    if (error) {
      return [];
    }

    const hiddenMessages = refreshHiddenMessages();

    return items
      .map((item: PublicMessage) => {
        if (!isItemAlreadyHidden(hiddenMessages, item)) {
          return { ...item };
        }
        return null;
      })
      .filter((item: any) => item);
  };

  const [publicMessages] = useApi(queries.getMessages(), { handleData });

  const calculateBlockInfoDim = (currentMessages: PublicMessage[]) => {
    const date = new Date();
    const shownMessagesOnly = currentMessages.filter(
      (item: PublicMessage) => date >= new Date(item.startDate) && date <= new Date(item.endDate),
    );
    const total = shownMessagesOnly.length >= limit ? limit : shownMessagesOnly.length;
    const offsetForMargin = total === 2 ? 1 : total; // height for default block is calculated for max mess count (2) to show breadcrumbs correctly - so do not increase offset for 2 messages
    dispatch(
      actionsOther.publishBlockInfoOptions({
        displayedmMessagesTotal: total,
        headerHightPx: CLOSED_INFO_BLOCK_HEIGHT * offsetForMargin,
      }),
    );
  };

  useLayoutEffect(() => {
    const renewedHiddenMessages = refreshHiddenMessages();
    if (renewedHiddenMessages?.length !== hiddenPublicMessages?.length) {
      dispatch(actionsOther.setHiddenPublicMessages(renewedHiddenMessages));
    }
    setRefreshedHiddenMessages(renewedHiddenMessages);
    calculateBlockInfoDim(publicMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicMessages]);

  const changeIsShow = (message: PublicMessage) => {
    const newHiddenMessages = [...refreshedHiddenMessages];
    newHiddenMessages.push({ id: message.id, startDate: message.startDate, endDate: message.endDate });
    dispatch(actionsOther.setHiddenPublicMessages(Array.from(newHiddenMessages)));
    setRefreshedHiddenMessages(Array.from(newHiddenMessages));

    const filteredPublicMessages = publicMessages?.filter(
      (item: PublicMessage) => !isItemAlreadyHidden(newHiddenMessages, message) && item.id !== message.id,
    );
    calculateBlockInfoDim(filteredPublicMessages);
  };

  const actualMessages = !!publicMessages?.length
    ? publicMessages?.filter((item: PublicMessage) => !isItemAlreadyHidden(refreshedHiddenMessages, item))
    : [];

  return !!actualMessages?.length ? (
    <div className="header-block_info">
      {actualMessages.slice(0, limit).map((message: PublicMessage, index: number) => (
        <HeaderBlockContent message={message} key={index} changeIsShow={changeIsShow} />
      ))}
    </div>
  ) : null;
};

export default HeaderBlockInfo;
