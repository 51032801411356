import React from 'react';
import ErrorPage from './ErrorPage';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} params={params} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log({ error });
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        hasError: false,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
