import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { PublicMessage } from '../../redux/other/otherTypes';

interface IProps {
  message: PublicMessage;
  changeIsShow: (message: PublicMessage) => void;
}

const HeaderBlockContent = (props: IProps) => {
  const { message, changeIsShow } = props;
  const [isOpen, setIsOpen] = useState(false);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isShowBtn = message.text.split('</p>').length > 2;

  const updateMessage = () => {
    changeIsShow(message);
    setIsOpen(false);
  };

  return (
    <div className="block-one_out">
      <div
        className={`block-one ${message.type.code} ${isOpen ? 'notification-open' : 'notification-close'}`}
        dangerouslySetInnerHTML={{
          __html: message.text,
        }}></div>
      <button className="block-one_close" onClick={() => updateMessage()}>
        &#10005;
      </button>
      {isShowBtn && (
        <div className="block-one_more--container">
          <button className="btn-more" onClick={() => setIsOpen(!isOpen)}>
            {buildLocale(currentTranslate, isOpen ? 'childrenLess' : 'childrenMore')}
          </button>
        </div>
      )}
    </div>
  );
};

export default HeaderBlockContent;
