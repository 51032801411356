import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import { getLocaleStorageItem, removeFromLocaleStorage, setLocaleStorageItem } from '../../../utils/localStorageHelper';
import { coupon, credits, draftId, freeBag, phone } from '../../../constants/payData';
import service from '../../../services/service';
import ClipLoader from 'react-spinners/ClipLoader';
import { actionsOrder } from '../../../redux/order/orderReducer';
import { selectorsUser } from '../../../redux/user/userReducer';
import { setLoaderColor } from '../../../utils/setLoaderColor';
import useCreateDraft from '../../../useHooks/useCreateDraft';

interface IProps {
  setSalePrice: (price: number | null) => void;
  couponInfo: string;
  setCouponInfo: (info: string) => void;
}

const CouponDataComponent = (props: IProps) => {
  const { setSalePrice, couponInfo, setCouponInfo } = props;
  const createDraft = useCreateDraft();
  const dispatch = useDispatch<any>();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const user = useSelector(selectorsUser.getDataUser);
  const isAuth = useSelector(selectorsUser.isAuth);
  const [isFreeBag, setIsFreeBag] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isUsingCredits, setIsUsingCredits] = useState(false);

  useEffect(() => {
    const currentCredits = getLocaleStorageItem(credits);
    setIsUsingCredits(!!currentCredits);
  }, []);

  useEffect(() => {
    const currentFreeBag = getLocaleStorageItem(freeBag);
    if (currentFreeBag) {
      setIsFreeBag(true);
    }
  }, []);

  const validateCoupon = () => {
    setIsLoading(true);
    const draft = getLocaleStorageItem(draftId);
    service
      .validateCoupon({ code: inputValue, orderId: draft ? +draft : null, phoneNumber: getLocaleStorageItem(phone) })
      .then((res) => {
        if (!res.success) {
          setIsError(true);
          return;
        }
        setSalePrice(res.data.totalPrice);
        setLocaleStorageItem(coupon, inputValue);
        dispatch(actionsOrder.setCouponId(res.data.coupon.id));
        setCouponInfo(`${res.data.coupon.amount} ${res.data.coupon.type.code === 'fix' ? '€' : '%'}`);
        createDraft();
      })
      .finally(() => setIsLoading(false));
  };

  const changeFreeBag = (value) => {
    setIsFreeBag(value);
    if (value) {
      setLocaleStorageItem(freeBag, value);
    } else {
      removeFromLocaleStorage(freeBag);
    }
  };

  const onChangeInput = (text: string) => {
    setInputValue(text);
    setIsError(false);
  };

  const setUseCredits = (isUsed: boolean) => {
    setIsUsingCredits(!!isUsed);
    isUsed ? setLocaleStorageItem(credits, 'true') : removeFromLocaleStorage(credits);
  };

  return (
    <div className="pay-coupon">
      <div className="pay-block_title">
        <div className="title">
          <span className="number">4</span> {buildLocale(currentTranslate, 'payPageCoupon')}
        </div>
      </div>

      {user?.credits?.balance && +user?.credits?.balance ? (
        <div className="pay-coupon_use">
          <p>
            {buildLocale(currentTranslate, 'creditsText1')} <span className="font-bold">{user?.credits?.balance}</span>{' '}
            {buildLocale(currentTranslate, 'creditsText2')}
          </p>
          {isUsingCredits ? (
            <button className="btn btn-small" onClick={() => setUseCredits(false)}>
              {buildLocale(currentTranslate, 'creditsDiscard')}
            </button>
          ) : (
            <button className="btn btn-small" onClick={() => setUseCredits(true)}>
              {buildLocale(currentTranslate, 'creditsUse')}
            </button>
          )}
        </div>
      ) : null}
      {isUsingCredits ? (
        <div className="pay-coupon_use-text">{buildLocale(currentTranslate, 'creditsUsed')}</div>
      ) : null}

      <p>{buildLocale(currentTranslate, 'payPageCouponTitle')}</p>
      {getLocaleStorageItem(coupon) && !couponInfo && (
        <div>
          <span>
            {buildLocale(currentTranslate, 'payPageCouponUsed')} "{getLocaleStorageItem(coupon)}"
          </span>{' '}
          <span>{buildLocale(currentTranslate, 'payPageCouponAnother')}</span>
        </div>
      )}
      {!couponInfo && (
        <div className="pay-coupon_form">
          <input className="form-control" value={inputValue} onChange={(e) => onChangeInput(e.target.value)} />
          {isLoading ? (
            <ClipLoader size={30} color={setLoaderColor()} loading={isLoading} />
          ) : (
            <button disabled={isLoading || !inputValue} onClick={() => validateCoupon()}>
              {buildLocale(currentTranslate, 'filtersBtnApply')}
            </button>
          )}
        </div>
      )}
      {couponInfo && (
        <p className="coupon-success">
          {buildLocale(currentTranslate, 'payPageCouponSuccess')} {couponInfo}
        </p>
      )}
      {couponInfo && !isAuth && (
        <p style={{ color: '#f3335c' }}>{buildLocale(currentTranslate, 'couponDataChangePhone')}</p>
      )}
      {isError && <p className="coupon-error">{buildLocale(currentTranslate, 'payPageCouponError')}</p>}
      <div className="pay-check">
        <p>{buildLocale(currentTranslate, 'payPagePackage')}</p>
        <div className="form-check" onClick={() => {}}>
          <input
            id="free-bag"
            type="checkbox"
            checked={isFreeBag}
            onChange={(e) => {
              changeFreeBag(e.target.checked);
              createDraft();
            }}
          />
          <label className="form-check-label" htmlFor="free-bag">
            {buildLocale(currentTranslate, 'freeBag')}
          </label>
        </div>
      </div>
    </div>
  );
};

export default CouponDataComponent;
