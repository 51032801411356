import React from 'react';

interface IShowErrorProps {
  errors: any;
  name: string;
}
const ShowError = ({ errors, name }: IShowErrorProps) => {
  const error = errors[name];
  if (!error) return null;

  return <p className="profile-form-error">{error.message || error}</p>;
};

export default ShowError;
