import React, { useContext } from 'react';

interface IRouterStaticContext {
  context: any;
}

const RouterStaticContext = React.createContext({} as IRouterStaticContext);

const useRouterStaticContext = () => {
  const context = useContext(RouterStaticContext);
  return context.context;
};

const ProviderRouterStaticContext = ({ children, context = {} }: any) => {
  return <RouterStaticContext.Provider value={{ context }}>{children}</RouterStaticContext.Provider>;
};

export { useRouterStaticContext };
export default ProviderRouterStaticContext;
