const config = {
  canLink: process.env.REACT_APP_CAN_LINK,
  clientApiURL: process.env.REACT_APP_CLIENT_API_URL,
  baseURL: process.env.REACT_APP_BASE_URL,
  baseURLV2: process.env.REACT_APP_BASE_URL_V2,
  baseURLV3: process.env.REACT_APP_BASE_URL_V3,
  baseURLCallback: process.env.REACT_APP_BASE_URL_CALLBACK,
  payeeId: process.env.REACT_APP_PAYEE_ID,
  expiredTime: process.env.REACT_APP_EXPIRED_TIME_ID_DRAFT || 1,
  paymentRedirect: process.env.REACT_APP_PAYMENT_REDIRECT,
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  disallowRobots: process.env.REACT_APP_DISALLOW_ROBOTS,

  enableGTM: process.env.REACT_APP_ENABLE_GTM_TRACKING === 'true',
  GTM: process.env.REACT_APP_GTM_TRACKING_ID,

  recaptchaPublicSiteKey: process.env.REACT_APP_NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  expiryHourCache: +(process.env.REACT_APP_EXPIRY_HOUR_CACHE || 7),
  baseURLCloudflare: process.env.REACT_APP_BASE_URL_CLOUDFLARE,
  defaultLng: process.env.REACT_APP_DEFAULT_LNG,
  paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  videoURL: process.env.REACT_APP_PUBLIC_BUCKET_URL,
  publicBucketURL: process.env.REACT_APP_PUBLIC_BUCKET_URL,
  sitemapURL: process.env.REACT_APP_PUBLIC_BUCKET_URL + 'sitemaps/sitemap.xml',
  shopId: process.env.REACT_APP_SHOP_ID,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sentryMaxBreadcrumbs: process.env.REACT_APP_SENTRY_MAX_BREADCRUMBS,
  versionTag: process.env.REACT_APP_APP_VERSION_TAG,
  isErrorReportingEnabled: process.env.REACT_APP_ERROR_REPORTER_ENABLED,
  appVersion: process.env.REACT_APP_VERSION,

  maxApiRequests: process.env.REACT_APP_MAX_API_REQUESTS,
};

export default config;
