const validation = {
  email: {
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Geben Sie eine gültige E-Mail-Adresse ein',
    },
    required: 'Pflichtfeld',
  },
  phone: {
    pattern: {
      value: /\+?\d+$/,
      message: 'Darf nur Zahlen enthalten',
    },
    minLength: {
      value: 8,
      message: 'Pflichtfeld',
    },
    required: 'Pflichtfeld',
  },
  zipCode: {
    pattern: {
      value: /^\d{5}$/,
      message: 'Darf nur Zahlen enthalten',
    },
    minLength: {
      value: 5,
      message: 'Muss aus fünf Ziffern bestehen',
    },
    maxLength: {
      value: 5,
      message: 'Muss aus fünf Ziffern bestehen',
    },
    required: 'Pflichtfeld',
  },
  required: {
    required: 'Pflichtfeld',
  },
  pass: {
    minLength: {
      message: 'Muss mindestens 8 Zeichen lang sein',
      value: 8,
    },
    required: 'Pflichtfeld',
  },
};

export default validation;
