import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { usePaths } from '../Routes/RouterList';
import { generateClassName } from '../../utils/generateClassName';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { actionsCart, selectorsCart } from '../../redux/cart/cartReducer';
import { buildLocale } from '../../utils/buildLocale';
import getUrlImg from '../../utils/getUrlImg';
import { isBrowser } from '../../utils/isBrowser';
import { ICDNVariantKey } from '../../typings/ICDNImage';
import wishlistIcon from '../../assets/images/ic-heart_o.svg';
import wishlistIconNonActive from '../../assets/images/ic-heart.svg';
import service from '../../services/service';
import { selectorsUser } from '../../redux/user/userReducer';
import ProductImage from '../ImagesComponent/ProductImage';
import { useLessThen991 } from '../../utils/mediaQuery';
import ProductCardMobile from './ProductCardMobile';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import ProductImagesSlider from '../Products/ProductImagesSlider';
import ProductMagnifyImage from '../common/ProductMagnifyImage';
import { DL_helper } from '../../utils/DataLayer/DL_helper';
import BadgesList from '../common/BadgesList';
import PriceBlock from '../common/PriceBlock';
import useApi from 'react-use-api';
import queries from '../../services/queries';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';
import { isContentNotEmpty } from '../../utils/isContentNotEmpty';
import { ProductKeysTab } from '../Products/Enum/ProductKeysTab';
import RelatedProductsWidget from '../Products/RelatedProductsWidget';
import { IProduct } from '../../typings/IProduct';
import { carouselRelatedProductsPopup } from '../../constants/configCarousel';
import { selectorsOther } from '../../redux/other/otherReducer';
import { useNavigate } from 'react-router';
import SaleDiscountWidget from '../Products/SaleDiscountWidget';

const pixelRatio = isBrowser ? Math.min(window.devicePixelRatio, 2) : 1;

const getPropsProductImage = (product: any) => {
  if (!product) {
    return;
  }
  if (product.cdnImages !== null && product.cdnImages !== undefined && product.cdnImages.length > 0) {
    const cdn = product.cdnImages[0];
    return {
      src: cdn.variants[ICDNVariantKey.origin],
      sizes: `
                (max-width: 767px) calc((100vw / 2 - 30px) /  ${pixelRatio}),
                calc(300px / ${pixelRatio})`,
      srcSet: `
                ${cdn.variants[ICDNVariantKey['250x150']]} 350w, 
                ${cdn.variants[ICDNVariantKey['550x300']]} 550w
            `,
      srcSetLoading: cdn.variants[ICDNVariantKey.mock],
    };
  } else {
    return {
      src: getUrlImg(product.productImages),
    };
  }
};

interface ProductCardProps {
  isOpen: boolean;
  id: number;
  isFavorite?: boolean;
  onProductCardClose: (needToCloseAdvancedModal?: boolean) => void;
  setIsFavorite?: (state: boolean) => void;
  resetSearchField?: () => void;
}

const ProductCard = (props: ProductCardProps) => {
  const dispatch = useDispatch<any>();
  const paths = usePaths();
  const navigate = useNavigate();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const { isOpen, onProductCardClose, resetSearchField, id } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [tab, setTab] = useState(ProductKeysTab.fullDescription);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const [product] = useApi(queries.getProductById(id, isInCourierArea));
  const [isFavorite, setIsFavorite] = useState(product?.isFavorite || false);
  const isProductInCart = useSelector(selectorsCart.checkProductInCart(product?.id));
  const isAuth = useSelector(selectorsUser.isAuth);
  const cart = useSelector(selectorsCart.getCartProducts);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const currentProductOption = product?.productOptions?.length ? product.productOptions.at(0) : null;
  const salePrice = currentProductOption ? currentProductOption.salePrice : null;
  const imageProps = getPropsProductImage(product);

  const handleData = (data: any, state: any) => {
    const { error } = state;
    if (error) {
      return [];
    }
    const { items } = data;
    return [
      ...items.map((item: IProduct) => {
        item.title = item.titleDe;
        return item;
      }),
    ];
  };

  const [productRecommendations] = useApi(queries.getProductRecommendations(id, isInCourierArea), { handleData });
  const [productVariants] = useApi(queries.getProductVariants(id, isInCourierArea), { handleData });

  const setActiveTab = () => {
    const keyTabs = Object.values(ProductKeysTab);
    const mobileKeysTabs = isLess991
      ? keyTabs.filter((key: string) => key !== ProductKeysTab.fullDescription)
      : keyTabs;
    for (const tabName of mobileKeysTabs) {
      if (isContentNotEmpty(product[tabName])) {
        setTab(tabName);

        return;
      }
    }
  };

  useEffect(() => {
    if (product?.id) {
      setActiveTab();
      setIsFavorite(product.isFavorite || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, isLess991]);

  useEffect(() => {
    if (isOpen) {
      blockBodyScroll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set the default value after external closing
    setTab(ProductKeysTab.fullDescription);
  }, [isOpen]);

  const closeModal = (needToCloseAdvancedModal = false) => {
    setTab(ProductKeysTab.fullDescription);
    onProductCardClose(needToCloseAdvancedModal);
    blockBodyScroll(false);
  };

  const addToWishlist = (id: number) => {
    service
      .addToWishlist(id)
      .then(() => {
        setIsFavorite(true);
      })
      .catch((e) => console.log('ERROR>>>>> add to wishlist'));
  };

  const removeFromWishlist = (id: number) => {
    service
      .removeFromWishlist(id)
      .then(() => {
        setIsFavorite(false);
      })
      .catch((e) => console.log('ERROR>>>>>>>> remove from wishlist'));
  };

  const changeWishlist = () => {
    if (!isAuth) {
      navigate(paths.login, { state: { from: paths.product(product.id) } });
      return;
    }
    handleWishlistChanges();
  };

  const handleWishlistChanges = () => {
    if (!product) {
      return;
    }
    isFavorite ? removeFromWishlist(product.id) : addToWishlist(product.id);
  };

  const addProductToCart = (count: number, alternativeCount: number | null = null) => {
    if (!isProductInCart) {
      dispatch(
        actionsCart.addProduct({
          count: count,
          comment: '',
          product: product!,
          alternativeCount,
          services: [],
        }),
      );
      DL_helper(cart, 1, 'addToCart', product);
    }
  };

  const orderBtnTitle = isProductInCart
    ? `${buildLocale(currentTranslate, 'allreaduInBasket')}`
    : `${buildLocale(currentTranslate, 'wantToOrder')}`;

  if (isLess991) {
    return (
      product && (
        <ProductCardMobile
          product={product}
          salePrice={salePrice}
          imageProps={imageProps}
          closeModal={closeModal}
          resetSearchField={resetSearchField}
          changeWishlist={changeWishlist}
          isFavorite={isFavorite}
          addProductToCart={addProductToCart}
          orderBtnTitle={orderBtnTitle}
          tab={tab}
          KeysTab={ProductKeysTab}
          setTab={setTab}
          isOpen={isOpen}
          productRecommendations={productRecommendations}
          productVariants={productVariants}
        />
      )
    );
  }

  return (
    <Modal
      id="product-card-popup-model"
      className={`product-modal`}
      shouldCloseOnOverlayClick
      overlayClassName="edit_overlay payment_overlay modal_assortmant"
      isOpen={isOpen}>
      <button
        className="modal-close"
        style={{ zIndex: 1000 }}
        onClick={() => {
          closeModal();
          blockBodyScroll(false);
        }}></button>
      {product && (
        <div
          className="row"
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}>
          <div className="col-md-6">
            <figure className={`product-pic${salePrice !== null ? ' item-sale' : ''}`}>
              {salePrice !== null && <SaleDiscountWidget productOption={currentProductOption} needCountdown={true} />}
              <BadgesList product={product} isBigBadge={true} />
              {isLess991 ? (
                <ProductImagesSlider product={product} />
              ) : (
                <div className="product-pic_zoom">
                  <div id="zoom-picture">
                    <ProductMagnifyImage product={product} imageIndex={currentImageIndex} />
                  </div>
                  <div className={'product-pic-may-differ'}>
                    <span>{buildLocale(currentTranslate, 'productImageMayDifferFromOriginal')}</span>
                  </div>
                  {product && product.cdnImages && product.cdnImages.length > 1 && (
                    <div className="product-pic_pic-more_scroll">
                      <ul className="product-pic_pic-more">
                        {product.cdnImages.map((img: any, index: number) => (
                          <li onClick={() => setCurrentImageIndex(index)} key={img.id}>
                            <ProductImage product={product} imageIndex={index} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </figure>
          </div>
          <div className="col-md-6">
            <div className="product-card">
              <h1>
                <Link
                  to={paths.product(product.id)}
                  onClick={() => {
                    closeModal(true);
                    resetSearchField && resetSearchField();
                  }}>
                  {product.title}
                </Link>
              </h1>
              <button
                onClick={() => changeWishlist()}
                className="btn btn-wish"
                aria-label="Zu den Favoriten hinzufügen">
                <img src={isFavorite ? wishlistIcon : wishlistIconNonActive} alt="" width="20" height="19" />
              </button>
              <p
                dangerouslySetInnerHTML={{
                  __html: product.shortDescription,
                }}></p>
              <PriceBlock
                product={product}
                addProductToCart={addProductToCart}
                orderBtnTitle={orderBtnTitle}
                closeModal={closeModal}
              />
            </div>
          </div>
          <div className="col-12">
            <section className="product-reviews">
              <ul className="nav nav-tabs">
                {isContentNotEmpty(product.fullDescription) && (
                  <li className="nav-item">
                    <button
                      className={generateClassName('nav-link', { active: tab === ProductKeysTab.fullDescription })}
                      onClick={() => setTab(ProductKeysTab.fullDescription)}>
                      {buildLocale(currentTranslate, 'productCardTabDesc')}
                    </button>
                  </li>
                )}
                {isContentNotEmpty(product.garnish) && (
                  <li className="nav-item">
                    <button
                      className={generateClassName('nav-link', { active: tab === ProductKeysTab.garnish })}
                      onClick={() => setTab(ProductKeysTab.garnish)}>
                      {buildLocale(currentTranslate, 'productCardTabGarnish')}
                    </button>
                  </li>
                )}
                {isContentNotEmpty(product.energyValue) && (
                  <li className="nav-item">
                    <button
                      className={generateClassName('nav-link', { active: tab === ProductKeysTab.energyValue })}
                      onClick={() => setTab(ProductKeysTab.energyValue)}>
                      {buildLocale(currentTranslate, 'productCardTabEnergyValue')}
                    </button>
                  </li>
                )}
                {isContentNotEmpty(product.ingredients) && (
                  <li className="nav-item">
                    <button
                      className={generateClassName('nav-link', { active: tab === ProductKeysTab.ingredients })}
                      onClick={() => setTab(ProductKeysTab.ingredients)}>
                      {buildLocale(currentTranslate, 'productCardTabWarehouse')}
                    </button>
                  </li>
                )}
              </ul>
              <div className="tab-content">
                {tab === ProductKeysTab.fullDescription && (
                  <div className={generateClassName('tab-pane', { active: tab === ProductKeysTab.fullDescription })}>
                    {product.fullDescription ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: product.fullDescription,
                        }}></p>
                    ) : (
                      <p> - </p>
                    )}
                  </div>
                )}
                {tab === ProductKeysTab.energyValue && (
                  <div className={generateClassName('tab-pane', { active: tab === ProductKeysTab.energyValue })}>
                    {product.energyValue ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: product.energyValue,
                        }}></p>
                    ) : (
                      <p> - </p>
                    )}
                  </div>
                )}
                {tab === ProductKeysTab.garnish && (
                  <div className={generateClassName('tab-pane', { active: tab === ProductKeysTab.garnish })}>
                    {product.garnish ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: product.garnish,
                        }}></p>
                    ) : (
                      <p> - </p>
                    )}
                  </div>
                )}
                {tab === ProductKeysTab.ingredients && (
                  <div className={generateClassName('tab-pane', { active: tab === ProductKeysTab.ingredients })}>
                    {product.ingredients ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: product.ingredients,
                        }}></p>
                    ) : (
                      <p> - </p>
                    )}
                  </div>
                )}
              </div>
            </section>
          </div>
          <RelatedProductsWidget
            productId={id}
            productRecommendations={productRecommendations}
            productVariants={productVariants}
            responsiveConfig={carouselRelatedProductsPopup}
            carouselItemClass={'carousel-item-popup-li'}
          />
        </div>
      )}
    </Modal>
  );
};

export default ProductCard;
