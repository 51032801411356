import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { selectorsUser } from '../../../redux/user/userReducer';
import useComponentVisible from '../../../useHooks/useComponentVisible';
import { buildLocale } from '../../../utils/buildLocale';
import { formatAddress } from '../../../utils/formatAddress';
import { selectorsDelivery } from '../../../redux/delivery/deliveryReducer';

interface IProps {
  setAddressData: (address: any) => void;
  updateStorageAddress: (address: any) => void;
}

const AddressesDropdown = (props: IProps) => {
  const { setAddressData, updateStorageAddress } = props;
  const addresses = useSelector(selectorsUser.getAddresses);
  const [showInfo, setShowInfo] = useState<any>(null);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const currentZipCode = useSelector(selectorsDelivery.getZipCode);

  const { ref: inputRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleToggle = () => {
    setIsComponentVisible((f) => !f);
  };

  const selectAddress = (address: any) => {
    setAddressData(address);
    updateStorageAddress(address);
    setIsComponentVisible(false);
    setShowInfo(null);
  };

  const addressesWithCurrentZipCode = addresses.filter((address) => address.zipCode === currentZipCode);

  if (!addressesWithCurrentZipCode || addressesWithCurrentZipCode.length === 0) {
    return null;
  }

  return (
    <div className="pay-dropdown" ref={inputRef}>
      <span className="link" onFocus={handleToggle} tabIndex={1}>
        {buildLocale(currentTranslate, 'payPageAddressDropdown')}
      </span>
      {isComponentVisible && (
        <div className="pay-dropdown_block">
          {addressesWithCurrentZipCode.map((address) => (
            <div
              className="info-row"
              key={address.id}
              onClick={() => selectAddress(address)}
              onMouseEnter={() => setShowInfo(formatAddress(address, currentTranslate))}
              onMouseLeave={() => setShowInfo(null)}>
              {formatAddress(address, currentTranslate)}
            </div>
          ))}
        </div>
      )}
      {showInfo && <div className="info-row-tooltip">{showInfo}</div>}
    </div>
  );
};

export default AddressesDropdown;
