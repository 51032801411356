import React from 'react';
import imgCard from '../../assets/images/mastercard_logo.svg';
import { ICard } from '../../typings/ICard';
import { generateClassName } from '../../utils/generateClassName';
import OtherImage from '../ImagesComponent/OtherImage';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';

interface IPaymentCardProps {
  card: ICard;
  isActive: boolean;
  onClick: (id: number) => any;
  onDelete?: (id: number) => any;
  disabled: boolean;
  setIsActiveAnotherCartPay?: (bool: boolean) => any;
}

const formatCard = (card: string) => {
  return `${card.substr(0, 4)} ${card.substr(4, 4)} ${card.substr(8, 4)} ${card.substr(12, 4)}`;
};

const PaymentCard = ({ card, isActive, onClick, disabled, onDelete, setIsActiveAnotherCartPay }: IPaymentCardProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  let { number } = card;

  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick(card.id);
    setIsActiveAnotherCartPay && setIsActiveAnotherCartPay(false);
  };

  const handleDelete = () => {
    if (disabled) {
      return;
    }
    onDelete && onDelete(card.id);
  };

  return (
    <div
      style={{ opacity: disabled ? 0.4 : 1 }}
      className={generateClassName('checkout-delivery_card', {
        'checkout-delivery_card-default': isActive,
      })}
      onClick={handleClick}>
      {onDelete && <button className="btn-delete" onClick={handleDelete} />}
      <div className="checkout-delivery_card-info">
        <OtherImage src={imgCard} className="card-logo card-logo_mc" title="payment card" width="34" height="22" />
        <p className="card-name">{formatCard(number)}</p>
      </div>
      {isActive && <p className="card-note">{buildLocale(currentTranslate, 'commonDefaultCard')}</p>}
    </div>
  );
};

export { formatCard };
export default PaymentCard;
