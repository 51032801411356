import React from 'react';
import { IFetchCategory } from '../../typings/ICategory';
import { ICDNVariantKey } from '../../typings/ICDNImage';
import getUrlImg from '../../utils/getUrlImg';
import { isBrowser } from '../../utils/isBrowser';
import OtherImage from './OtherImage';

interface ICategoryImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  category: IFetchCategory;
  loading?: 'lazy' | 'eager';
}

const pixelRatio = isBrowser ? Math.min(window.devicePixelRatio, 2) : 1;

const getPropsCategoryImage = (category: IFetchCategory) => {
  if (category.cdnImage !== null && category.cdnImage !== undefined) {
    const cdn = category.cdnImage;
    return {
      src: cdn.variants[ICDNVariantKey.origin],
      sizes: `
      (max-width: 575px) calc((100vw - 30px) / ${pixelRatio}),
      (max-width: 992px) calc((100vw / 2 - 30px)  / ${pixelRatio}),
      calc(280px / ${pixelRatio})`,
      srcSet: `
        ${cdn.variants[ICDNVariantKey['250x150']]} 300w, 
        ${cdn.variants[ICDNVariantKey['400x300']]} 450w, 
        ${cdn.variants[ICDNVariantKey['600x450']]} 600w
    `,
      srcSetLoading: cdn.variants[ICDNVariantKey.mock],
    };
  } else {
    return {
      src: getUrlImg(category.categoryImages),
    };
  }
};

const CategoryImage = ({ category, ...props }: ICategoryImageProps) => {
  const imageProps = getPropsCategoryImage(category);

  return (
    <OtherImage
      width="242"
      height="189"
      src={imageProps.src}
      srcSet={imageProps.srcSet}
      srcSetLoading={imageProps.srcSetLoading}
      sizes={imageProps.sizes}
      title={category.name}
      {...props}
    />
  );
};

export default CategoryImage;
