import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import service from '../../services/service';
import ProductImage from '../ImagesComponent/ProductImage';
import ClipLoader from 'react-spinners/ClipLoader';
import { IBrandItem } from '../../typings/IBrands';
import { buildLocale } from '../../utils/buildLocale';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { setLoaderColor } from '../../utils/setLoaderColor';
import { Link } from 'react-router-dom';
import { usePaths } from '../Routes/RouterList';

import { useNavigate } from 'react-router';

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  currentId: number | null;
  resetSearchField?: () => void;
  location: any;
}

const BrandModal = (props: IProps) => {
  const { isOpen, closeModal, currentId, resetSearchField, location } = props;
  const paths = usePaths();
  const navigate = useNavigate();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [brandInfo, setBrandInfo] = useState<IBrandItem | null>(null);

  useEffect(() => {
    if (currentId) {
      service
        .getBrandById(currentId)
        .then((value) => setBrandInfo(value.data))
        .catch((e) => closeModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToBrandPage = () => {
    if (!brandInfo) {
      return;
    }
    const historyData = location?.state?.from?.length
      ? [...location.state.from, location.pathname]
      : [location.pathname];
    navigate(paths.brand(brandInfo.id), { state: { from: historyData } });
  };

  return (
    <Modal className="brands-modal_modal" isOpen={isOpen}>
      {!brandInfo ? (
        <div className="loading-center">
          <ClipLoader size={30} color={setLoaderColor()} loading={true} />
        </div>
      ) : (
        <div className="brands-modal">
          <figure className="brands-modal_logo">
            <ProductImage
              product={{ ...(brandInfo as any), title: brandInfo.name, cdnImages: [brandInfo.logo] }}
              isDefaultImg={!brandInfo.logo}
            />
          </figure>
          <div className="brands-modal_content">
            <h1>
              <Link
                to={paths.brand(brandInfo.id)}
                onClick={(e) => {
                  e.preventDefault();
                  closeModal();
                  resetSearchField && resetSearchField();
                  navigateToBrandPage();
                }}>
                {brandInfo.nameDe || ''}
              </Link>
            </h1>
            {brandInfo.descriptionDe && (
              <div
                className="brands-modal_text"
                dangerouslySetInnerHTML={{
                  __html: brandInfo.descriptionDe,
                }}></div>
            )}
          </div>
          <div className="brands-modal_buttons">
            <button className="btn btn-border btn-orange-border" onClick={() => closeModal()}>
              {buildLocale(currentTranslate, 'brandsModalClose')}
            </button>
            {brandInfo.link && (
              <a href={brandInfo.link} target={'_blank'} rel="noopener, ,nofollow noreferrer" className="btn">
                {buildLocale(currentTranslate, 'brandsModalVisit')}
              </a>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BrandModal;
