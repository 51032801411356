import config from '../config';

const getShopLogo = (isMobile = false) => {
  let fileName = config.shopId;
  if (isMobile) {
    fileName = `${fileName}_m`;
  }

  return `${config.publicBucketURL}logo/${fileName}.svg?v=${new Date().getTime().toString().substring(0, 7)}`;
};

export default getShopLogo;
