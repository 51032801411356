import { useLocation } from 'react-router-dom';

const useParseQuery = (name?: string) => {
  const location = useLocation();
  const res = new URLSearchParams(location?.search);

  if (!name) {
    return Object.fromEntries(res);
  }

  const param: any = res.get(name);
  if (!param) {
    return '';
  }

  return param;
};

const getQuery = (obj: any) => {
  const cleanObj = Object.entries(obj).reduce((a, [key, value]) => (value ? ((a[key] = value), a) : a), {});
  const query = new URLSearchParams(cleanObj).toString();

  if (query?.length) {
    return '?' + query;
  }
  return query;
};

export { getQuery };
export default useParseQuery;
