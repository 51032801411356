import { CreatorReducer } from '../base/base';
import { IFiltersActions, IFiltersState } from './filtersTypes';
import { RootState } from '../reducer';

const init: IFiltersState = {
  brands: [],
  brandsDraft: [],
  selectedBrands: [],
  badges: [],
  badgesDraft: [],
  selectedBadges: [],
  allergich: [],
  allergichDraft: [],
  selectedAllergich: [],
  nutrion: [],
  nutrionDraft: [],
  selectedNutrion: [],
  hasPriceRanges: false,
  hasPriceRangesDraft: false,
  hasBadges: false,
  hasBadgesDraft: false,
  hasSalePrice: false,
  hasSalePriceDraft: false,
  searchFilter: null,
  searchFilterDraft: null,
  sortDirection: undefined,
};

const creator = new CreatorReducer<IFiltersActions, IFiltersState>('filters');
creator.addAction('setBrands', (state, action) => {
  return { ...state, brands: action.payload };
});
creator.addAction('setBrandsDraft', (state, action) => {
  return { ...state, brandsDraft: action.payload };
});
creator.addAction('setSelectedBrands', (state, action) => {
  return { ...state, selectedBrands: action.payload };
});
creator.addAction('setBadges', (state, action) => {
  return { ...state, badges: action.payload };
});
creator.addAction('setBadgesDraft', (state, action) => {
  return { ...state, badgesDraft: action.payload };
});
creator.addAction('setSelectedBadges', (state, action) => {
  return { ...state, selectedBadges: action.payload };
});
creator.addAction('setAllergich', (state, action) => {
  return { ...state, allergich: action.payload };
});
creator.addAction('setAllergichDraft', (state, action) => {
  return { ...state, allergichDraft: action.payload };
});
creator.addAction('setSelectedAllergich', (state, action) => {
  return { ...state, selectedAllergich: action.payload };
});
creator.addAction('setNutrion', (state, action) => {
  return { ...state, nutrion: action.payload };
});
creator.addAction('setNutrionDraft', (state, action) => {
  return { ...state, brandsDraft: action.payload };
});
creator.addAction('setSelectedNutrion', (state, action) => {
  return { ...state, selectedNutrion: action.payload };
});
creator.addAction('clearAllSelected', (state, action) => {
  return {
    ...state,
    selectedBrands: [],
    selectedBadges: [],
    selectedAllergich: [],
    selectedNutrion: [],
    hasPriceRanges: false,
    hasBadges: false,
    hasSalePrice: false,
    searchFilter: null,
    sortDirection: undefined,
  };
});
creator.addAction('setHasPriceRanges', (state, action) => {
  return { ...state, hasPriceRanges: action.payload };
});
creator.addAction('setHasPriceRangesDraft', (state, action) => {
  return { ...state, hasPriceRangesDraft: action.payload };
});
creator.addAction('setHasBadges', (state, action) => {
  return { ...state, hasBadges: action.payload };
});
creator.addAction('setHasBadgesDraft', (state, action) => {
  return { ...state, hasBadgesDraft: action.payload };
});
creator.addAction('setHasSalePrice', (state, action) => {
  return { ...state, hasSalePrice: action.payload };
});
creator.addAction('setHasSalePriceDraft', (state, action) => {
  return { ...state, hasSalePriceDraft: action.payload };
});
creator.addAction('setSearchFilter', (state, action) => {
  return { ...state, searchFilter: action.payload };
});
creator.addAction('setSearchFilterDraft', (state, action) => {
  return { ...state, searchFilterDraft: action.payload };
});
creator.addAction('setSortDirection', (state, action) => {
  return { ...state, sortDirection: action.payload };
});
creator.addAction('setSortDirectionDraft', (state, action) => {
  return { ...state, sortDirectionDraft: action.payload };
});
creator.addAction('setAllDraft', (state, action) => {
  return {
    ...state,
    badgesDraft: action.payload.badgesDraft,
    brandsDraft: action.payload.brandsDraft,
    nutrionDraft: action.payload.nutrionDraft,
    allergichDraft: action.payload.allergichDraft,
    hasSalePriceDraft: action.payload.hasSalePriceDraft,
    hasBadgesDraft: action.payload.hasBadgesDraft,
    hasPriceRangesDraft: action.payload.hasPriceRangesDraft,
    sortDirectionDraft: action.payload.sortDirectionDraft,
  };
});

const actionsFilters = creator.createActions();

const selectorsFilters = {
  getBrands: (state: RootState) => state.filters.brands,
  getBrandsDraft: (state: RootState) => state.filters.brandsDraft,
  getSelectedBrands: (state: RootState) => state.filters.selectedBrands,
  getBadges: (state: RootState) => state.filters.badges,
  getBadgesDraft: (state: RootState) => state.filters.badgesDraft,
  getSelectedBadges: (state: RootState) => state.filters.selectedBadges,
  getAllergich: (state: RootState) => state.filters.allergich,
  getAllergichDraft: (state: RootState) => state.filters.allergichDraft,
  getSelectedAllergich: (state: RootState) => state.filters.selectedAllergich,
  getNutrion: (state: RootState) => state.filters.nutrion,
  getNutrionDraft: (state: RootState) => state.filters.nutrionDraft,
  getSelectedNutrion: (state: RootState) => state.filters.selectedNutrion,
  getTotalSelectedFiltersCount: (state: RootState) =>
    state.filters.selectedBrands.length +
    state.filters.selectedBadges.length +
    state.filters.selectedAllergich.length +
    state.filters.selectedNutrion.length +
    (state.filters.hasPriceRanges ? 1 : 0) +
    (state.filters.hasBadges ? 1 : 0) +
    (state.filters.hasSalePrice ? 1 : 0) +
    (state.filters.searchFilter ? state.filters.searchFilter.length : 0),
  getHasPriceRanges: (state: RootState) => state.filters.hasPriceRanges,
  getHasPriceRangesDraft: (state: RootState) => state.filters.hasPriceRangesDraft,
  getHasBadges: (state: RootState) => state.filters.hasBadges,
  getHasPriceBadgesDraft: (state: RootState) => state.filters.hasBadgesDraft,
  getHasSalePrice: (state: RootState) => state.filters.hasSalePrice,
  getHasSalePriceDraft: (state: RootState) => state.filters.hasSalePriceDraft,
  getSearchFilter: (state: RootState) => state.filters.searchFilter,
  getSearchFilterDraft: (state: RootState) => state.filters.searchFilterDraft,
  getSortDirection: (state: RootState) => state.filters.sortDirection,
  getSortDirectionDraft: (state: RootState) => state.filters.sortDirectionDraft,
};

export { actionsFilters, selectorsFilters };
export default creator.createReducer(init);
