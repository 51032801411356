import React from 'react';
import { useFormattingContext } from '../../context/FormattingContext';
import { IUnit } from '../../typings/IUnit';
import { calculateFullPrice, getUnitType } from '../../utils/calculateUnits';

interface IProps {
  salePrice: string | null;
  price: number;
  quantityPerPack: string;
  unit: IUnit;
  isWeightable: boolean | undefined;
}

const UnitPrice = (props: IProps) => {
  const { salePrice, price, quantityPerPack, unit, isWeightable } = props;
  const { formatPrice } = useFormattingContext();
  const formattedUnitType = getUnitType(unit?.type);

  const getUnitPrice = (isFixPrice: boolean) => {
    const currentPrice = salePrice && salePrice !== '0' && !isFixPrice ? +salePrice : price;
    return formatPrice(calculateFullPrice(currentPrice, quantityPerPack, unit.type));
  };

  return (
    <div className={`item-food_price${salePrice ? ' sale' : ''} `}>
      <div className="packaging">
        <span className="number-unit">
          {isWeightable
            ? `${formatPrice(salePrice ? +salePrice : +price, false)} €/${formattedUnitType}`
            : `${getUnitPrice(false)}/${formattedUnitType}`}
        </span>
      </div>
    </div>
  );
};

export default UnitPrice;
