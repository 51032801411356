import React from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { setLocaleStorageItem } from '../../utils/localStorageHelper';

interface IProps {
  isOpen: boolean;
  setIsOpenMainModal: (isOpen: boolean) => void;
}

const MainInfoModal = (props: IProps) => {
  const { setIsOpenMainModal, isOpen } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const setNotShow = () => {
    setIsOpenMainModal(false);
    setLocaleStorageItem('isOpenMainModal', 'true');
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName="edit_overlay payment_overlay modal_assortmant"
      className={`modal-settings`}>
      <div className="modal-settings_modal">
        <button className="modal-close" onClick={() => setIsOpenMainModal(false)}>
          &nbsp;
        </button>
        <div className="modal-settings_content">
          <p className="modal-settings_title">{buildLocale(currentTranslate, 'mainInfoModalTitle')}</p>
          {buildLocale(currentTranslate, 'mainInfoModalDesc')}{' '}
          <span className="font-bold">{buildLocale(currentTranslate, 'mainInfoModalCode')}</span>
        </div>
        <span className="dont-show-again-btn" onClick={() => setNotShow()}>
          {buildLocale(currentTranslate, 'mainInfoModalDontShow')}
        </span>
        <button className="btn btn-red" onClick={() => setIsOpenMainModal(false)} style={{ marginTop: '1rem' }}>
          {buildLocale(currentTranslate, 'mainInfoModalBtn')}
        </button>
      </div>
    </Modal>
  );
};

export default MainInfoModal;
