import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import BioBadgeModal from '../components/BioBadgesPage/BioBadgeModal';
import BioBadgesHeader from '../components/BioBadgesPage/BioBadgesHeader';
import Pagination from '../components/common/Pagination';
import ProductImage from '../components/ImagesComponent/ProductImage';
import { IBioBadges } from '../typings/IBioBadges';
import { blockBodyScroll } from '../utils/blockBodyScroll';
import useApi from 'react-use-api';
import queries from '../services/queries';
import { useNavigate, useNavigationType } from 'react-router';
import GoBackBtn from '../components/CategoriesMobile/GoBackBtn';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';
import CommonHelmet from '../components/common/CommonHelmet';
import { buildLocale } from '../utils/buildLocale';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { selectorsOther } from '../redux/other/otherReducer';
import { useLessThen991 } from '../utils/mediaQuery';
import { useRouterStaticContext } from '../context/RouterStaticContext';
import NotFoundComponent from './common/NotFoundPage';

const BioBadgesPage = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = 20;
  const paginationParamValue = Number(searchParams.get('page')) || 1;
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const offset = paginationParamValue > 1 ? (paginationParamValue - 1) * limit : 0;
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);
  const navigationType = useNavigationType();
  const staticContext = useRouterStaticContext();

  const location = useLocation();
  const navigate = useNavigate();

  const backBtn = useMemo(() => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return '';
    }
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={currentTranslate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleData = (data: any, state: any) => {
    const { error } = state;
    if (!error) {
      const { items, count } = data;
      setCount(count);
      return [...items];
    } else {
      // show an error message from the api
      console.error(error.response.data.msg);
      return [];
    }
  };
  const getBioBadges = (currentFilters: any, top?: number, skip?: number) => {
    return queries.getBioBadges(currentFilters, top, skip);
  };
  const [bioBadges, { loading: isLoading, error }] = useApi<IBioBadges>(getBioBadges(search, limit, offset), {
    handleData,
  });
  const [currentBioBadgeId, setCurrentBioBadgeId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (staticContext && error?.status === 404) {
    staticContext.statusCode = 404;
  }

  useEffect(() => {
    blockBodyScroll(isModalOpen);
  }, [isModalOpen]);

  const openBioBadgeModal = (id: number) => {
    setCurrentBioBadgeId(id);
    setIsModalOpen(true);
  };

  const closeBioBadgeModal = () => {
    setIsModalOpen(false);
    setCurrentBioBadgeId(null);
  };

  const onSearch = (value: string) => {
    setSearchParams({});
    setSearch(value);
  };

  if (error?.status === 404) {
    return <NotFoundComponent />;
  }

  return (
    <section className="brands-page">
      <CommonHelmet title={`${buildLocale(currentTranslate, 'footerBioBadges')} | MyBio.de`}></CommonHelmet>
      {backBtn}
      <BioBadgesHeader onSearch={onSearch} />
      <div className="container">
        <ul className="brands-list">
          {!!bioBadges?.length &&
            bioBadges.map((bioBadge: any) => (
              <li onClick={() => openBioBadgeModal(bioBadge.id)} key={bioBadge.id}>
                <figure className="brands-logo">
                  <ProductImage
                    product={{ ...bioBadge, title: bioBadge.name, cdnImages: [bioBadge.logo] }}
                    isDefaultImg={!bioBadge.logo}
                  />
                </figure>
              </li>
            ))}
        </ul>
        <Pagination availableCount={1000} count={count} perPage={limit} loading={isLoading} />
      </div>
      {isModalOpen && (
        <BioBadgeModal isOpen={isModalOpen} currentId={currentBioBadgeId} closeModal={closeBioBadgeModal} />
      )}
    </section>
  );
};

export default BioBadgesPage;
