import { IPictures } from '../typings/IPictures';
import searchBg from '../assets/images/bg-search.png';
import { ISelectedFilters } from '../typings/IFilters';

export const getPictureByCode = (code: string, pictures: IPictures) => {
  const pic = pictures?.find((item) => item.code === code);

  return pic?.media?.variants?.origin;
};

export const getLinkByCode = (code: string, pictures: IPictures) => {
  const pic = pictures.find((item) => item.code === code);

  return pic && pic.link ? pic.link : '#';
};

export const getBannerTitle = (currentCategory: any, noBannerTitle: string, selectedBrands?: ISelectedFilters) => {
  let title = noBannerTitle;
  if (currentCategory && currentCategory.categoryBanner) {
    title = currentCategory.categoryBanner.title ? currentCategory.categoryBanner.title : noBannerTitle;
  }

  if (selectedBrands?.length === 1) {
    title += ` ${selectedBrands[0].nameDe}`;
  }

  return title;
};

export const getBannerBullets = (currentCategory: any) => {
  const bullets: Array<any> = [];

  if (currentCategory && currentCategory.categoryBanner) {
    currentCategory.categoryBanner.bullet1 && bullets.push(currentCategory.categoryBanner.bullet1);
    currentCategory.categoryBanner.bullet2 && bullets.push(currentCategory.categoryBanner.bullet2);
    currentCategory.categoryBanner.bullet3 && bullets.push(currentCategory.categoryBanner.bullet3);
  } else {
    return null;
  }

  return bullets;
};

export const getBannerImage = (currentCategory: any) => {
  if (currentCategory && currentCategory.categoryBanner) {
    return currentCategory.categoryBanner.image ? currentCategory.categoryBanner.image.variants.origin : null;
  }

  return searchBg;
};

export const getBannerHeight = (currentCategory: any) => {
  if (currentCategory && currentCategory.categoryBanner) {
    return currentCategory.categoryBanner.height ? +currentCategory.categoryBanner.height : 570;
  }

  return 570;
};

export const isHasBanner = (currentCategory: any) => {
  return currentCategory && currentCategory.categoryBanner && currentCategory.categoryBanner.image;
};
