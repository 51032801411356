import { cities } from '../constants/cities';
import { IAddressRedux } from '../typings/CustomTypes/IAddressRedux';
import { buildLocale } from './buildLocale';

export const formatAddress = (address?: IAddressRedux | any, currentTranslate?: any) => {
  const flatT = currentTranslate ? buildLocale(currentTranslate, 'tiFlatPlaceholder') : 'wohnung';
  const entranceT = currentTranslate ? buildLocale(currentTranslate, 'tiEntrancePlaceholder') : 'eingang';
  const floorT = currentTranslate ? buildLocale(currentTranslate, 'tiFloorPlaceholder') : 'boden';
  if (!address) return '';
  const cityNumber = cities.find((city) => city.value === address.city);
  const district = '';
  const flat = address.flatNumber ? `, ${flatT} ${address.flatNumber}` : '';
  const entrance = address.entrance ? `, ${entranceT} ${address.entrance}` : '';
  const floor = address.floor ? `, ${floorT} ${address.floor}` : '';
  const zip = address.zipCode ? `${address.zipCode}` : '';
  const city = cityNumber ? cityNumber.label : address.city || '';
  return `${district || ''} ${address.street} ${address.buildNumber} ${flat}${entrance}${floor},${
    city ? ` ${city}` : ''
  } ${zip}`;
};

export const getConvertDataToFetch = (data: IAddressRedux) => {
  const district = '';
  const addressDictionaryId = data.buildObj ? data.buildObj.value : null;
  const buildNumber: string = data.buildObj ? data.buildObj.extra.build : data.buildNumber;
  const cityNumber = cities.find((city) => city.value === data.city || city.label === data.city);
  return {
    district,
    city: cityNumber ? cityNumber.label : data.city,
    street: data.street,
    buildNumber,
    flatNumber: data.flatNumber,
    entrance: data.entrance,
    floor: data.floor,
    addressDictionary: {
      id: addressDictionaryId,
    },
    notes: '',
    corps: null,
    zipCode: data.zipCode,
  } as any;
};
