import { WebSite, WithContext } from 'schema-dts';

type GoogleWebSite = WebSite & {
  potentialAction: {
    '@type': string;
    target: {
      '@type': string;
      urlTemplate: string;
    };
    'query-input'?: string;
  };
};

const useGoogleWebSiteDataJson = (): WithContext<GoogleWebSite> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'MyBio',
    url: 'https://mybio.de/',
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'https://mybio.de/all_products?search={search_term_string}',
      },
      'query-input': 'required name=search_term_string',
    },
  };
};

export default useGoogleWebSiteDataJson;
