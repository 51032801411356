import React from 'react';
import { useSelector } from 'react-redux';
import { selectorsCategory } from '../../redux/category/categoryReducer';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { ICDNVariantKey } from '../../typings/ICDNImage';
import { IProduct } from '../../typings/IProduct';
import { getOwnCategory } from '../../utils/getOwnCategory';
import { buildLocale } from '../../utils/buildLocale';
import getUrlImg from '../../utils/getUrlImg';
import OtherImage from './OtherImage';

interface IImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  product: IProduct;
}

const getPropsProductImage = (product: IProduct) => {
  if (product.cdnImages !== null && product.cdnImages !== undefined && product.cdnImages.length > 0) {
    const cdn = product.cdnImages[0];
    return {
      src: cdn.variants[ICDNVariantKey.public],
      sizes: `75px`,
      srcSet: `
        ${cdn.variants[ICDNVariantKey['75x50']]} 75w,
        ${cdn.variants[ICDNVariantKey['150x100']]} 150w,
        ${cdn.variants[ICDNVariantKey['250x150']]} 250w
    `,
      srcSetLoading: cdn.variants[ICDNVariantKey['75x50']],
    };
  } else {
    return {
      src: getUrlImg(product.productImages),
    };
  }
};

const ThumbProductImage = ({ product, ...props }: IImageProps) => {
  const customCategory = getOwnCategory(product.customCategories);
  const isRest = useSelector(selectorsCategory.isRestCategory(customCategory?.id));
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const imageProps = getPropsProductImage(product);

  return (
    <OtherImage
      width="75"
      height="50"
      src={imageProps.src}
      srcSet={imageProps.srcSet}
      srcSetLoading={imageProps.srcSetLoading}
      sizes={imageProps.sizes}
      title={`${isRest ? buildLocale(currentTranslate, 'dish') : ''} ${product.shortDescription}`.trim()}
      {...props}
    />
  );
};

export default ThumbProductImage;
