import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { buildLocale } from '../utils/buildLocale';
import { getPictureByCode } from '../utils/bannerPrictures';
import downloadPicture from '../assets/images/download-pic.png';
import useApi from 'react-use-api';
import queries from '../services/queries';
import { useNavigate, useNavigationType } from 'react-router';
import { useLocation } from 'react-router-dom';
import GoBackBtn from '../components/CategoriesMobile/GoBackBtn';
import isLocationForFirstLoadedPage from '../utils/isLocationForFirstLoadedPage';
import CommonHelmet from '../components/common/CommonHelmet';
import BreadcrumbsStaticPage from './common/BreadcrumbsStaticPage';
import { selectorsOther } from '../redux/other/otherReducer';
import { useLessThen991 } from '../utils/mediaQuery';

const PressePage = () => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const currentHTMLTranslate = useSelector(selectorsLocale.getHTMLTranslate);
  const [pictures] = useApi(queries.getPictures());
  const navigationType = useNavigationType();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLessThen991 = useLessThen991(isDesktop);

  const location = useLocation();
  const navigate = useNavigate();

  const backBtn = useMemo(() => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return '';
    }
    if (!isLessThen991) {
      return '';
    }
    return <GoBackBtn location={location} navigate={navigate} translate={currentTranslate} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <section className="section-history section-about">
      <CommonHelmet title={`${buildLocale(currentTranslate, 'footerPresse')} | MyBio.de`}></CommonHelmet>
      {backBtn}
      <div className="breadcrumbs-box">
        <BreadcrumbsStaticPage lastItemName={buildLocale(currentTranslate, 'footerPresse')} />
      </div>
      <div className="container">
        <div className="row row-content">
          <div className="col-md-12">
            <section className="history-man">
              <div className="row">
                <div className="col-12 col-md-6">
                  <article
                    className="history-man_content"
                    dangerouslySetInnerHTML={{
                      __html: buildLocale(currentHTMLTranslate, 'presse.text1'),
                    }}></article>
                </div>
                <div className="col-12 col-md-6">
                  <figure className="history-man_pic">
                    <img src={getPictureByCode('presse.banner1', pictures)} alt="" />
                  </figure>
                </div>
              </div>
            </section>
            <section className="history-man">
              <div className="row">
                <div className="col-12 col-md-6">
                  <article className="download-doc">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: buildLocale(currentHTMLTranslate, 'presse.text2'),
                      }}></div>
                    <a href="mybio-pressekit.zip" download>
                      <img src={downloadPicture} alt="" width="70" height="64" />
                    </a>
                  </article>
                  <div
                    className="info-more"
                    dangerouslySetInnerHTML={{
                      __html: buildLocale(currentHTMLTranslate, 'presse.text3'),
                    }}></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PressePage;
